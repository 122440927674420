// import React from "react";

// const DashboardHeader=()=>
// {
//     return(
//         <>
//         </>
//     )
// }
// export default DashboardHeader;



// import * as React from 'react';
// import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
// import MuiDrawer from '@mui/material/Drawer';
// import Box from '@mui/material/Box';
// import MuiAppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';


// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import SideBarItem from './SideBarItem';
// // import CustomerStat from './CustomerStat';  // Ensure this import statement is correct


// const drawerWidth = 240;
// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(['width', 'margin'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

// const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     '& .MuiDrawer-paper': {
//       position: 'relative',
//       whiteSpace: 'nowrap',
//       width: drawerWidth,
//       transition: theme.transitions.create('width', {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       boxSizing: 'border-box',
//       ...(!open && {
//         overflowX: 'hidden',
//         transition: theme.transitions.create('width', {
//           easing: theme.transitions.easing.sharp,
//           duration: theme.transitions.duration.leavingScreen,
//         }),
//         width: theme.spacing(7),
//         [theme.breakpoints.up('sm')]: {
//           width: theme.spacing(9),
//         },
//       }),
//     },
//   }),
// );

// // TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme();

// export default function DashboardHeader() {
//   const [open, setOpen] = React.useState(true);
//   const [anchorEl, setAnchorEl] = React.useState(null);

//   const toggleDrawer = () => {
//     setOpen(!open);
//   };

//   const handleProfileMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleProfileMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const isMenuOpen = Boolean(anchorEl);

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Box sx={{ display: 'flex' }}>
//         <CssBaseline />
//         <AppBar position="absolute" open={open}>
//           <Toolbar
//             sx={{
//               pr: '24px', // keep right padding when drawer closed
//             }}
//           >
//             <IconButton
//               edge="start"
//               color="inherit"
//               aria-label="open drawer"
//               onClick={toggleDrawer}
//               sx={{
//                 marginRight: '36px',
//                 ...(open && { display: 'none' }),
//               }}
//             >
//               <MenuIcon />
//             </IconButton>
//             <Typography
//               component="h1"
//               variant="h6"
//               color="inherit"
//               noWrap
//               sx={{ flexGrow: 1 }}
//             >
//               Customer Dashboard
//             </Typography>
//             <IconButton
//               edge="end"
//               color="inherit"
//               aria-controls="profile-menu"
//               aria-haspopup="true"
//               onClick={handleProfileMenuOpen}
//             >
//               <AccountCircleIcon />
//             </IconButton>
//           </Toolbar>
//         </AppBar>
//         <Drawer variant="permanent" open={open}>
//           <Toolbar
//             sx={{
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'flex-end',
//               px: [1],
//             }}
//           >
//             <IconButton onClick={toggleDrawer}>
//               <ChevronLeftIcon />
//             </IconButton>
//           </Toolbar>
//           <Divider />
//           <List component="nav">
//             <SideBarItem/>
//             {/* <Divider sx={{ my: 1 }} />
//             {secondaryListItems} */}
//           </List>
//         </Drawer>
//         <Box
//           component="main"
//           sx={{
//             backgroundColor: (theme) =>
//               theme.palette.mode === 'light'
//                 ? theme.palette.grey[100]
//                 : theme.palette.grey[900],
//             flexGrow: 1,
//             height: '100vh',
//             overflow: 'auto',
//           }}
//         >
//           <Toolbar />
//           <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
//             <Grid container spacing={3}>
//               {/* Chart */}
//               {/* <Grid item xs={12} md={8} lg={12}>
//                 <Box
//                   sx={{
//                     p: 2,
//                     display: 'flex',
//                     flexDirection: 'column',
//                     height: 240,
//                   }}
//                 >
//                   <CustomerStat />
//                 </Box>
//               </Grid> */}
            
              
//             </Grid>
//             {/* <Copyright sx={{ pt: 4 }} /> */}
//           </Container>
//         </Box>
//         <Menu
//           id="profile-menu"
//           anchorEl={anchorEl}
//           anchorOrigin={{
//             vertical: 'top',
//             horizontal: 'right',
//           }}
//           keepMounted
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'right',
//           }}
//           open={isMenuOpen}
//           onClose={handleProfileMenuClose}
//         >
//           <MenuItem onClick={handleProfileMenuClose}>Profile</MenuItem>
//           <MenuItem onClick={handleProfileMenuClose}>My account</MenuItem>
//           <MenuItem onClick={handleProfileMenuClose}>Logout</MenuItem>
//         </Menu>
//       </Box>
//     </ThemeProvider>
//   );
// }


import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { AppBar } from "./style";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Typography, Grid, Menu, MenuItem } from "@mui/material";
// import Logo from "../assets/logo.svg";
import Logo from "../assets/header/logo.png";
import qicon from "../assets/qicon.svg";
import useAuth from "../hook/useAuth";
// import { useLogOutMutation } from "../redux/reducer/api/authApiSlice";
import { useSendLogOutMutation } from "../redux/reducer/api/authApiSlice";
import { useNavigate } from "react-router";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import Avatar from "@mui/material/Avatar";
import { themeColor } from "../constant/color";
import Badge from "@mui/material/Badge";
import { useDispatch } from "react-redux";
import { headerSettingArray } from "../constant/headerArray";
import { logOut} from "../redux/reducer/shared/authSlice";
const logoStyle = {
  width: "100px",
  height: "80px",
};
const DashboardHeader = ({ open, toggleSidebar }) => {
  const { displayName } = useAuth();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState(null);
//   const [logout] = useSendLogOutMutation();
const dispatch = useDispatch();
  const [sendLogOut] = useSendLogOutMutation();

  const handleLogout = async () => {
    try {
      // Send logout request to the server
      await sendLogOut();

      // Dispatch logout action to clear the token in the Redux store
      dispatch(logOut());
      navigate('/')
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onClickProfile = async () => {
    navigate("/");
  };
//   const onClickLogout = async () => {
//     try {
//       await logout();
//       navigate("/");
//     } catch (error) {}
//   };

  const onClickSetting = (event, item) => {
    event.preventDefault();

    handleCloseUserMenu();

    if (item === "Logout") {
        handleLogout();
      return;
    } 
    else if (item === "Home") {
      onClickProfile();
      return;
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        sx={{ background: "#FFFFFF", color: "#212B36" }}
      >
        <Toolbar
          sx={{
            pr: "24px",
          }}
        >
          <IconButton
            edge="start"
            aria-label="open drawer"
            onClick={toggleSidebar}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{
              fontWeight: 500,
              fontSize: "0.25rem",
              lineHeight: 0.6,
              flexGrow: 1,
              display: {
                xs: open ? "none" : "block",
                md: "block",
                sm: "block",
              },
            }}
          >
            {/* <img src={Logo} alt="Logo" /> */}
            <img src={Logo} style={logoStyle} alt="logo of sitemark" />
          </Typography>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{
              fontWeight: 500,
              flexGrow: 1,

              display: {
                xs: "block",

                md: "none",
                sm: "none",
              },
            }}
          ></Typography>
          <Toolbar color="inherit">
            <Box
              sx={{
                color: "#294289",
                display: "flex",
              }}
            >
              {/* <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
              >
                <Badge>
                  <ControlPointRoundedIcon sx={{ width: 44, height: 29 }} />
                </Badge>
              </IconButton> */}
              {/* <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge sx={{ width: 35, height: 24 }}>
                  <img src={qicon} alt="Question icon" />
                </Badge>
              </IconButton> */}

              <Box sx={{ flexGrow: 0 }}>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="stretch"
                  wrap="wrap"
                  sx={{ mt: 0.7 }}
                >
                  <Typography
                    variant="body1"
                    float="right"
                    style={{ color: themeColor.headerButtonText }}
                    sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                  >
                    Hi,
                    {displayName}
                  </Typography>

                  <IconButton onClick={handleOpenUserMenu}>
                    <Avatar
                      sx={{ width: 27, height: 26 }}
                      alt={displayName}
                      src="/static/images/avatar/2.jpg"
                      style={{
                        color: themeColor.headerButtonText,
                        backgroundColor: themeColor.secondary,
                      }}
                    />
                  </IconButton>
                </Grid>

                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {headerSettingArray?.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={(event) => onClickSetting(event, item)}
                    >
                      <Typography textAlign="center">{item}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default DashboardHeader;

