import React from 'react'
import Home from './Home'

const index = () => {
  return (
    <>
      <Home/>
    </>
  )
}

export default index
