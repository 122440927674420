// // import {createSlice} from '@reduxjs/toolkit';

// // const name="auth";

// // const authSlice=createSlice({
// //     name,
// //     initialState:{accessToken:null},
// //     reducers:{
// //         setCredentials:(state,action)=>
// //             {
// //                 const {accessToken}=action.payload;
// //                 state.accessToken=accessToken;
// //             },
// //             logOut:(state,action)=>
// //             {
// // state.accessToken=null;
// //             }
// //     }

// // });
// // export default authSlice.reducer;
// // export const {setCredentials,logOut}=authSlice.actions;
// // export const selectCurrentToken=(state)=>state[name].accessToken;



// // import { createSlice } from '@reduxjs/toolkit'

// // const authSlice = createSlice({
// //     name: 'auth',
// //     //initialState: { token: null },
// //     initialState: { token: localStorage.getItem('token') || null },
// //     reducers: {
// //         // setCredentials: (state, action) => {
// //         //     const { accessToken } = action.payload
// //         //     state.token = accessToken
// //         // },

// //         // setToken: (state, action) => {
// //         //     state.token = action.payload;
// //         //     // localStorage.setItem('token', action.payload);
// //         //     localStorage.setItem('token', JSON.stringify(action.payload)); 
// //         //   },

// //         setToken: (state, action) => {
// //             const tokenString = JSON.stringify(action.payload);
// //             state.token = tokenString;
// //             localStorage.setItem('token', tokenString); 
// //         },
        
// //         logOut: (state, action) => {
// //             state.token = null
// //             localStorage.removeItem('token');
// //         },
// //     }
// // })

// // // export const { setCredentials, logOut } = authSlice.actions
// // export const { setToken, logOut } = authSlice.actions

// // export default authSlice.reducer

// // export const selectCurrentToken = (state) => state.auth.token || localStorage.getItem('token');




// // import { createSlice } from "@reduxjs/toolkit";
// // // import { tagTypeDict } from "../../common/tag";

// // // const name = tagTypeDict.auth;

// // const authSlice = createSlice({
// //   name:'auth',
// //   initialState: { accessToken: null },
// //   reducers: {
// //     setCredentials: (state, action) => {
// //       const { accessToken } = action.payload;
// //       state.accessToken = accessToken;
// //     },
// //     logOut: (state, action) => {
// //       state.accessToken = null;
// //     },
// //   },
// // });

// // export default authSlice.reducer;

// // export const { setCredentials, logOut } = authSlice.actions;
// // export const selectCurrentToken = (state) => state[name].accessToken;



// // import { createSlice } from "@reduxjs/toolkit";

// // // Define a consistent name for the slice which is used in selector
// // const sliceName = 'auth';

// // const authSlice = createSlice({
// //   name: sliceName,
// //   initialState: { accessToken: null },
// //   reducers: {
// //     setToken: (state, action) => {
// //       const { accessToken } = action.payload;
// //       state.accessToken = accessToken;
// //     },
// //     logOut: (state) => {
// //       state.accessToken = null;
// //     },
// //   },
// // });
// // export default authSlice.reducer;
// // export const { setToken, logOut } = authSlice.actions;
// // // Selector to get the access token from the state
// // export const selectCurrentToken = (state) => state[sliceName].accessToken;


// import { createSlice } from '@reduxjs/toolkit';
// const sliceName = 'auth';
// const initialState = {
//     accessToken: null
// };

// const authSlice = createSlice({
//     name: sliceName ,
//     initialState,
//     reducers: {
//         setToken: (state, action) => {
//             state.accessToken = action.payload.accessToken;
//         },
//         logOut: (state) => {
//             state.accessToken = null;
//         },
//     }
// });

// export const { setToken, logOut } = authSlice.actions;
// export default authSlice.reducer;

// export const selectCurrentToken = (state) => state[sliceName].accessToken;


// import {createSlice} from '@reduxjs/toolkit';

// const name="auth";

// const authSlice=createSlice({
//     name,
//     initialState:{accessToken:null},
//     reducers:{
//         setCredentials:(state,action)=>
//             {
//                 const {accessToken}=action.payload;
//                 state.accessToken=accessToken;
//             },
//             logOut:(state,action)=>
//             {
// state.accessToken=null;
//             }
//     }

// });
// export default authSlice.reducer;
// export const {setCredentials,logOut}=authSlice.actions;
// export const selectCurrentToken=(state)=>state[name].accessToken;



// import { createSlice } from '@reduxjs/toolkit'

// const authSlice = createSlice({
//     name: 'auth',
//     //initialState: { token: null },
//     initialState: { token: localStorage.getItem('token') || null },
//     reducers: {
//         // setCredentials: (state, action) => {
//         //     const { accessToken } = action.payload
//         //     state.token = accessToken
//         // },

//         // setToken: (state, action) => {
//         //     state.token = action.payload;
//         //     // localStorage.setItem('token', action.payload);
//         //     localStorage.setItem('token', JSON.stringify(action.payload)); 
//         //   },

//         setToken: (state, action) => {
//             const tokenString = JSON.stringify(action.payload);
//             state.token = tokenString;
//             localStorage.setItem('token', tokenString); 
//         },
        
//         logOut: (state, action) => {
//             state.token = null
//             localStorage.removeItem('token');
//         },
//     }
// })

// // export const { setCredentials, logOut } = authSlice.actions
// export const { setToken, logOut } = authSlice.actions

// export default authSlice.reducer

// export const selectCurrentToken = (state) => state.auth.token || localStorage.getItem('token');




// import { createSlice } from "@reduxjs/toolkit";
// // import { tagTypeDict } from "../../common/tag";

// // const name = tagTypeDict.auth;

// const authSlice = createSlice({
//   name:'auth',
//   initialState: { accessToken: null },
//   reducers: {
//     setCredentials: (state, action) => {
//       const { accessToken } = action.payload;
//       state.accessToken = accessToken;
//     },
//     logOut: (state, action) => {
//       state.accessToken = null;
//     },
//   },
// });

// export default authSlice.reducer;

// export const { setCredentials, logOut } = authSlice.actions;
// export const selectCurrentToken = (state) => state[name].accessToken;



// import { createSlice } from "@reduxjs/toolkit";

// // Define a consistent name for the slice which is used in selector
// const sliceName = 'auth';

// const authSlice = createSlice({
//   name: sliceName,
//   initialState: { accessToken: null },
//   reducers: {
//     setToken: (state, action) => {
//       const { accessToken } = action.payload;
//       state.accessToken = accessToken;
//     },
//     logOut: (state) => {
//       state.accessToken = null;
//     },
//   },
// });
// export default authSlice.reducer;
// export const { setToken, logOut } = authSlice.actions;
// // Selector to get the access token from the state
// export const selectCurrentToken = (state) => state[sliceName].accessToken;


// import { createSlice } from '@reduxjs/toolkit';
// const sliceName = 'auth';
// const initialState = {
//     accessToken: null
// };

// const authSlice = createSlice({
//     name: sliceName ,
//     initialState,
//     reducers: {
//         setToken: (state, action) => {
//             state.accessToken = action.payload.accessToken;
//         },
//         logOut: (state) => {
//             state.accessToken = null;
//         },
//     }
// });

// export const { setToken, logOut } = authSlice.actions;
// export default authSlice.reducer;

// export const selectCurrentToken = (state) => state[sliceName].accessToken;



import { createSlice } from "@reduxjs/toolkit";
//import { tagTypeDict } from "../../common/tag";

//const name = tagTypeDict.auth;

const authSlice = createSlice({
 name: 'auth',
  initialState: { accessToken: null },
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken } = action.payload;
      state.accessToken = accessToken;
    },
    logOut: (state, action) => {
      state.accessToken = null;
    },
  },
});

export default authSlice.reducer;

export const { setCredentials, logOut } = authSlice.actions;
//export const selectCurrentToken = (state) => state[name].accessToken;
export const selectCurrentToken = (state) => state.auth.accessToken
