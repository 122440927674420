import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import GeneralHeader from "./GeneralHeader";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
// import Landing from "../feature/home";
const GeneralLayout = () => {
  return (
    <>
      <Box>
        <CssBaseline />

        <GeneralHeader />
{/* <Landing/> */}
        <Outlet />

        <Footer />
      </Box>
    </>
  );
};

export default GeneralLayout;
