import { queryListener } from "../../constant/object";
import { useGetInquiriesByCustomerQuery } from "../../redux/reducer/api/inquiryApiSlice";

const useInquiriesByCustomerQuery = (quoteId, option = queryListener.default) => {
  let array = [];

  const { data, isLoading, isSuccess, refetch } =
  useGetInquiriesByCustomerQuery(quoteId, {
      ...option,
    });
  if (isSuccess) {
    array = data || []; 
    // const { entities } = data;
    // array = Object.values(entities) || [];
  }

  return {
    array,
    isLoading,
    refetch,
  };
};

export default useInquiriesByCustomerQuery;
