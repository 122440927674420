import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableCell,
  Tooltip,
  Toolbar,
  TablePagination,
  Button,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import useQuotesByCompanyQuery from "../../../hook/routing/useGetQuotesByCompanyQuery";
import QuoteViewCompany from "./QuoteViewCompany";
import useAuth from "../../../hook/useAuth"; 
import {
  globalStyle,
} from "../../../constant/globalStyle";
import { useNavigate } from "react-router-dom";
const columns = [
  { field: "quoteId", headerName: "Quote ID", width: 130 },
  { field: "services", headerName: "Services", width: 130 },
  { field: "totalSiteLoad", headerName: "Total Site Load", width: 130 },
  { field: "status", headerName: "Admin Status", width: 130 },
  { field: "action", headerName: "Actions", width: 130 },
];



const QuoteListComapny = () => {
  const navigate = useNavigate();
  const { array, isLoading, refetch } = useQuotesByCompanyQuery();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  

  const openAlertBox = async ({ title, message, confirmButtonText, cancelButtonText, onConfirm }) => {
    if (window.confirm(message)) {
      await onConfirm();
    }
  };

  const deleteUser = async (user) => {
    console.log("Deleting user:", user);
  };
  const handleDelete = async (object) => {
    try {
      await openAlertBox({
        title: "Confirmation",
        message: "Are you sure you want to permanently delete this record?",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        onConfirm: () => deleteUser(object),
      });
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleView = (quoteId) => {
    // Navigate to the quote detail page
    navigate(`/quote/${quoteId}`);
  };

  // const handleAddQuote = () => {
  //   // Add your logic to open the quote creation form or navigate to the creation page
  //   console.log("Add Quote button clicked");
  //   navigate('/customer/create-quote');
  // };

  return (
    <>
    
      <Paper elevation={2} sx={{ borderRadius: "15px",my:2, mx: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <Box component="span" sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            Customer Quotes
          </Box>
          {/* <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddQuote}
            sx={{ textTransform: "none" }}
          >
            Add Quote
          </Button> */}
        </Box>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow >
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    align="left"
                    sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
                  >
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>Loading...</TableCell>
                </TableRow>
              ) : (
                array
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.quoteId}>
                      <TableCell>{row.quoteId}</TableCell>
                      <TableCell>{row.services}</TableCell>
                      <TableCell>{row.totalSiteLoad}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>
                      {/* <Toolbar title="View">
                        
                          <IconButton>
                            <QuoteViewCompany  props={row} onCancel={() => {}} />
                          </IconButton>
                        </Toolbar> */}
                       
                     
                        <QuoteViewCompany props={row} />
                      
                        </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={array.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </>
  );
};

export default QuoteListComapny;
