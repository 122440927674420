import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import TtyOutlinedIcon from "@mui/icons-material/TtyOutlined";
import DialpadOutlinedIcon from "@mui/icons-material/DialpadOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";

export const headerMenuArray = [
  {
    label: "Dashboard",
    route: "/dashboardheader",
    logo: <HomeOutlinedIcon />,
  },
  {
    label: "Summary",
    route: "/dashboard/summary",
    logo: <SummarizeOutlinedIcon />,
  },
  {
    label: "Company",
    route: "/dashboard/user",
    logo: <BusinessOutlinedIcon />,
  },
  {
    label: "Smart Number",
    route: "/dashboard/smart-number",
    logo: <DialpadOutlinedIcon />,
  },
  {
    label: "Call Record",
    route: "/dashboard/call-record",
    logo: <TtyOutlinedIcon />,
  },
  {
    label: "Analytic",
    route: "/dashboard/analytic",
    logo: <AnalyticsOutlinedIcon />,
  },
];

// export const headerSettingArray = ["Profile", "Logout"];
export const headerSettingArray = ["Home","Logout"];
