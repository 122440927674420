import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import AdminStat from './AdminStat';
import AdminAppbar from './AdminAppbar';
import AdminSidebar from './AdminSidebar';
import AdminQuotes from './AdminQuotes';
import AdminInquiries from './AdminInquiries';

const defaultTheme = createTheme();

function AdminDashboard() {
  const [activePage, setActivePage] = useState('stats'); 

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AdminAppbar />
        <AdminSidebar setActivePage={setActivePage} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {activePage === 'stats' && <AdminStat />}
            {activePage === 'quotes' && <AdminQuotes />}
            {activePage === 'enquiries' && <AdminInquiries />}
            
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default AdminDashboard;