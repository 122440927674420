// // // // import { drawerItemStyleDefault } from "./style";
// // // // import ListItem from "@mui/material/ListItem";
// // // // import ListItemButton from "@mui/material/ListItemButton";
// // // // import ListItemIcon from "@mui/material/ListItemIcon";
// // // // import ListItemText from "@mui/material/ListItemText";
// // // // import Collapse from "@mui/material/Collapse";
// // // // import { useNavigate } from "react-router";
// // // // import List from "@mui/material/List";
// // // // import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// // // // import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// // // // import { useState } from "react";

// // // // const SideBarItem = ({ open, item, children }) => {
// // // //   const navigate = useNavigate();
// // // //   const [expandList, setExpandList] = useState(false);

// // // //   const onClickItem = (event) => {
// // // //     event.preventDefault();

// // // //     if (!children) navigate(item.route);
// // // //     else setExpandList((previous) => !previous);
// // // //   };

// // // //   if (!children)
// // // //     return (
// // // //       <>
// // // //         <ListItem
// // // //           disablePadding
// // // //           sx={drawerItemStyleDefault}
// // // //           onClick={onClickItem}
// // // //         >
// // // //           <ListItemButton
// // // //             sx={{
// // // //               minHeight: 48,
// // // //               justifyContent: open ? "initial" : "center",
// // // //               px: 2.5,
// // // //             }}
// // // //           >
// // // //             <ListItemIcon
// // // //               sx={{
// // // //                 minWidth: 0,
// // // //                 mr: open ? 3 : "auto",
// // // //                 justifyContent: "center",
// // // //               }}
// // // //             >
// // // //               {item.logo}
// // // //             </ListItemIcon>

// // // //             <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
// // // //           </ListItemButton>
// // // //         </ListItem>
// // // //       </>
// // // //     );

// // // //   return (
// // // //     <>
// // // //       <ListItem
// // // //         disablePadding
// // // //         sx={drawerItemStyleDefault}
// // // //         onClick={onClickItem}
// // // //       >
// // // //         <ListItemButton
// // // //           sx={{
// // // //             minHeight: 48,
// // // //             justifyContent: open ? "initial" : "center",
// // // //             px: 2.5,
// // // //           }}
// // // //         >
// // // //           <ListItemIcon
// // // //             sx={{
// // // //               minWidth: 0,
// // // //               mr: open ? 3 : "auto",
// // // //               justifyContent: "center",
// // // //             }}
// // // //           >
// // // //             {item.logo}
// // // //           </ListItemIcon>

// // // //           <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />

// // // //           {expandList && open && <ExpandLessIcon />}

// // // //           {!expandList && open && <ExpandMoreIcon />}
// // // //         </ListItemButton>
// // // //       </ListItem>

// // // //       <Collapse in={expandList} timeout="auto" unmountOnExit>
// // // //         <List component="div" disablePadding style={{ paddingLeft: 12 }}>
// // // //           {children.map((item, index) => (
// // // //             <SideBarItem key={index} open={open} item={item} />
// // // //           ))}
// // // //         </List>
// // // //       </Collapse>
// // // //     </>
// // // //   );
// // // // };

// // // // export default SideBarItem;


// // // import React from "react";
// // // import ListItem from "@mui/material/ListItem";
// // // import ListItemButton from "@mui/material/ListItemButton";
// // // import ListItemIcon from "@mui/material/ListItemIcon";
// // // import ListItemText from "@mui/material/ListItemText";
// // // import { Link } from "react-router-dom";

// // // const SideBarItem = ({ item, open }) => {
// // //   return (
// // //     <Link to={item.route} style={{ textDecoration: "none", color: "inherit" }}>
// // //       <ListItem key={item.name} disablePadding sx={{ display: "block" }}>
// // //         <ListItemButton
// // //           sx={{
// // //             minHeight: 48,
// // //             justifyContent: open ? "initial" : "center",
// // //             px: 2.5,
// // //           }}
// // //         >
// // //           <ListItemIcon
// // //             sx={{
// // //               minWidth: 0,
// // //               mr: open ? 3 : "auto",
// // //               justifyContent: "center",
// // //             }}
// // //           >
// // //             {item.logo}
// // //           </ListItemIcon>
// // //           <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
// // //         </ListItemButton>
// // //       </ListItem>
// // //     </Link>
// // //   );
// // // };

// // // export default SideBarItem;



// // import { drawerItemStyleDefault } from "./style";
// // import ListItem from "@mui/material/ListItem";
// // import ListItemButton from "@mui/material/ListItemButton";
// // import ListItemIcon from "@mui/material/ListItemIcon";
// // import ListItemText from "@mui/material/ListItemText";
// // import Collapse from "@mui/material/Collapse";
// // import { useNavigate } from "react-router";
// // import List from "@mui/material/List";
// // import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// // import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// // import { useState } from "react";

// // const SideBarItem = ({ open, item, children }) => {
// //   const navigate = useNavigate();
// //   const [expandList, setExpandList] = useState(false);

// //   const onClickItem = (event) => {
// //     event.preventDefault();

// //     if (!children) navigate(item.route);
// //     else setExpandList((previous) => !previous);
// //   };

// //   if (!children)
// //     return (
// //       <>
// //         <ListItem
// //           disablePadding
// //           sx={drawerItemStyleDefault}
// //           onClick={onClickItem}
// //         >
// //           <ListItemButton
// //             sx={{
// //               minHeight: 48,
// //               justifyContent: open ? "initial" : "center",
// //               px: 2.5,
// //             }}
// //           >
// //             <ListItemIcon
// //               sx={{
// //                 minWidth: 0,
// //                 mr: open ? 3 : "auto",
// //                 justifyContent: "center",
// //               }}
// //             >
// //               {item.logo}
// //             </ListItemIcon>

// //             <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
// //           </ListItemButton>
// //         </ListItem>
// //       </>
// //     );

// //   return (
// //     <>
// //       <ListItem
// //         disablePadding
// //         sx={drawerItemStyleDefault}
// //         onClick={onClickItem}
// //       >
// //         <ListItemButton
// //           sx={{
// //             minHeight: 48,
// //             justifyContent: open ? "initial" : "center",
// //             px: 2.5,
// //           }}
// //         >
// //           <ListItemIcon
// //             sx={{
// //               minWidth: 0,
// //               mr: open ? 3 : "auto",
// //               justifyContent: "center",
// //             }}
// //           >
// //             {item.logo}
// //           </ListItemIcon>

// //           <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />

// //           {expandList && open && <ExpandLessIcon />}

// //           {!expandList && open && <ExpandMoreIcon />}
// //         </ListItemButton>
// //       </ListItem>

// //       <Collapse in={expandList} timeout="auto" unmountOnExit>
// //         <List component="div" disablePadding style={{ paddingLeft: 12 }}>
// //           {children.map((item, index) => (
// //             <SideBarItem key={index} open={open} item={item} />
// //           ))}
// //         </List>
// //       </Collapse>
// //     </>
// //   );
// // };

// // export default SideBarItem;




// import { drawerItemStyleDefault } from "./style";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import Collapse from "@mui/material/Collapse";
// import { useNavigate } from "react-router";
// import List from "@mui/material/List";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import { useState } from "react";
// const SideBarItem = ({ open, item, children }) => {
//   const navigate = useNavigate();
//   const [expandList, setExpandList] = useState(false);
//   const onClickItem = (event) => {
//     event.preventDefault();

//     if (!children) navigate(item.route);
//     else setExpandList((previous) => !previous);
//   };

//   if (!children)
//     return (
//       <>
//         <ListItem
//           disablePadding
//           sx={drawerItemStyleDefault}
//           onClick={onClickItem}
//         >
//           <ListItemButton
//             sx={{
//               minHeight: 48,
//               justifyContent: open ? "initial" : "center",
//               px: 2.5,
//             }}
//           >
//             <ListItemIcon
//               sx={{
//                 minWidth: 0,
//                 mr: open ? 3 : "auto",
//                 justifyContent: "center",
//               }}
//             >
//               {item.logo}
//             </ListItemIcon>

//             <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
//           </ListItemButton>
//         </ListItem>
//       </>
//     );

//   return (
//     <>
//       <ListItem
//         disablePadding
//         sx={drawerItemStyleDefault}
//         onClick={onClickItem}
//       >
//         <ListItemButton
//           sx={{
//             minHeight: 48,
//             justifyContent: open ? "initial" : "center",
//             px: 2.5,
//           }}
//         >
//           <ListItemIcon
//             sx={{
//               minWidth: 0,
//               mr: open ? 3 : "auto",
//               justifyContent: "center",
//             }}
//           >
//             {item.logo}
//           </ListItemIcon>

//           <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />

//           {expandList && open && <ExpandLessIcon />}

//           {!expandList && open && <ExpandMoreIcon />}
//         </ListItemButton>
//       </ListItem>

//       <Collapse in={expandList} timeout="auto" unmountOnExit>
//         <List component="div" disablePadding style={{ paddingLeft: 12 }}>
//           {children.map((item, index) => (
//             <SideBarItem key={index} open={open} item={item} />
//           ))}
//         </List>
//       </Collapse>
//     </>
//   );
// };

// export default SideBarItem;


import { drawerItemStyleDefault } from "./style";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { useNavigate } from "react-router";
import List from "@mui/material/List";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useState } from "react";

const SideBarItem = ({ open, item }) => {
  const navigate = useNavigate();
  const [expandList, setExpandList] = useState(false);

  const onClickItem = (event) => {
    event.preventDefault();
    if (!item.subMenu) {
      navigate(item.route);
    } else {
      setExpandList((previous) => !previous);
    }
  };

  return (
    <>
      <ListItem
        disablePadding
        sx={drawerItemStyleDefault}
        onClick={onClickItem}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {item.logo}
          </ListItemIcon>
          <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
          {item.subMenu && open && (expandList ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
        </ListItemButton>
      </ListItem>

      {item.subMenu && (
        <Collapse in={expandList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{ paddingLeft: 12 }}>
            {item.subMenu.map((subItem, index) => (
              <SideBarItem key={index} open={open} item={subItem} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default SideBarItem;
