
import { Outlet,useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useRefreshMutation } from "../../redux/reducer/api/authApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/reducer/shared/authSlice";
import { CircularProgress } from "@mui/material";

const PersistLogin = () => {
  const navigate = useNavigate();
  const persist = true;
  const token = useSelector(selectCurrentToken);
  const effectRan = useRef(false);
  const [trueSuccess, setTrueSuccess] = useState(false);
  const [refresh, { isUninitialized, isLoading, isSuccess, isError, error }] =
    useRefreshMutation();

  useEffect(() => {
    if (effectRan.current === false) {
      const verifyRefreshToken = async () => {
        try {
          await refresh();
          setTrueSuccess(true);
        } catch (err) {}
      };

      if (!token && persist) verifyRefreshToken();
    }

    return () => (effectRan.current = true);
  }, [persist, refresh, token]);

  let content;

  if (process.env.NODE_ENV !== "production") return <Outlet />;

  if (!persist) content = <Outlet />;
  else if (isLoading) content = <CircularProgress />;
  else if (isError){
    navigate("/login"); // Redirects to login page on error
    content = null; // Optionally, return null since navigation will handle the redirection
  }
    // content = (
    //   <p>
    //     {`${error?.data?.message} - `}
    //     <Link to="/">Please login again</Link>.
    //   </p>
    // );
  else if (isSuccess && trueSuccess) content = <Outlet />;
  else if (token && isUninitialized) content = <Outlet />;

  return content;
};

export default PersistLogin;
