import { queryListener } from "../../constant/object";
import { useGetQuoteByIdQuery } from "../../redux/reducer/api/quoteApiSlice";

const useGetQuoteById = (quoteId, option = queryListener.default) => {
    console.log("Received Quote ID..G:", quoteId);

    const { data, isLoading, isSuccess, isError, error, refetch } = useGetQuoteByIdQuery(quoteId, { ...option });

    let quote = null;

    if (isLoading) {
        console.log("Loading data...");
    }

    if (isError) {
        console.error("Error fetching quote:", error);
    }

    if (isSuccess) {
        console.log("Data received from API:", data);

        if (data && data.entities) {
            quote = data.entities[quoteId] || null;
        } else {
            console.log("No data returned from API.");
        }
    } else {
        console.log("Failed to fetch data or data is not available");
    }

    console.log("Quote data is:", quote);

    return {
        quote,
        isLoading,
        refetch
    };
};

export default useGetQuoteById;
