import { apiSlice } from "../../service/apiSlice"
// import { logOut, setCredentials } from "../../shared/authSlice"
// import { logOut, setToken } from "../../shared/authSlice"
import { logOut,setCredentials } from "../shared/authSlice";
export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        signUp:builder.mutation({
            query:(payload)=>({
                    url:'/users',
                    method:"POST",
                    body:payload
                }),
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled
                        console.log("Sign up successful:", data);
                        const { accessToken } = data;
                        dispatch(setCredentials({ accessToken }));
                    } catch (err) {
                        console.error("Sign up error:", err);
                    }
                }
        }),


    
        sendOtp: builder.mutation({
            query: ({ email }) => ({
              url: '/sendotp',
              method: 'POST',
              body: { email }
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
              try {
                const { data } = await queryFulfilled;
                console.log("OTP sent successfully:", data);
              } catch (err) {
                console.error("OTP sending error:", err);
              }
            }
          }),
      
        // login: builder.mutation({
        //     query: (payload) => ({
        //         url: '/auth',
        //         method: 'POST',
        //         body: { ...payload }
        //     })
        // }),

        login: builder.mutation({
            query: credentials => ({
              url: '/auth',
              method: 'POST',
              body: credentials,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
              try {
                const { data } = await queryFulfilled;
                console.log("Login response:", data); // Log to confirm the structure
                dispatch(setCredentials({ accessToken: data.accessToken }));
              } catch (error) {
                console.error('Login error:', error);
              }
            }
          }),



        sendLogOut: builder.mutation({
            query: () => ({
                url: '/auth/logout',
                method: 'POST',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    console.log(data)
                    dispatch(logOut())
                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState())
                    }, 1000)
                } catch (err) {
                    console.log(err)
                }
            }
        }),

forgetPassword: builder.mutation({
    query: (data) => ({
        url: '/users/resetpassword',
        method: 'PATCH',
        body: data
    }),
    // Add more hooks here if needed for success/error handling
}),
        refresh: builder.mutation({
            query: () => ({
                url: '/auth/refresh',
                method: 'GET',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    console.log(data)
                    const { accessToken } = data
                    // dispatch(setCredentials({ accessToken }))
                    dispatch(setCredentials({ accessToken }))
                } catch (err) {
                    console.log(err)
                }
            }
        }),
        forgotpasswordOTP: builder.mutation({
            query: ({ email }) => ({
              url: '/sendotp/forgotpassword',
              method: 'POST',
              body: { email }
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
              try {
                const { data } = await queryFulfilled;
                console.log("OTP sent successfully:", data);
              } catch (err) {
                console.error("OTP sending error:", err);
              }
            }
          }),

    })
})

export const {
    useSignUpMutation,
    useLoginMutation,
    useSendLogOutMutation,
    useRefreshMutation,
    useSendOtpMutation,
    useForgotpasswordOTPMutation,
    useForgetPasswordMutation,
} = authApiSlice



// import { apiSlice } from "../../service/apiSlice";
// import { logOut, setToken } from "../shared/authSlice";

// export const authApiSlice = apiSlice.injectEndpoints({
//     endpoints: builder => ({
//         signUp: builder.mutation({
//             query: (credentials) => ({
//                 url: '/users',
//                 method: 'POST',
//                 body: credentials,
//             }),
//             async onQueryStarted(arg, { dispatch, queryFulfilled }) {
//                 try {
//                     const { data } = await queryFulfilled;
//                     console.log("Sign up successful:", data);
//                     if (data.accessToken) {
//                         dispatch(setToken({ accessToken: data.accessToken }));
//                     }
//                 } catch (error) {
//                     console.error("Sign up error:", error);
//                 }
//             }
//         }),

//         sendOtp: builder.mutation({
//             query: ({ email }) => ({
//                 url: '/sendotp',
//                 method: 'POST',
//                 body: { email }
//             }),
//             async onQueryStarted(arg, { dispatch, queryFulfilled }) {
//                 try {
//                     const { data } = await queryFulfilled;
//                     console.log("OTP sent successfully:", data);
//                 } catch (error) {
//                     console.error("OTP sending error:", error);
//                 }
//             }
//         }),

//         login: builder.mutation({
//             query: (credentials) => ({
//                 url: '/auth',
//                 method: 'POST',
//                 body: credentials,
//             }),
//             async onQueryStarted(arg, { dispatch, queryFulfilled }) {
//                 try {
//                     const { data } = await queryFulfilled;
//                     console.log("Login response:", data);
//                     if (data.accessToken) {
//                         dispatch(setToken({ accessToken: data.accessToken }));
//                     }
//                 } catch (error) {
//                     console.error('Login error:', error);
//                 }
//             }
//         }),

//         logOut: builder.mutation({
//             query: () => ({
//                 url: '/auth/logout',
//                 method: 'POST',
//             }),
//             async onQueryStarted(arg, { dispatch, queryFulfilled }) {
//                 try {
//                     await queryFulfilled;
//                     dispatch(logOut());
//                 } catch (error) {
//                     console.error('Logout error:', error);
//                 }
//             }
//         }),

//         forgetPassword: builder.mutation({
//             query: (data) => ({
//                 url: '/users/resetpassword',
//                 method: 'POST',
//                 body: data
//             }),
//             // Add more hooks here if needed for success/error handling
//         }),

//         refresh: builder.mutation({
//             query: () => ({
//                 url: '/auth/refresh',
//                 method: 'GET',
//             }),
//             async onQueryStarted(arg, { dispatch, queryFulfilled }) {
//                 try {
//                     const { data } = await queryFulfilled;
//                     if (data.accessToken) {
//                         dispatch(setToken({ accessToken: data.accessToken }));
//                     }
//                 } catch (error) {
//                     console.error('Refresh token error:', error);
//                 }
//             }
//         }),
//     })
// });

// export const {
//     useSignUpMutation,
//     useLoginMutation,
//     useLogOutMutation,
//     useRefreshMutation,
//     useSendOtpMutation,
//     useForgetPasswordMutation
// } = authApiSlice;
