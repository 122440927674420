import { Container } from "@mui/material";
import React from "react";

const ContainerGlobal=(props)=>
{
return(
    <>
    <Container maxWidth='xl' style={{marginTop:12}}>
{props.children}
    </Container>
    </>
)
}

export default ContainerGlobal;