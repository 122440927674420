// InquiryDetailModal.jsx
import React from 'react';
import { Modal, Box, Typography, Button ,Grid,Divider} from '@mui/material';

const EnquiryViewCustomer = ({ open, onClose, inquiry }) => {
  // const inquiryProps = inquiry || {};
  //   console.log("Inquiry Detail",inquiryProps)
    if (!inquiry) {
        return null; // Don't render the modal if inquiry is not available
      }
      const formatDate = (dateString) => {
        // Return an empty string if the dateString is null or invalid
        if (!dateString) {
          return "";
        }
      
        const date = new Date(dateString);
      
        // Check if the date is invalid
        if (isNaN(date.getTime())) {
          return "";
        }
      
        return date.toISOString().split('T')[0];  // Format as YYYY-MM-DD
      };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="detail-modal-title"
      aria-describedby="detail-modal-description"
    >
      {/* <Box
        sx={{
          position: 'absolute',
          top: '10%',
          left: '10%',
          width: '80%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: '10px',
          overflowY: 'auto',
          maxHeight: '80vh',
        }}
      >
        <Typography id="detail-modal-title" variant="h6" component="h2">
          Inquiry Details
        </Typography>
        {inquiry && (
          <Box sx={{ mt: 2 }}>
            <Typography>
              <strong>Inquiry ID:</strong> {inquiry.inquiryId}
            </Typography>
            <Typography>
              <strong>Offer Expiry Date:</strong> {inquiry.offerExpiryDate}
            </Typography>
            <Typography>
              <strong>Total Price:</strong> {inquiry.totalPrice}
            </Typography>
            <Typography>
              <strong>Admin Status:</strong> {inquiry.adminStatus}
            </Typography>
            <Typography>
              <strong>Customer Status:</strong> {inquiry.customerStatus}
            </Typography>
          </Box>
        )}
        <Button
          variant="contained"
          onClick={onClose}
          sx={{ mt: 3 }}
        >
          Back
        </Button>
      </Box> */}

      <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            // border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            overflowY: "auto",
            maxHeight: "80vh",
          }}
        >
        
         
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
            <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Enquiry Details
          </Typography>
          <Divider />
              <Typography variant="subtitle1"><strong>Enquiry ID:</strong> {inquiry.inquiryId}</Typography>
              <Typography variant="subtitle1"><strong>Offer Expiry Date:</strong> {formatDate(inquiry.offerExpiryDate)}</Typography>
              <Typography variant="subtitle1"><strong>Total Price:</strong> {inquiry.totalPrice}</Typography>
              <Typography variant="subtitle1"><strong>Admin Status:</strong> {inquiry.adminStatus}</Typography>
              <Typography variant="subtitle1"><strong>Customer Status:</strong> {inquiry.customerStatus}</Typography>
              <Typography variant="subtitle1"><strong>Description</strong> {inquiry.description}</Typography>
              <Button
          variant="contained"
          onClick={onClose}
          sx={{ mt: 3 }}
        >
          Back
        </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1"><strong>Drawing File</strong></Typography>
              {inquiry.drawingFile ? (
                <Button
                  variant="contained"
                  color="primary"
                  href={inquiry.drawingFile}
                  target="_blank"
                  sx={{ textTransform: "none", mt: 1 }}
                >
                  View File
                </Button>
              ) : (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  No offer letter available
                </Typography>
              )}
              <Typography variant="subtitle1"><strong>Proposal File</strong></Typography>
              {inquiry.proposalFile ? (
                <Button
                  variant="contained"
                  color="primary"
                  href={inquiry.proposalFile}
                  target="_blank"
                  sx={{ textTransform: "none", mt: 1 }}
                >
                  View File
                </Button>
              ) : (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  No offer letter available
                </Typography>
              )}
              <Typography variant="subtitle1"><strong>Additional Documents</strong></Typography>
              {inquiry.additionalDocuments?.map((file, index) => file && (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  href={file}
                  target="_blank"
                  sx={{ textTransform: "none", mt: 1, mr: 1 }}
                >
                  File {index + 1}
                </Button>
              ))}
             
            </Grid>

            

          

           
           
         
            
            
          </Grid>
        </Box>
    </Modal>
  );
};

export default EnquiryViewCustomer;
