import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button, IconButton } from "@mui/material";
import { globalStyle } from "../constant/globalStyle";
import { dashboardCardBorderRadius } from "./style";

const QuoteStatItem = ({ title, percentage, total, logo }) => {
  return (
    <>
      <Card
        variant="outlined"
        style={{ borderRadius: dashboardCardBorderRadius }}
      >
        <CardContent>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="stretch"
            wrap="wrap"
          >
            <Grid item xs>
              <Typography
                style={{
                  ...globalStyle,
                  color: "#595959",
                  fontWeight: 600,
                  fontSize: 12,
                }}
              >
                {title}
              </Typography>
            </Grid>

            <Grid item xs>
              <Typography
                style={{
                  ...globalStyle,
                  color: "#07591E",
                  textAlign: "end",
                  fontWeight: 600,
                  fontSize: 12,
                }}
              >
                {percentage}
              </Typography>
            </Grid>
          </Grid>

          <Typography
            style={{
              ...globalStyle,
              color: "#595959",
              fontWeight: 600,
              fontSize: 18,
              marginTop: 10,
            }}
          >
            {total}
          </Typography>

          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="stretch"
            wrap="wrap"
          >
            <Grid item xs>
              <Button
                variant="text"
                style={{
                  textDecorationLine: "underline",
                }}
              >
                View all Quotes
              </Button>
            </Grid>

            <Grid item>
              <IconButton size="small">{logo}</IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default QuoteStatItem;
