import * as React from "react";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
// import { logOut, selectCurrentToken } from "../redux/shared/authSlice";
import { logOut, selectCurrentToken } from "../redux/reducer/shared/authSlice";
import { useSendLogOutMutation } from "../redux/reducer/api/authApiSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  MenuItem,
  Typography,
  Drawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import logo from "../assets/header/logo.png";
import { Link, useNavigate } from "react-router-dom";
import OurGoals from "../feature/MenuPage/OurGoals";
// import { px } from 'framer-motion';

const logoStyle = {
  width: "180px",
  height: "150px",
  cursor: "pointer",
};

function GeneralHeader() {
  const [open, setOpen] = React.useState(false);
  const accessToken = useSelector(selectCurrentToken);
  const [featuresMenuOpen, setFeaturesMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

console.log("Token isssssssssssssssss: ",accessToken )
const dispatch = useDispatch();
  const [sendLogOut] = useSendLogOutMutation();
  
  const handleLogout = async () => {
    try {
      // Send logout request to the server
      await sendLogOut();

      // Dispatch logout action to clear the token in the Redux store
      dispatch(logOut());
    } catch (error) {
      console.error("Logout error:", error);
    }
  };



  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleSignUp = () => {
    navigate("/signup");
  };
  const handleSignIn = () => {
    navigate("/login");
  };

  const handleAboutus = () => {
    navigate("/aboutus");
  };
  const handleOurGoals = () => {
    navigate("/ourgoals");
  };

  const handleOurValues = () => {
    navigate("/ourvalues");
  };
  const trackQuote = () => {
    navigate("/customer/quote");
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };

  const handleFeaturesMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setFeaturesMenuOpen(true);
  };

  const handleFeaturesMenuClose = () => {
    setAnchorEl(null);
    setFeaturesMenuOpen(false);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "white",
          backgroundImage: "none",
        }}
      >
        <Box maxWidth="xl">
          <Toolbar
            variant="regular"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "rgba(255, 255, 255, 0.4)",
              backdropFilter: "blur(24px)",
              height: "100px",
              //border: '1px solid',
              //  borderColor: 'divider',
              boxShadow:
                "0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                // ml: '-18px',
                // mr:'280px',
              }}
            >
              <Link to="/">
                <img src={logo} style={logoStyle} alt="logo of sitemark" />
              </Link>
             
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                
                <MenuItem
                 onClick={handleAboutus}
                  sx={{
                    py: "6px",
                    px: "12px",
                    pl: "12px",
                    ml: "48px",
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: "#FFC400",
                    },
                  }}
                >
                  <Typography variant="body2" color="text.primary">
                  About Us
                  </Typography>
                </MenuItem>
               
                <MenuItem
                  onClick={handleOurGoals}
                  sx={{
                    py: "6px",
                    px: "12px",
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: "#FFC400",
                    },
                  }}
                >
                  <Typography variant="body2" color="text.primary">
                  Our Goals
                  
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleOurValues}
                  sx={{
                    py: "6px",
                    px: "12px",
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: "#FFC400",
                    },
                  }}
                >
                  <Typography variant="body2" color="text.primary">
                  Our Values
                  </Typography>
                </MenuItem>
                
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 0.5,
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={trackQuote}
                sx={{
                  backgroundColor: "#FFC400",
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#ffd54f",
                  },
                  
                }}
              >
                Track a Quote
              </Button>

              
              {accessToken ? (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  component="a"
                  //href="/material-ui/getting-started/templates/sign-in/"
                  target="_blank"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              ) : (
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    // onClick={handleLogin}
                    onClick={handleSignIn}
                  >
                    Login
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={handleSignUp}
                  >
                    Sign up
                  </Button>
                </Box>
              )}
            </Box>
            <Box sx={{ display: { sm: "", md: "none" } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: "30px", p: "4px" }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: "60dvw",
                    p: 2,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      flexGrow: 1,
                    }}
                  >
                    {/* Additional settings for the drawer */}
                  </Box>
                 

                  <MenuItem
                    onClick={handleAboutus}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    {/* <Typography variant="body2" color="text.primary" sx={{ display: 'flex', alignItems: 'center' }}> */}
                  About Us

                    {/* </Typography> */}
                  </MenuItem>
                 
                  <MenuItem onClick={handleOurGoals} >
                  Our Goals
                  
                  </MenuItem>
                  <MenuItem onClick={handleOurValues}>
                  Our Values
                  </MenuItem>
                  
                  <Divider />
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      component="a"
                      // href="/Request"
                      target="_blank"
                      onClick={trackQuote}
                      sx={{
                        backgroundColor: "#FFC400",
                        color: "black",
                        width: "100%",
                      }}
                    >
                      Track a Quote
                    </Button>
                  </MenuItem>
                  
                  {accessToken ? (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  component="a"
                  
                  target="_blank"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              ) : (
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    // onClick={handleLogin}
                    onClick={handleSignIn}
                  >
                    Login
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={handleSignUp}
                  >
                    Sign up
                  </Button>
                </Box>
              )}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </div>
  );
}

export default GeneralHeader;