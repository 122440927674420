// import React from 'react'
// import Box from "@mui/material/Box";
// import { IconButton, Tooltip } from "@mui/material";
// import { Modal } from "@mui/material";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// const EnquiryAdd = ({props}) => {
//     console.log(props)
//     const [open, setOpen] = React.useState(false);

//     const handleOpen = () => setOpen(true);
//     const handleClose = () => setOpen(false);
//   return (
//     <>
//       <Tooltip title="View">
//         <IconButton onClick={handleOpen}>
//           {/* <VisibilityIcon
//             style={{ color: "#294289", cursor: "pointer" }}
//           /> */}
//           Place Inquiry
//         </IconButton>
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box>
//             ascd
//         </Box>
//       </Modal>
//     </>
//   )
// }

// export default EnquiryAdd


// import React from "react";
// import Box from "@mui/material/Box";
// import { IconButton, Tooltip, Typography, Modal, Button } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";

// const EnquiryAdd = ({ props, open, setOpen,handleBack }) => {
//   const handleClose = () => setOpen(false);

//   return (
//     <>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="enquiry-modal-title"
//         aria-describedby="enquiry-modal-description"
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: 400,
//             bgcolor: "background.paper",
//             border: "2px solid #000",
//             boxShadow: 24,
//             p: 4,
//             borderRadius: "10px",
//           }}
//         >
//           <Box display="flex" justifyContent="space-between" alignItems="center">
//             <Typography id="enquiry-modal-title" variant="h6" component="h2">
//               Place Enquiry
//             </Typography>
//             <IconButton onClick={handleClose}>
//               <CloseIcon />
//             </IconButton>
//           </Box>
//           {/* Your form or content for placing an enquiry */}
//           <Typography variant="body1" sx={{ mt: 2 }}>
//             Inquiry form content goes here for Quote ID: {props.quoteId}
//           </Typography>
//           <Button
//             variant="contained"
//             color="primary"
//             fullWidth
//             sx={{ mt: 2 }}
//             onClick={handleClose} // Handle form submission here
//           >
//             Submit Enquiry
//           </Button>
//           <Button
//             variant="contained"
//             color="secondary"
//             fullWidth
//             sx={{ mt: 2 }}
//             onClick={handleBack} // Call the back handler
//           >
//             Back to Quote
//           </Button>
//         </Box>
//       </Modal>
//     </>
//   );
// };

// export default EnquiryAdd;



import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Snackbar,
  IconButton,
  Box,
  Tooltip,
  Modal
} from "@mui/material";
import ButtonLoading from "../../../constant/ButtonLoading";
import { useCreateInquiryMutation } from "../../../redux/reducer/api/inquiryApiSlice";
import {
  globalAddPaper,
  globalModalBtnStyle,
  globalModalTitleStyle,
} from "../../../constant/globalStyle";
import { useId } from "react";
import { uploadFileFormat } from "../../../constant/uploadFileFormat";
import CloseIcon from "@mui/icons-material/Close";

const validationSchema = yup.object().shape({
  totalPrice: yup.string().required("Total Price is required"),
  specialNote: yup.string(),
  offerExpiryDate: yup.date().required("Expiry date is required"),
});

const EnquiryAdd = ({ props, open, setOpen,handleBack }) => {
    const handleClose = () => setOpen(false);
  const idh = useId();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [drawingFile, setDrawingFile] = useState("");
  const [proposalFile, setProposalFile] = useState("");
  const [additionalDocuments, setAdditionalDocuments] = useState([]);

  const [createInquiry, { isLoading }] = useCreateInquiryMutation();

  const initialValues = {
    totalPrice: "",
    drawingFile: null,
    proposalFile: null,
    additionalDocuments: [],
    offerExpiryDate: "",
    description: "",
  };

  const handleDrawingFile = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setDrawingFile(file.name);
      setFieldValue("drawingFile", file);
      setTimeout(() => {
        setOpenSnackbar(true);
      }, 1500);
    } else {
      setDrawingFile("");
      setFieldValue("drawingFile", null);
    }
  };

  const handleProposalFile = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setProposalFile(file.name);
      setFieldValue("proposalFile", file);
      setTimeout(() => {
        setOpenSnackbar(true);
      }, 1500);
    } else {
      setProposalFile("");
      setFieldValue("proposalFile", null);
    }
  };

  // const handleFileChange = (event, setFieldValue) => {
  //   const files = event.target.files;
  //   if (files && files.length > 0) {
  //     const fileList = Array.from(files).map((file) => ({
  //       name: file.name,
  //       size: file.size,
  //     }));
  //     setAdditionalDocuments(fileList);
  //     setFieldValue("additionalDocuments", fileList);
  //   } else {
  //     setAdditionalDocuments([]);
  //     setFieldValue("additionalDocuments", []);
  //   }
  // };

  const handleFileChange = (event, setFieldValue) => {
    const files = event.target.files;
    console.log(files)
    const maxAdditionalDocuments = 3;

    if (files.length > maxAdditionalDocuments) {
      alert(`You can only upload a maximum of ${maxAdditionalDocuments} files.`);
      return;
    }
  
    if (files && files.length > 0) {
      const fileList = Array.from(files);
      setAdditionalDocuments(fileList);
      setFieldValue("additionalDocuments", fileList);
    } else {
      setAdditionalDocuments([]);
      setFieldValue("additionalDocuments", []);
    }
  };
  

  const fileDisplay = (files) =>
    files.map((file, index) => (
      <Typography key={index}>
        {uploadFileFormat(file.name)} - {Math.round(file.size / 1024)} KB
      </Typography>
    ));

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

//   const onSubmitHandle = async (values) => {
//     console.log(values);
//    handleClose();
//   };

const onSubmitHandle = async (values) => {
    const formData = new FormData();
    formData.append("totalPrice", values.totalPrice);
    formData.append("offerExpiryDate", values.offerExpiryDate);
    formData.append("description", values.description);
    formData.append("quoteId", props._id);

    if (values.drawingFile) {
      formData.append("drawingFile", values.drawingFile);
    }
    if (values.proposalFile) {
      formData.append("proposalFile", values.proposalFile);
    }
    if (values.additionalDocuments) {
      console.log("Additional Documents",values.additionalDocuments)
      Array.from(values.additionalDocuments).forEach((file) =>
        formData.append("additionalDocuments", file)
      );
    }

    try {
      const response = await createInquiry(formData).unwrap();
      console.log("Inquiry created successfully:", response);
      setAdditionalDocuments([]);
      setDrawingFile("");
      setProposalFile("");
      handleClose();
    } catch (error) {
       console.error("Failed to create inquiry:", error);
    // alert(`An error occurred during create imquiry: ${error.data?.message  || error.status ||  "Please try again later."}`);
    alert(`An error occurred during create imquiry: ${error.data?.error || error.data?.message }`);
    setAdditionalDocuments([]);
    setDrawingFile("");
    setProposalFile("");
    }
  };

  const commonAreaStyle = { height: "49px", width: "550px", marginLeft: "12px" };

  return (
        <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="enquiry-modal-title"
        aria-describedby="enquiry-modal-description"
      >
               <Box
          sx={{
           position: "absolute",
             top: "50%",
             left: "50%",
             transform: "translate(-50%, -50%)",
             width: "80%",
             bgcolor: "background.paper",
             border: "2px solid #000",
             boxShadow: 24,
            //  p: 4,
             borderRadius: "10px",
           }}
         >
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandle}
    >
     {/* <Form method="POST" enctype="multipart/form-data"> */}
      {({ isSubmitting, setFieldValue, values }) => (
       
        <Form>
          <Paper elevation={1} sx={{ ...globalAddPaper }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                {/* <Typography sx={{ ...globalModalTitleStyle, p: 2 }}>
                  Company Proposal
                </Typography> */}
                          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography id="enquiry-modal-title" variant="h6" component="h2">
               Place Enquiry
             </Typography>
                       <Typography variant="body1" >
             Quote ID: {props.quoteId}
           </Typography>
             <IconButton onClick={handleClose}>
               <CloseIcon />
             </IconButton>
           </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field name="totalPrice">
                  {({ field, meta }) => (
                    <TextField
                      id={`${idh}-totalPrice`}
                      label="Total Price"
                      fullWidth
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue("totalPrice", event.target.value)
                      }
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field name="offerExpiryDate">
                  {({ field, meta }) => (
                    <TextField
                      id={`${idh}-offerExpiryDate`}
                      label="Expected Date of Expiry"
                      type="date"
                      
                      fullWidth
                      onChange={(event) => {
                        setFieldValue("offerExpiryDate", event.target.value);
                      }}
                      InputLabelProps={{
        shrink: true, // This ensures the label is always shown above the input
      }}
      // InputProps={{
      //   inputProps: {
      //     placeholder: " ", // Adding an empty space to hide the placeholder
      //   },
      // }}
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field name="description">
                  {({ field }) => (
                    <TextField
                      id={`${idh}-description`}
                      label="Any Special Note"
                      fullWidth
                      {...field}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  component="label"
                  // sx={{
                  //   ...globalModalBtnStyle,
                  //   height: "49px",
                  //   width: "205px",
                  //   ml: 2,
                  // }}
                   sx={{
                      ...globalModalBtnStyle,
                      width: { xs: "100%", sm: "50%" },
                      height: "49px",
                    }}
                >
                  Upload Drawing File
                  <input
                    type="file"
                    hidden
                    onChange={(event) =>
                      handleDrawingFile(event, setFieldValue)
                    }
                  />
                </Button>
                {drawingFile && (
                  <Typography sx={{ ml: 1.5, mt: 1 }}>
                    {uploadFileFormat(drawingFile)}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} >
                <Button
                  variant="contained"
                  component="label"
                  // sx={{ ...globalModalBtnStyle, height: "49px", width: "200px" }}
                  sx={{
                      ...globalModalBtnStyle,
                      width: { xs: "100%", sm: "50%" },
                      height: "49px",
                    }}
                >
                  Upload Proposal File
                  <input
                    type="file"
                    hidden
                    onChange={(event) =>
                      handleProposalFile(event, setFieldValue)
                    }
                  />
                </Button>
                {proposalFile && (
                  <Typography sx={{ ml: 2, mt: 1 }}>
                    {uploadFileFormat(proposalFile)}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} >
                <Button
                  variant="contained"
                  component="label"
                  // sx={{ ...globalModalBtnStyle, height: "49px", ml: 2 }}
                  sx={{
                      ...globalModalBtnStyle,
                      width: { xs: "100%", sm: "50%" },
                      height: "49px",
                      
                    }}
                >
                  Upload Additional Files
                  <input
                    type="file"
                    hidden
                    multiple
                    onChange={(event) => handleFileChange(event, setFieldValue)}
                  />
                </Button>
                {additionalDocuments.length > 0 && (
                  <div style={{ marginTop: "10px" }}>
                    {fileDisplay(additionalDocuments)}
                  </div>
                )}
              </Grid>

              

              <Grid
               container
                item
                xs={12}
                sm={12}
//                 sx={{ 
//     display: "flex", 
//     justifyContent: "center", 
//     alignItems: "center", 
//     p: 2 
//   }}
justifyContent="center" // Center the buttons horizontally
//   spacing={2}  // Adds space between the buttons
  
              >
               <Button
             variant="contained"
            //  color="secondary"
             
             fullWidth
             sx={{
      ...globalModalBtnStyle,  // Apply your global button style
      width: { xs: "100%", sm: "25%" },
      height: "49px",
      mr: { xs: 0, sm: 2 },
      mt: { xs: 2, sm: 2 },
    //   backgroundColor: "secondary.main", // Secondary button color
      "&:hover": {
        backgroundColor: "secondary.dark", // Darker shade on hover
      },
    }}
             onClick={handleBack} // Call the back handler
           >
             Back to Quote
           </Button>
                {isSubmitting && <ButtonLoading />}
                {!isSubmitting && (
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{
                      ...globalModalBtnStyle,
                      width: { xs: "100%", sm: "25%" },
                      height: "49px",
                      mt: { xs: 2, sm: 2 },
                    }}
                  >
                    Save
                  </Button>
                )}
                          
              </Grid>
            </Grid>
          </Paper>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message="File uploaded successfully"
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          />
        </Form>
      )}
    </Formik>
    </Box>
    </Modal>
    </>
  );
};

export default EnquiryAdd;