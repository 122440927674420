// import React from 'react'
// import Box from "@mui/material/Box";
// import { IconButton, Tooltip } from "@mui/material";
// import { Modal } from "@mui/material";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// const QuoteViewCompany = ({props}) => {
//     console.log(props)
//     const [open, setOpen] = React.useState(false);

//     const handleOpen = () => setOpen(true);
//     const handleClose = () => setOpen(false);
//   return (
//     <>
//       <Tooltip title="View">
//         <IconButton onClick={handleOpen}>
//           <VisibilityIcon
//             style={{ color: "#294289", cursor: "pointer" }}
//           />
//         </IconButton>
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box>
//             ascd
//         </Box>
//       </Modal>
//     </>
//   )
// }

// export default QuoteViewCompany

import React from "react";
import Box from "@mui/material/Box";
import { IconButton, Tooltip, Typography, Modal, Grid, Divider, Button,Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Paper } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EnquiryAdd from "../enquiry/EnquiryAdd";

const QuoteViewCompany = ({ props }) => {
  const [open, setOpen] = React.useState(false);
  const [openEnquiry, setOpenEnquiry] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const handleOpenEnquiry = () => {
    setOpenEnquiry(true);
    handleClose(); // Close the QuoteViewCompany modal
  };

  const handleBackToQuoteView = () => {
    setOpen(true);  // Reopen the QuoteViewCompany modal
    setOpenEnquiry(false);  // Close the EnquiryAdd modal
  };
  const formatDate = (dateString) => {
    // Return an empty string if the dateString is null or invalid
    if (!dateString) {
      return "";
    }
  
    const date = new Date(dateString);
  
    // Check if the date is invalid
    if (isNaN(date.getTime())) {
      return "";
    }
  
    return date.toISOString().split('T')[0];  // Format as YYYY-MM-DD
  };
  const handleCloseEnquiry = () => setOpenEnquiry(false);

  return (
    <>
      <Tooltip title="View">
        <IconButton onClick={handleOpen}>
          <VisibilityIcon style={{ color: "#294289", cursor: "pointer" }} />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            height: "100%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            overflowY: "scroll",
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Quote Details
          </Typography>
          <Divider />
          <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6} >
              {/* <Typography variant="h6" component="h3" sx={{ mt: 2 }}>Quote Details</Typography>
              <Divider /> */}
              {/* <Typography variant="subtitle1"><strong>Full Address:</strong> {quote.fullAddress}</Typography> */}
              <Typography variant="subtitle1"><strong>Quote ID:</strong> {props.quoteId}</Typography>
              <Typography variant="subtitle1"><strong>Status:</strong> {props.status}</Typography>
              <Typography variant="subtitle1"><strong>Services:</strong> {props.services.replace(/[0-9.]/g, "")}</Typography>
              <Typography variant="subtitle1"><strong>Grid Reference:</strong> {props.GridReference}</Typography>
              <Typography variant="subtitle1"><strong>DNO:</strong> {props.DNO}</Typography>
              <Typography variant="subtitle1"><strong>Heating Type:</strong> {props.heatingType.replace(/[0-9.]/g, "")}</Typography>
              <Typography variant="subtitle1"><strong>Custom Heating Type:</strong> {props.customHeatingType}</Typography>
              <Typography variant="subtitle1"><strong>Total Site Load:</strong> {props.totalSiteLoad }</Typography>
              <Typography variant="subtitle1"><strong>Quote deadline:</strong> {formatDate(props.quoteDeadline)}</Typography>
              <Typography variant="subtitle1"><strong>Expected date:</strong> {formatDate(props.expectedDate)}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} >
            <Typography variant="subtitle1"><strong>Offer Letter File:</strong></Typography>

              {props.offerLetterFile ? (
                <Button
                  variant="contained"
                  color="primary"
                  href={props.offerLetterFile}
                  target="_blank"
                  sx={{ textTransform: "none", mt: 1 }}
                >
                  View File
                </Button>
              ) : (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                 
                </Typography>
              )}
              <Typography variant="subtitle1"><strong>Site Plan Files:</strong></Typography>
              {props.sitePlanFiles?.map((file, index) => file && (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  href={file}
                  target="_blank"
                  sx={{ textTransform: "none", mt: 1, mr: 1 }}
                >
                  File {index + 1}
                </Button>
              ))}
              
              {/* <Typography variant="h6" component="h3" sx={{ mt: 2 }}>Customer Details</Typography>
              <Divider />
              <Typography variant="subtitle1"><strong>Username:</strong> {customer.username || "N/A"}</Typography>
              <Typography variant="subtitle1"><strong>Email:</strong> {customer.email || "N/A"}</Typography>
              <Typography variant="subtitle1"><strong>Contact Number:</strong> {customer.contactNumber || "N/A"}</Typography> */}
            </Grid>
            {/* <Grid item xs={6}>
              <Typography variant="subtitle1"><strong>Plots:</strong></Typography>
              {props.plots?.map((plot, index) => (
                <Box key={index}  sx={{ mt: 1, ml: 2 }}>
                  <Typography variant="body2">Plot {index + 1}</Typography>
                  <Typography variant="body2">Type: {plot.plotType}</Typography>
                  <Typography variant="body2">Name: {plot.plotName || ""}</Typography>
                  <Typography variant="body2">Units: {plot.units}</Typography>
                  <Typography variant="body2">Load per Plot: {plot.loadPerPlot}</Typography>
                </Box>
              ))}
            </Grid> */}
            <Grid item xs={12}>
              <Typography variant="subtitle1"><strong>Plots:</strong></Typography>
              <TableContainer component={Paper}>
                <Table aria-label="plots table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Plot Type</strong></TableCell>
                      <TableCell><strong>Plot Name</strong></TableCell>
                      <TableCell><strong>Units</strong></TableCell>
                      <TableCell><strong>Load per Plot</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.plots?.map((plot, index) => (
                      <TableRow key={index}>
                        <TableCell>{plot.plotType}</TableCell>
                        <TableCell>{plot.plotName || ""}</TableCell>
                        <TableCell>{plot.units}</TableCell>
                        <TableCell>{plot.loadPerPlot}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                sx={{ textTransform: "none", mr: 2 }}
              >
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenEnquiry}
                sx={{ textTransform: "none" }}
              >
                Place Enquiry
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
               onClick={handlePlaceEnquiry}
                sx={{ textTransform: "none" }}
              > */}
                {/* Place Enquiry */}
                {/* <EnquiryAdd  open={openEnquiry} 
        handleClose={handleCloseEnquiry} 
        props={props}  onCancel={() => {}}/> */}
              {/* </Button> */}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <EnquiryAdd
        props={props}
        open={openEnquiry}
        setOpen={setOpenEnquiry}
        handleBack={handleBackToQuoteView} // Pass the back handler
      />
    </>
  );
};

export default QuoteViewCompany;

