// import React from "react";
// import Stack from "@mui/material/Stack";
// import Typography from "@mui/material/Typography";
// import {Box} from '@mui/material';
// import { keyframes } from "@mui/system";

// // Define keyframes for fadeInUp animation
// const fadeInUp = keyframes`
//   from {
//     opacity: 0;
//     transform: translateY(20px);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

// const Aboutus = () => {
//   return (



// <Box>
//     <Typography
//         component="h5"
//         variant="h5"
//         sx={{
//           display: "flex",
//           flexDirection: { xs: "column", md: "row" },
//           alignSelf: "center",
//           textAlign: "center",
//           fontSize: { xs: "5rem", sm: "5rem" },
//           animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
//         }}
//       >
//         About Infraverse
//       </Typography>
//     <Stack
//       spacing={2}
//       useFlexGap
//       sx={{
//         width: { xs: "100%", sm: "100%" },
//         pt: { xs: 2, sm: 10 },
//         pb: { xs: 2, sm: 10 },
//       }}
//     >
// <Typography
//         component="h5"
//         variant="h5"
//         sx={{
//           display: "flex",
//           flexDirection: { xs: "column", md: "row" },
//           alignSelf: "center",
//           textAlign: "center",
//           fontSize: { xs: "5rem", sm: "5rem" },
//           animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
//         }}
//       >
//         About InfraVerse
//       </Typography>

//       <Typography
//         variant="body1"
//         color="text.secondary"
//         sx={{
//           lineHeight: "1.6",
//           letterSpacing: "0.5px",
         
//           fontSize: "16px",
//           gap: "20px",
//           padding: "20px",
//           animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
//         }}
//       >
//        InfraVerse Ltd is at the forefront of technological innovation,
//        spearheading a transformative shift in the electricity connection 
//        process within the UK's construction industry. Our pioneering digital
//        platform stands as the premier centralized marketplace, bridging the gap between customers 
//        – including developers, consultants, and construction firms – 
//        and key industry players such as Independent Connection Providers (ICPs), 
//        Distribution Network Operators (DNOs), and Independent Distribution Network Operators (IDNOs).
//       <br />
// Distinguished by its commitment to transparency, efficiency, and cost-effectiveness, 
// our platform revolutionizes the procurement of electricity connections. 
// Through a streamlined approach facilitated by transparent bidding, 
// customers gain access to a range of proposals, empowering them to select 
// the most fitting option and significantly reducing both project timelines and costs. 
// This innovative model challenges the dominance of major corporations, 
// fostering healthy competition and catalyzing innovation throughout the industry.
// <br />
        
//       </Typography>



//       <Typography
//         component="h5"
//         variant="h5"
//         sx={{
//           display: "flex",
//           flexDirection: { xs: "column", md: "row" },
//           alignSelf: "center",
//           textAlign: "center",
        
//           animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
//         }}
//       >
//         Our Vision
//       </Typography>

//       <Typography
//         variant="body1"
//         color="text.secondary"
//         sx={{
//           lineHeight: "1.6",
//           letterSpacing: "0.5px",
//           // fontWeight: "bold",
//           fontSize: "16px",
//           gap: "20px",
//           padding: "20px",
//           animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
//         }}
//       >
//        At InfraVerse Ltd, our vision is to redefine the future of infrastructure development
//         in the UK by pioneering unparalleled innovation and sustainability in the electricity 
//         connection process. We envision a future where every construction project, from towering 
//         skyscrapers to renewable energy installations, is seamlessly powered by efficient, transparent, 
//         and sustainable electricity connections.<br />
// We aspire to be the driving force behind a transformative shift in the construction 
// and utility sectors, where outdated processes are replaced with cutting-edge technology 
// and streamlined solutions. Our vision encompasses not only revolutionizing the way electricity 
// connections are obtained but also catalyzing the transition towards a carbon-neutral and sustainable 
// energy ecosystem.<br />
// Through our unwavering commitment to innovation, transparency, and environmental stewardship,
//  we aim to empower developers, consultants, construction companies, and renewable energy pioneers 
//  with the tools and resources they need to thrive in a rapidly evolving landscape. 
//  We envision a future where equitable access to electricity connections fuels economic growth, 
//  fosters innovation, and accelerates the journey towards a greener, more sustainable tomorrow.<br />

  
        
//       </Typography>



//       <Typography
//         component="h5"
//         variant="h5"
//         sx={{
//           display: "flex",
//           flexDirection: { xs: "column", md: "row" },
//           alignSelf: "center",
//           textAlign: "center",
        
//           animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
//         }}
//       >
//         Our Mission
//       </Typography>

//       <Typography
//         variant="body1"
//         color="text.secondary"
//         sx={{
//           lineHeight: "1.6",
//           letterSpacing: "0.5px",
//           // fontWeight: "bold",
//           fontSize: "16px",
//           gap: "20px",
//           padding: "20px",
//           animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
//         }}
//       >
//        InfraVerse Ltd is at the forefront of technological innovation,
//        spearheading a transformative shift in the electricity connection 
//        process within the UK's construction industry. Our pioneering digital
//        platform stands as the premier centralized marketplace, bridging the gap between customers 
//        – including developers, consultants, and construction firms – 
//        and key industry players such as Independent Connection Providers (ICPs), 
//        Distribution Network Operators (DNOs), and Independent Distribution Network Operators (IDNOs).
//       <br />
// Distinguished by its commitment to transparency, efficiency, and cost-effectiveness, 
// our platform revolutionizes the procurement of electricity connections. 
// Through a streamlined approach facilitated by transparent bidding, 
// customers gain access to a range of proposals, empowering them to select 
// the most fitting option and significantly reducing both project timelines and costs. 
// This innovative model challenges the dominance of major corporations, 
// fostering healthy competition and catalyzing innovation throughout the industry.
// <br />
        
//       </Typography>
//     </Stack>

// </Box>
//   );
// };

// export default Aboutus;

import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box } from '@mui/material';
import { keyframes } from "@mui/system";

// Define keyframes for fadeInUp animation
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Aboutus = () => {
  return (
    <Box sx={{ color: "#FFFFFF", px: "20px",py:"40px",mt:3 }}>
     
      <Stack
        spacing={2}
        useFlexGap
        sx={{
          width: { xs: "100%", sm: "100%" },
          pt: { xs: 2, sm: 10 },
          pb: { xs: 2, sm: 10 },
        }}
      >
       <Typography
        component="h5"
        variant="h5"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignSelf: "center",
          textAlign: "center",
          fontSize: { xs: "2rem", sm: "3rem" },
          animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
          color:"#FFC400",
          py:"20px",
        }}
      >
        About InfraVerse
      </Typography>
        <Typography
          variant="body1"
          color="inherit"
          sx={{
            lineHeight: "1.6",
            letterSpacing: "0.5px",
            fontSize: "16px",
            gap: "20px",
            padding: "20px",
            textAlign: "justify",
            animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
            backgroundColor:"#003C5F",
          }}
        >
          InfraVerse Ltd is at the forefront of technological innovation, spearheading a transformative shift in the electricity connection process within the UK's construction industry. Our pioneering digital platform stands as the premier centralized marketplace, bridging the gap between customers – including developers, consultants, and construction firms – and key industry players such as Independent Connection Providers (ICPs), Distribution Network Operators (DNOs), and Independent Distribution Network Operators (IDNOs).
          <br />
          Distinguished by its commitment to transparency, efficiency, and cost-effectiveness, our platform revolutionizes the procurement of electricity connections. Through a streamlined approach facilitated by transparent bidding, customers gain access to a range of proposals, empowering them to select the most fitting option and significantly reducing both project timelines and costs. This innovative model challenges the dominance of major corporations, fostering healthy competition and catalyzing innovation throughout the industry.
          <br />
        </Typography>

        <Typography
          component="h5"
          variant="h5"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignSelf: "center",
            textAlign: "center",
            fontSize: { xs: "2rem", sm: "3rem" },
            animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
            color:"#FFC400",
          py:"20px",
            
          }}
        >
          Our Vision
        </Typography>

        <Typography
          variant="body1"
          color="inherit"
          sx={{
            lineHeight: "1.6",
            letterSpacing: "0.5px",
            fontSize: "16px",
            gap: "20px",
            padding: "20px",
            textAlign: "justify",
            animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
            backgroundColor:"#003C5F",
          }}
        >
          At InfraVerse Ltd, our vision is to redefine the future of infrastructure development in the UK by pioneering unparalleled innovation and sustainability in the electricity connection process. We envision a future where every construction project, from towering skyscrapers to renewable energy installations, is seamlessly powered by efficient, transparent, and sustainable electricity connections.
          <br />
          We aspire to be the driving force behind a transformative shift in the construction and utility sectors, where outdated processes are replaced with cutting-edge technology and streamlined solutions. Our vision encompasses not only revolutionizing the way electricity connections are obtained but also catalyzing the transition towards a carbon-neutral and sustainable energy ecosystem.
          <br />
          Through our unwavering commitment to innovation, transparency, and environmental stewardship, we aim to empower developers, consultants, construction companies, and renewable energy pioneers with the tools and resources they need to thrive in a rapidly evolving landscape. We envision a future where equitable access to electricity connections fuels economic growth, fosters innovation, and accelerates the journey towards a greener, more sustainable tomorrow.
          <br />
        </Typography>

        <Typography
          component="h1"
          variant="h1"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignSelf: "center",
            textAlign: "center",
            fontSize: { xs: "2rem", sm: "3rem" },
            animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
            
          color:"#FFC400",
          py:"20px",
          }}
        >
          Our Mission
        </Typography>

        <Typography
          variant="body1"
          color="inherit"
          sx={{
            lineHeight: "1.6",
            letterSpacing: "0.5px",
            fontSize: "16px",
            gap: "20px",
            padding: "20px",
            textAlign: "justify",
            animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
            backgroundColor:"#003C5F",
          }}
        >
          InfraVerse Ltd is at the forefront of technological innovation, spearheading a transformative shift in the electricity connection process within the UK's construction industry. Our pioneering digital platform stands as the premier centralized marketplace, bridging the gap between customers – including developers, consultants, and construction firms – and key industry players such as Independent Connection Providers (ICPs), Distribution Network Operators (DNOs), and Independent Distribution Network Operators (IDNOs).
          <br />
          Distinguished by its commitment to transparency, efficiency, and cost-effectiveness, our platform revolutionizes the procurement of electricity connections. Through a streamlined approach facilitated by transparent bidding, customers gain access to a range of proposals, empowering them to select the most fitting option and significantly reducing both project timelines and costs. This innovative model challenges the dominance of major corporations, fostering healthy competition and catalyzing innovation throughout the industry.
          <br />
        </Typography>
      </Stack>
    </Box>
  );
};

export default Aboutus;