// import React, { useEffect, useState } from "react";
// import {
//   Table,
//   TableBody,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
//   TableCell,
//   Tooltip,
//   TablePagination,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import useEnquiriesByCompanyQuery from "../../../hook/routing/useGetEnquiriesByCompanyQuery";
// import {
//   globalStyle,
// } from "../../../constant/globalStyle";

// const columns = [
//     { field: "inquiryId", headerName: "Enquiry ID", width: 130 },
//     { field: "offerExpiryDate", headerName: "Offer Expiry Date", width: 130 },
//     { field: "totalPrice", headerName: "Total Price", width: 130 },
//     { field: "adminStatus", headerName: "Admin Status", width: 130 },
//     { field: "customerStatus", headerName: "Customer Status", width: 130 },
//     // { field: "action", headerName: "Actions", width: 130 },
//   ];



// const CompanyEnquiryList = () => {
//   const { array, isLoading, refetch } = useEnquiriesByCompanyQuery();
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   const openAlertBox = async ({ title, message, confirmButtonText, cancelButtonText, onConfirm }) => {
//     if (window.confirm(message)) {
//       await onConfirm();
//     }
//   };

// //   const deleteUser = async (user) => {
// //     console.log("Deleting user:", user);
// //   };
// //   const handleDelete = async (object) => {
// //     try {
// //       await openAlertBox({
// //         title: "Confirmation",
// //         message: "Are you sure you want to permanently delete this record?",
// //         confirmButtonText: "Delete",
// //         cancelButtonText: "Cancel",
// //         onConfirm: () => deleteUser(object),
// //       });
// //     } catch (error) {
// //       console.error("Error during deletion:", error);
// //     }
// //   };

// //   const handleEdit = (object) => {
// //     console.log("Editing quote:", object);
// //     // Add your logic to open the edit form or navigate to the edit page
// //   };

//   useEffect(() => {
//     refetch();
//   }, [refetch]);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

  

//   return (
//     <>
// {/* <AdminAppbar/>
// <AdminSidebar/> */}
    
//       <Paper elevation={2} sx={{ borderRadius: "15px",my:2, mx: 4 }}>
//         <TableContainer>
//           <Table aria-label="simple table">
//             <TableHead>
//               <TableRow>
//                 {columns.map((column) => (
//                   <TableCell
//                     key={column.field}
//                     align="left"
//                     sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
//                   >
//                     {column.headerName}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>

//             <TableBody>
//               {isLoading ? (
//                 <TableRow>
//                   <TableCell colSpan={columns.length}>Loading...</TableCell>
//                 </TableRow>
//               ) : (
//                 array
//                   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                   .map((row) => (
//                     <TableRow key={row.inquiryId}>
//                       <TableCell>{row.inquiryId}</TableCell>
//                       <TableCell>{row.offerExpiryDate}</TableCell>
//                       <TableCell>{row.totalPrice}</TableCell>
//                       <TableCell>{row.adminStatus}</TableCell>
//                       <TableCell>{row.customerStatus}</TableCell>
//                       {/* <TableCell>
//                       <Tooltip title="Edit">
//                         <IconButton
//                           color="primary"
//                           onClick={() => handleEdit(row)}
//                         >
//                           <EditIcon />
//                         </IconButton>
//                       </Tooltip>
//                         <Tooltip title="Delete">
//                           <IconButton
//                             color="error"
//                             onClick={() => handleDelete(row)}
//                           >
//                             <DeleteIcon />
//                           </IconButton>
//                         </Tooltip>
//                       </TableCell> */}
                     
//                     </TableRow>
//                   ))
//               )}
//             </TableBody>
//           </Table>
//           <TablePagination
//             rowsPerPageOptions={[5, 10, 25]}
//             component="div"
//             count={array.length}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </TableContainer>
//       </Paper>
//     </>
//   );
// };

// export default CompanyEnquiryList;


import React, { useEffect } from "react";
import { DataGrid, GridToolbar, useGridApiContext } from "@mui/x-data-grid";
import {
  Paper,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
// import AddIcon from "@mui/icons-material/Add";
import DataGridHeader from "../../../component/data-grid/DataGridHeader";
import CustomNoRowsOverlay from "../../../component/data-grid/CustomNoRowsOverlay";
import useEnquiriesByCompanyQuery from "../../../hook/routing/useGetEnquiriesByCompanyQuery";
import EnquiryViewCompany from "./EnquiryViewCompany";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
// import { useNavigate } from "react-router-dom";

const CompanyEnquiryList = () => {
  // const navigate = useNavigate();
  const [pageSize, setPageSize] = React.useState(10);
  const { array, refetch, isLoading } = useEnquiriesByCompanyQuery();

  useEffect(() => {
    refetch().catch((error) => {
      console.error("Error fetching data:", error);
    });
  }, [refetch]);

  
  const formattedOfferExpiryDate = array?.offerExpiryDate
  ? new Date(array.offerExpiryDate).toISOString().split("T")[0]
  : "";
  

  

  // const handleQuote = () => {
  //   navigate("/customer/create-quote");
  // };

 

  

  return (
    <Paper elevation={2} sx={{ borderRadius: "15px",pt:2 }}>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: "#1976d2",
            '&:hover': {
              backgroundColor: "#1565c0",
            }
          }}
          onClick={handleQuote} 
        >
          Add Quote
        </Button>
      </Box> */}
      <DataGrid
        autoHeight
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        rows={array}
        loading={isLoading}
        getRowId={(row) => row.inquiryId}
        slots={{
          toolbar: GridToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        columns={[
          {
            field: "inquiryId",
            flex: 1,
            hide: true,
            renderHeader: () => <DataGridHeader header={"Inquiry Id"} />,
            headerAlign: "center",
            align: "center",
          },
          // {
          //   field: "offerExpiryDate",
          //   flex: 0,
          //   renderHeader: () => <DataGridHeader header={"Offer Expiry Date"} />,
          //   headerAlign: "center",
          //   align: "center",
            
          // },
          {
  field: "offerExpiryDate",
  flex: 1,
  renderHeader: () => <DataGridHeader header={"Offer Expiry Date"} />,
  headerAlign: "center",
  align: "center",
  renderCell: (params) => {
    // Check if the offerExpiryDate exists and format it
    const offerExpiryDate = params.row?.offerExpiryDate
      ? new Date(params.row.offerExpiryDate).toISOString().split("T")[0]
      : "N/A"; // Fallback if offerExpiryDate is missing

    return offerExpiryDate;
  },
},

          {
            field: "totalPrice",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Total Price"} />,
            headerAlign: "center",
            align: "center",
          },
          {
            field: "adminStatus",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Admin Status"} />,
            headerAlign: "center",
            align: "center",

          },
          {
            field: "customerStatus",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Customer Status"} />,
            headerAlign: "center",
            align: "center",
          },
          {
            field: "actions",
            sortable: false,
            filterable: false,
            // hideable: false,
            // hideFil
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Actions"} />,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              return (
                <>
                  
                 
                  {
                    <EnquiryViewCompany props={params.row} />
                  }
                </>
              );
            },
          },
        ]}
      />
    </Paper>
  );
};

export default CompanyEnquiryList;