export const userTypeObject = {
  Admin: "Admin",
  IntegrationEngineer: "Integration Engineer",
  Customer: "Customer",
  Company: "Company",
};

export const queryListener = {
  default: {
    pollingInterval: 60000,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  },
  slow: {
    pollingInterval: 300000,
    refetchOnMountOrArgChange: true,
  },
  fast: {
    pollingInterval: 60000,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  },
  edit: {
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  },
};
