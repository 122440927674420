import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { TextField, Typography, Grid, CircularProgress, MenuItem, Select } from "@mui/material";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useSignUpMutation, useSendOtpMutation } from '../../redux/reducer/api/authApiSlice';

export default function SignUp() {
  const navigate = useNavigate();
  const [signUp, { isLoading: isSignUpLoading }] = useSignUpMutation();
  const [sendOtp, { isLoading: isOtpLoading }] = useSendOtpMutation();
  //const [otpRequested, setOtpRequested] = useState(false);
  const [timer, setTimer] = useState(0);

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      contactNumber: "",
      password: "",
      confirmPassword: "",
      otp: "",
      roles: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Username is required"),
      email: Yup.string().email("Invalid email address").required("Email is required"),
      contactNumber: Yup.string().required("Contact Number is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/,
          "Must contain 6 characters and a special character."
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      // otp: otpRequested ? Yup.string().required("OTP is required") : Yup.string().nullable(),
      otp: Yup.string().required("OTP is required") ,
      roles: Yup.string().required("Account type is required"),
    }),
    onSubmit: (values) => {
      console.log('Values for sign Up : ',values)
      signUp(values)
        .unwrap()
        .then(response => {
          console.log("Sign up successful:", response);
          navigate("/login");
        })
        .catch(error => {
          //console.error("Signup error:", error);
          //alert("An error occurred during signup. Please try again later.");
          alert(`An error occurred during signup: ${error.data?.message || "Please try again later."}`);
        });
    }
  });

  // const handleRequestOtp = async () => {
  //   if (!formik.values.email) {
  //     alert("Please enter a valid email to request OTP.");
  //     return;
  //   }
  //   try {
  //     await sendOtp({ email: formik.values.email }).unwrap();
  //     setOtpRequested(true);
  //   } catch (error) {
  //     console.error("Error requesting OTP:", error);
  //     alert(`Failed to send OTP: ${error.data?.error || error.status || "Please try again later."}`);
  //   }
  // };
  // const accessToken = useSelector(selectCurrentToken);


  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleRequestOtp = async () => {
    formik.setFieldTouched('email', true); // Trigger validation for email field
   //await formik.validateField('email'); // Validate email field

    if (formik.errors.email || !formik.values.email.trim()) {
      console.log('Email validation error:', formik.errors.email); // Debugging line
      return; // Exit if email is invalid
    }
     const email = formik.values.email.trim();
    // if (!email) {
    //   alert("Please enter a valid email to request OTP.");
    //   return;
    // } 
    try {
      await sendOtp({ email }).unwrap();
      setTimer(60); // 4 minutes and 59 seconds = 299 seconds
      //setOtpRequested(true);
    } catch (error) {
      //console.error("Error requesting OTP:", error);
      //alert(`Failed to send OTP: ${error.data?.error || error.status || "Please try again later."}`);
      alert(`Failed to send OTP: ${error.data?.message || error.status || "Please try again later."}`);
    }
  };

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center",py:"50px" }}>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="username"
                name="username"
                label="Username"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
              />
            </Grid>

            {/* start */}
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email Address"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={4}>
              {/* <Button onClick={handleRequestOtp} variant="outlined" disabled={otpRequested || isOtpLoading}>
                {isOtpLoading ? <CircularProgress size={24} /> : "Request OTP"}
              </Button> */}
              <Button onClick={handleRequestOtp} variant="outlined" disabled={isOtpLoading || timer > 0}>
              {isOtpLoading ? <CircularProgress size={24} /> : timer > 0 ? `Retry in ${formatTime(timer)}` : "Request OTP"}
              </Button> 

            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="otp"
                name="otp"
                label="OTP"
                value={formik.values.otp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
               // disabled={!otpRequested}
                error={formik.touched.otp && Boolean(formik.errors.otp)}
                helperText={formik.touched.otp && formik.errors.otp}
              />
            </Grid>

            {/* End */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="contactNumber"
                name="contactNumber"
                label="Contact Number"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                helperText={formik.touched.contactNumber && formik.errors.contactNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="password"
                id="password"
                name="password"
                label="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="roles"
                name="roles"
                label="Roles Type"
                select
                SelectProps={{ native: true }}
                value={formik.values.roles}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.roles && Boolean(formik.errors.roles)}
                helperText={formik.touched.roles && formik.errors.roles}
              >
                <option ></option>
                <option value="Customer">Customer</option>
                <option value="Company">Company</option>
              </TextField>
            </Grid>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isSignUpLoading}>
              Sign Up
            </Button>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
}
