import React from "react";
import CustomerStatItem from "./CustomerStatItem";
import Grid from "@mui/material/Grid";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote"; // Icon for quotes
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"; // Icon for enquiries

const statArray = [
  {
    title: "Total Quotes",
    percentage: "16",
    total: "5",
    logo: <FormatQuoteIcon />,
    link:"Number of quotes",
     // More specific icon
  },
  {
    title: "Number of Enquires",
    percentage: "10",
    total: "10",
    logo: <QuestionAnswerIcon />,
    link:"Number of Enquires",
    // More specific icon
  },
  // Uncomment and update these if needed
  // {
  //   title: "Highest Call",
  //   percentage: "16.24%",
  //   total: "1950",
  //   logo: <CallIcon />, // Relevant for calls
  // },
  // {
  //   title: "Missed Calls",
  //   percentage: "16.24%",
  //   total: "120",
  //   logo: <CallIcon />, // Relevant for calls
  // },
];

const Customerstat = () => {
  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="wrap"
      >
        {statArray?.map((item, index) => (
          <Grid item lg md={6} xs={12} key={index}>
            <CustomerStatItem
              title={item?.title}
              percentage={item?.percentage}
              total={item?.total}
              logo={item?.logo}
              link={item?.link}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Customerstat;
