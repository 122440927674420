import { useGetEnquiriesByCompanyQuery } from "../../redux/reducer/api/inquiryApiSlice";
import { queryListener } from "../../constant/object";
const useEnquiriesByCompanyQuery = (option = queryListener.default) => {
  let array = [];

  const { data, isLoading, isSuccess, refetch } = useGetEnquiriesByCompanyQuery(option);

  if (isSuccess) {
    array = data || []; 
  }
  //console.log("All Quotes array is :",array)
  return {
    array,
    isLoading,
    refetch,
  };
};

export default useEnquiriesByCompanyQuery;
