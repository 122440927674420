import React from 'react';
import { Modal, Box, Typography, Button ,Grid,Divider,IconButton,Tooltip,} from '@mui/material';
import VisibilityIcon from "@mui/icons-material/Visibility";

const EnquiryViewCompany = ({ props }) => {
     console.log("Inquiry Detail",props)
    // if (!inquiry) {
    //     return null; // Don't render the modal if inquiry is not available
    //   }
    const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
    <Tooltip title="View">
        <IconButton onClick={handleOpen}>
          <VisibilityIcon style={{ color: "#294289", cursor: "pointer" }} />
        </IconButton>
      </Tooltip>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="detail-modal-title"
      aria-describedby="detail-modal-description"
    >
      {/* <Box
        sx={{
          position: 'absolute',
          top: '10%',
          left: '10%',
          width: '80%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: '10px',
          overflowY: 'auto',
          maxHeight: '80vh',
        }}
      >
        <Typography id="detail-modal-title" variant="h6" component="h2">
          Inquiry Details
        </Typography>
        {inquiry && (
          <Box sx={{ mt: 2 }}>
            <Typography>
              <strong>Inquiry ID:</strong> {inquiry.inquiryId}
            </Typography>
            <Typography>
              <strong>Offer Expiry Date:</strong> {inquiry.offerExpiryDate}
            </Typography>
            <Typography>
              <strong>Total Price:</strong> {inquiry.totalPrice}
            </Typography>
            <Typography>
              <strong>Admin Status:</strong> {inquiry.adminStatus}
            </Typography>
            <Typography>
              <strong>Customer Status:</strong> {inquiry.customerStatus}
            </Typography>
          </Box>
        )}
        <Button
          variant="contained"
          onClick={onClose}
          sx={{ mt: 3 }}
        >
          Back
        </Button>
      </Box> */}

      <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            overflowY: "auto",
            maxHeight: "80vh",
          }}
        >
        
         
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
            <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Enquiry Details
          </Typography>
          <Divider />
              <Typography variant="subtitle1"><strong>Enquiry ID:</strong> {props.inquiryId}</Typography>
              <Typography variant="subtitle1"><strong>Offer Expiry Date:</strong> {props.offerExpiryDate}</Typography>
              <Typography variant="subtitle1"><strong>Total Price:</strong> {props.totalPrice}</Typography>
              <Typography variant="subtitle1"><strong>Admin Status:</strong> {props.adminStatus}</Typography>
              <Typography variant="subtitle1"><strong>Customer Status:</strong> {props.customerStatus}</Typography>
              <Button
          variant="contained"
          onClick={handleClose}
          sx={{ mt: 3 }}
        >
          Back
        </Button>
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography variant="subtitle1"><strong>Drawing File</strong></Typography>
              {props.drawingFile ? (
                <Button
                  variant="contained"
                  color="primary"
                  href={props.drawingFile}
                  target="_blank"
                  sx={{ textTransform: "none", mt: 1 }}
                >
                  View File
                </Button>
              ) : (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  No offer letter available
                </Typography>
              )}
              <Typography variant="subtitle1"><strong>Proposal File</strong></Typography>
              {props.proposalFile ? (
                <Button
                  variant="contained"
                  color="primary"
                  href={props.proposalFile}
                  target="_blank"
                  sx={{ textTransform: "none", mt: 1 }}
                >
                  View File
                </Button>
              ) : (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  No offer letter available
                </Typography>
              )}
              <Typography variant="subtitle1"><strong>Additional Documents</strong></Typography>
              {props.additionalDocuments?.map((file, index) => file && (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  href={file}
                  target="_blank"
                  sx={{ textTransform: "none", mt: 1, mr: 1 }}
                >
                  File {index + 1}
                </Button>
              ))}
             
            </Grid>

            

          

           
           
         
            
            
          </Grid>
        </Box>
    </Modal>
    </>
  );
};

export default EnquiryViewCompany;