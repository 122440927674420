import React, { forwardRef, useState ,useEffect} from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Modal,
  Grid,
  Button,
  TextField,
  CircularProgress,
  InputLabel,
  MenuItem,FormControl,Select,
  colors,
  Grow
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Formik, useField,Form, Field } from "formik";
import * as yup from "yup";
import { notification } from "../../../util/notification";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  globalModalTextfieldLabelStyle,
  globalModalTextfieldStyle,
  globalModalTitleStyle,
} from "../../../constant/globalStyle";
import { globalStyle } from "../../../constant/globalStyle";
import ButtonLoading from "../../../constant/ButtonLoading";
import { uploadFileFormat } from "../../../constant/uploadFileFormat";
import { useDeleteInquiryFileMutation,useUpdateInquiryMutation } from "../../../redux/reducer/api/inquiryApiSlice";
import { useParams } from "react-router-dom";
//import useAllInquiries from "../../../hook/routing/useGetAllInquiries";
// import useGetInquiryById from "../../../hook/routing/useGetInquiryById";
import useAllInquiries from "../../../hook/routing/useGetAllInquiries";
const statusTypes=["Pending", "Declined","Approved"];
const MySelect = ({ label, placeholder, isBoolean = false, ...props }) => {
  const [field, meta, helpers] = useField(props); // helpers for programmatic changes

  const renderDisplayValue = (selected) => {
    if (selected === "" || selected === null || selected === undefined) {
      return <Typography color="textSecondary">{placeholder}</Typography>;
    }
    if (isBoolean) {
      return selected === true ? "True" : "False"; // Display "True" or "False" for boolean values
    }
    return selected; // Display the actual value for non-boolean fields
  };

  return (
    <FormControl fullWidth error={meta.touched && !!meta.error}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        {...props}
        value={field.value === undefined || field.value === null ? "" : field.value} // Ensure value is correct
        displayEmpty
        onChange={(e) => {
          const selectedValue = e.target.value;
          helpers.setValue(selectedValue); // Update Formik state
        }}
        renderValue={renderDisplayValue}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {isBoolean ? (
          [
            <MenuItem key="true" value={true}>
              True
            </MenuItem>,
            <MenuItem key="false" value={false}>
              False
            </MenuItem>,
          ]
        ) : (
          props.children // Render children (MenuItems) passed to MySelect
        )}
      </Select>
      {meta.touched && meta.error ? (
        <Typography color="error" variant="body2">
          {meta.error}
        </Typography>
      ) : null}
    </FormControl>
  );
};
const EnquiryEditAdmin = forwardRef(({props}) => {
  const {inquiryId}=useParams();
  const enquiry = props || {};
  console.log("Enquiry id is that",enquiry?.inquiryId)
//   const { inquiry,refetch} = useGetInquiryById(enquiry?.inquiryId);
const { array, refetch } = useAllInquiries();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [drawingFile,setDrawingFile]=useState(null);
  const [proposalFile,setProposalFile]=useState(null);
  const [additionalDocuments,setAdditionalDocuments]=useState([]);
  
  const [updateInquiry]=useUpdateInquiryMutation();
const [deleteInquiryFile]=useDeleteInquiryFileMutation();
  //console.log("Props Value", props);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
//   useEffect(() => {
//     refetch().catch((error) => {
//       console.error("Error fetching data:", error);
//     });
//   }, [refetch]);
 
  //const customer = enquiry.user || {};
  // Format offerExpiryDate to "YYYY-MM-DD"
  const formattedOfferExpiryDate = enquiry?.offerExpiryDate
    ? new Date(enquiry.offerExpiryDate).toISOString().split("T")[0]
    : "";
    
  const initialValues = {
    inquiryId:enquiry?.inquiryId || "",
    totalPrice: enquiry?.totalPrice || "",
    drawingFile: enquiry?.drawingFile || "",
    proposalFile:enquiry?.proposalFile || "",
    additionalDocuments:enquiry?.additionalDocuments || "",
    offerExpiryDate: formattedOfferExpiryDate,
    description: enquiry?.description || "",
    adminStatus: enquiry?.adminStatus || "",
  };

  console.log("Initial Values are : ",initialValues)
  const validationSchema = yup.object().shape({
    
  });
  const onSubmitEnquiryEdit = async (values) => {
   
    console.log("Payload Values are:", values);
    try {
      const payload=new FormData();
      console.log("Empty FormData Payload before population:", payload); // Log empty payload

      // console.log("Payloadddddd",payload);
      for (const key in values)
      {
        console.log(`Processing key: ${key}, Value: ${values[key]}`); // Log each key and its value
        if(key=== "drawingFile")
        {
          if(values.drawingFile instanceof File)
          {
            payload.append(key,values.drawingFile)
          }
          else if(values.drawingFile)
          {
            payload.append(key,values.drawingFile)
          }
        }
        else if (key=== "proposalFile")
        {
          if(values.proposalFile instanceof File)
          {
            payload.append(key,values.proposalFile)
          }
          else if(values.proposalFile)
          {
            payload.append(key,values.proposalFile)
          }
        }
        else if(key==="additionalDocuments")
        {
          values.additionalDocuments.forEach((file,index)=>
          {
            if(file instanceof File)
            {
              payload.append(`additionalDocuments[${index}]`,file)
            }
            else if(file)
            {
              payload.append(`additionalDocuments[${index}]`,file)
            }
          })
        }
        else {
          // For other fields like totalPrice, offerExpiryDate, etc.
          payload.append(key, values[key]);
          console.log(`Added field ${key} to payload with value: ${values[key]}`);
        }
      }

// Log the final payload structure before making the API request
for (let [key, value] of payload.entries()) {
  console.log(`Payload entry - Key: ${key}, Value: ${value}`); 
  // FormData requires this method to display its content
  //console.log("Iddddddd",values.inquiryId)
}
          
const response= await updateInquiry({inquiryId,payload}).unwrap();
console.log("API Response:", response); // Log the API response
setDrawingFile(null);
setProposalFile(null);
setAdditionalDocuments([]);
    } catch (error) {
      notification.error("Failed to update enquiry.");
    } finally {
      setIsLoading(false);
      handleClose();
     refetch();
    }
  };

//   useEffect(() => {
//     // Trigger refetch when files are removed or updated
//     refetch();
//   }, [drawingFile, proposalFile, additionalDocuments, refetch]);

  const handleRemoveFile=async(fileIndex,fileField,setFieldValue)=>
  {
   
    const inquiryId=initialValues.inquiryId;
    console.log("Inquiry Id inside Removal:", inquiryId);
try{
const response=await deleteInquiryFile({inquiryId,fileField,fileIndex}).unwrap();

if(response?.success)
{
  notification?.success(response?.message);
}
else{
  notification?.error(response?.message)
}
if(fileField === "drawingFile")
{
  setDrawingFile(null);
  setFieldValue('drawingFile',null);
}
else if(fileField === "proposalFile")
  {
    setProposalFile(null);
    setFieldValue('proposalFile', null);

  }
else if(fileField === "additionalDocuments")
{
 // const updatedFiles=additionalDocuments.filter((_,index)=>index !== fileIndex)
//   setAdditionalDocuments(updatedFiles);
//   setFieldValue('additionalDocuments', updatedFiles);
//  const updatedFiles = [...additionalDocuments];  // Clone the array
//       updatedFiles[fileIndex] = null;  // Set the specific index to null
//       setAdditionalDocuments(updatedFiles);  // Update the state
//       setFieldValue('additionalDocuments', updatedFiles);  // Update formik field
setFieldValue(`additionalDocuments[${fileIndex}]`, null);
      setAdditionalDocuments(prev => {
        const updated = [...prev];
        updated[fileIndex] = null;
        return updated;
    });
}
//refetch();
// window.location.reload();
}catch(error){
alert(`File Deletion failed : ${error.data?.message || error.status || "Please try again later."}`)
}
  }
  const fileDisplay = (files) =>
    files.map((file, index) => {
      if (file && file.name) {
        return (
          <Typography key={index}>
            {uploadFileFormat(file.name)} - {Math.round(file.size / 1024)} KB
          </Typography>
        );
      }
      return null;
    }).filter(Boolean);
  return (
    <>
      <Tooltip title="Edit">
        <IconButton onClick={handleOpen}>
          <EditIcon style={{ color: "#294289", cursor: "pointer" }} />
        </IconButton>
      </Tooltip>
      <Modal 
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
      <Box 
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "80%",
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: "10px",
                  overflowY: "auto",
                  maxHeight: "80vh",
                }}
              >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitEnquiryEdit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form >
              
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        ...globalStyle,
                        ...globalModalTitleStyle,
                      }}
                    >
                      Edit Enquiry
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field name="totalPrice">
                      {({ field, meta }) => (
                        <TextField
                          label="Total Price"
                          type="number"
                          fullWidth
                          // value={values.totalPrice}
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && meta.error}
                          {...field}
                          InputLabelProps={{
                            shrink: true,
                            style: { ...globalModalTextfieldLabelStyle },
                          }}
                          InputProps={{
                            style: { ...globalStyle, ...globalModalTextfieldStyle },
                          }}
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field name="offerExpiryDate">
                      {({ field, meta }) => (
                        <TextField
                          label="Offer Expiry Date"
                          type="date"
                          fullWidth
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && meta.error}
                          {...field}
                          InputLabelProps={{
                            shrink: true,
                            style: { ...globalModalTextfieldLabelStyle },
                          }}
                          InputProps={{
                            style: { ...globalStyle, ...globalModalTextfieldStyle },
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
<Grid item xs={12} sm={6}>
<InputLabel>Enquiry Status</InputLabel>
<MySelect name="adminStatus" placeholder={"Enquiry Status"}>
{statusTypes.map(adminStatus=>
  <MenuItem key={adminStatus} value={adminStatus}>
    {adminStatus}
  </MenuItem>
)}
</MySelect>
</Grid>
                  <Grid item xs={12}>
                    <Field name="description">
                      {({ field, meta }) => (
                        <TextField
                          label="Description"
                          multiline
                          rows={4}
                          fullWidth
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && meta.error}
                          {...field}
                          placeholder="Enter description"
                          InputLabelProps={{
                            shrink: true,
                            style: { ...globalModalTextfieldLabelStyle },
                          }}
                          InputProps={{
                            style: { ...globalStyle, ...globalModalTextfieldStyle },
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
<Grid item xs={12} sm={6} sx={{p:2}}>
<Typography variant="subtitle1">Drawing File:</Typography>
{values.drawingFile?(
  <Box sx={{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    mb:2
  }}>
<Button href={enquiry.drawingFile}
target="_blank"
variant="contained"
color="primary"
>
  Drawing File
</Button>
<Button 
sx={{
  lineHeight:"2",
  minWidth:"15%",
  ml:2,
  padding:"6px 12px",
  backgroundColor:'#d32f2f',
  '&:hover':{
    backgroundColor:'#c62828'
  }
}}
variant="contained"
color="secondary"
onClick={()=>handleRemoveFile(null,"drawingFile",setFieldValue)}>
<DeleteIcon/>
</Button>
  </Box>
):(
  <Typography variant="body2" color="textSecondary">No drawing file available</Typography>
)}
<Typography variant="subtitle1">
Update the drawing file
</Typography>
<Button
variant="contained"
component="label"
>
  Choose File
  <input
  type="file"
  hidden
  name="drawingFile"
  onChange={(event)=>{
    const file=event.currentTarget.files[0];
    setDrawingFile(file.name);
    setFieldValue('drawingFile',file)
  }}
  />
{drawingFile && 
<Typography>
  {uploadFileFormat(drawingFile)}
  </Typography>}
</Button>
</Grid>
<Grid item xs={12} sm={6} sx={{p:2}}>
<Typography variant="subtitle1">Proposal File :</Typography>
{values.proposalFile?(
  <Box sx={{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    mb:2
  }}>
    <Button href={enquiry.proposalFile}
    target="_blank"
    variant="contained"
    color="primary"
    >
      Proposal File
    </Button>
    <Button sx={{
      lineHeight:"2",
      minWidth:"15%",
      ml:2,
      padding:"6px 12px",
      backgroundColor:"#d32f2f",
      '&:hover':{
        backgroundColor:"#c62828"
      },
     
    }}
    variant="contained"
    color="secondary"
    onClick={()=>handleRemoveFile(null,'proposalFile',setFieldValue)}
    >
      <DeleteIcon/>
    </Button>
  </Box>
):(
  <Typography variant="body2" color="textSecondary">No drawing file available</Typography>
)}
<Typography variant="subtitle1">
Update the proposal file
</Typography>
<Button variant="contained" component="label">
Choose File 
<input
type="file"
hidden
name="proposalFile"
onChange={(event)=>
{
  const file=event.currentTarget.files[0];
  setProposalFile(file.name);
  setFieldValue('proposalFile',file)
}}
/>
{proposalFile && 
  <Typography>
    {uploadFileFormat(proposalFile)}
    </Typography>}

</Button>
</Grid>
<Grid item xs={12} sm={6} sx={{ p: 2 }}>
<Typography variant="subtitle1"> Additional Documents:</Typography>
{Array.isArray(values.additionalDocuments) && values.additionalDocuments.length>0?(
  values.additionalDocuments.map((file,index)=>(
    <Grid item container
    key={index}
    direction="column"
    sx={{
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between',
      mb: 0.6,
      
    }}
    >
      {file?(
       <>
           <Grid  container justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
<Button href={file instanceof File ? URL.createObjectURL(file) : file} 
target="_blank"
variant="contained"
         color="primary"
         >
Additional Documents
</Button>
<Button
              sx={{
                lineHeight: "2",
                minWidth: "15%",
                mt: 1,
                // padding: "6px 12px",
                backgroundColor: '#d32f2f',
                '&:hover': {
                  backgroundColor: '#c62828',
                }
              }}
              variant="contained"
              color="secondary"
              onClick={() => handleRemoveFile(index, 'additionalDocuments',setFieldValue)}
            >
              <DeleteIcon />
            </Button>
           </Grid>

       </> 
      ):(
        <Typography variant="subtitle1">
        No additional documents file 
    </Typography>
      
      )}
      <Button
      variant="contained"
      component="label"
      
      >
<input
type="file"
hidden
name={`additionalDocuments-${index}`}
// onChange={(event)=>
// {
//   const files=Array.from(event.currentTarget.files);
//   const updatedFiles=[...values.additionalDocuments];
//   updatedFiles[index]=files[0] || null;
// setFieldValue('additionalDocuments',updatedFiles);
// setAdditionalDocuments(updatedFiles);
// }
// }
onChange={(event) => {
                const files = Array.from(event.currentTarget.files);
                setFieldValue(`additionalDocuments[${index}]`, files[0] || null);
                setAdditionalDocuments(prev => {
                  const updated = [...prev];
                  updated[index] = files[0] || null;
                  return updated;
                });
              }}
sx={{ ml: 2 }}
/>
Choose File
      </Button>
      <Box sx={{ mt: 1 }}>
              {file && fileDisplay([file])}
            </Box>
      </Grid>
  ))
) :(
  <Typography variant="body2" color="textSecondary">
      No additional documents files available.
    </Typography>
)
}
</Grid>


                  <Grid item xs={12} sx={{ 
    display: "flex", // Use flexbox
    justifyContent: "center", // Horizontally center the content
    alignItems: "center"  // Vertically center the content if needed
  }}>
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        variant="contained"
                        sx={{ ...globalStyle,alignItems:"center" }}
                        type="submit"

                        // fullWidth
                      >
                        Edit Enquiry
                      </Button>
                    )}
                  </Grid>
                </Grid>
              
            </Form>
          )}
        </Formik>
        </Box>
      </Modal>
    </>
  );
});
export default EnquiryEditAdmin;












// import React, { forwardRef } from "react";
// import {
//   Box,
//   IconButton,
//   Tooltip,
//   Typography,
//   Modal,
//   Grid,
//   Button,
//   TextField,
//   CircularProgress,
// } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import { Formik, Form, Field } from "formik";
// import * as yup from "yup";
// import { notification } from "../../../util/notification";
// import {
//   globalModalTextfieldLabelStyle,
//   globalModalTextfieldStyle,
//   globalModalTitleStyle,
// } from "../../../constant/globalStyle";
// import { globalStyle } from "../../../constant/globalStyle";

// const EnquiryEditAdmin = forwardRef(({props}) => {
//   const [open, setOpen] = React.useState(false);
//   const [isLoading, setIsLoading] = React.useState(false);

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const enquiry = props || {};
// //   console.log("Enquiry Vlaues",enquiry)
// //   console.log("Enquiry Total Price",enquiry?.totalPrice)
//   const customer = enquiry.user || {};
//   const formattedOfferExpiryDate = enquiry?.offerExpiryDate
//     ? new Date(enquiry.offerExpiryDate).toISOString().split("T")[0]
//     : "";

//   const initialValues = {
//     totalPrice: enquiry?.totalPrice || "",
//     drawingFile: enquiry?.drawingFile || "",
//     offerExpiryDate: formattedOfferExpiryDate,
//     description: enquiry?.description || "",
//     customerStatus: enquiry?.customerStatus || "",
//   };

//   const validationSchema = yup.object().shape({
//     totalPrice: yup.number().required("Total Price is required"),
//     offerExpiryDate: yup.date().required("Expiry date is required"),
//     description: yup.string(),
//   });

//   const onSubmitEnquiryEdit = async (values) => {
//     const payload = {
//       inquiryId: enquiry?.inquiryId,
//       totalPrice: values.totalPrice,
//       drawingFile: values.drawingFile,
//       offerExpiryDate: values.offerExpiryDate,
//       description: values.description,
//       customerStatus: values.customerStatus,
//     };

//     try {
//       setIsLoading(true);
//       // API call to update enquiry can be placed here
//       console.log("Submitted data:", payload);
//       notification.success("Enquiry updated successfully!");
//     } catch (error) {
//       notification.error("Failed to update enquiry.");
//     } finally {
//       setIsLoading(false);
//       handleClose();
//     }
//   };

//   return (
//     <>
//       <Tooltip title="Edit">
//         <IconButton onClick={handleOpen}>
//           <EditIcon style={{ color: "#294289", cursor: "pointer" }} />
//         </IconButton>
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-title"
//         aria-describedby="modal-description"
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: "80%",
//             bgcolor: "background.paper",
//             border: "2px solid #000",
//             boxShadow: 24,
//             p: 4,
//             borderRadius: "10px",
//             overflowY: "auto",
//             maxHeight: "80vh",
//           }}
//         >
//           <Formik
//             initialValues={initialValues}
//             validationSchema={validationSchema}
//             onSubmit={onSubmitEnquiryEdit}
//           >
//             {({ isSubmitting, setFieldValue, values }) => (
//               <Form>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12}>
//                     <Typography
//                       sx={{
//                         ...globalStyle,
//                         ...globalModalTitleStyle,
//                       }}
//                     >
//                       Edit Enquiry
//                     </Typography>
//                   </Grid>

//                   <Grid item xs={12} sm={6}>
//                     <Field name="totalPrice">
//                       {({ field, meta }) => (
//                         <TextField
//                           label="Total Price"
//                           type="number"
//                           fullWidth
//                           error={meta.touched && !!meta.error}
//                           helperText={meta.touched && meta.error}
//                           {...field}
//                           InputLabelProps={{
//                             shrink: true,
//                             style: { ...globalModalTextfieldLabelStyle },
//                           }}
//                           InputProps={{
//                             style: { ...globalStyle, ...globalModalTextfieldStyle },
//                           }}
//                         />
//                       )}
//                     </Field>
//                   </Grid>

//                   <Grid item xs={12} sm={6}>
//                     <Field name="offerExpiryDate">
//                       {({ field, meta }) => (
//                         <TextField
//                           label="Offer Expiry Date"
//                           type="date"
//                           fullWidth
//                           error={meta.touched && !!meta.error}
//                           helperText={meta.touched && meta.error}
//                           {...field}
//                           InputLabelProps={{
//                             shrink: true,
//                             style: { ...globalModalTextfieldLabelStyle },
//                           }}
//                           InputProps={{
//                             style: { ...globalStyle, ...globalModalTextfieldStyle },
//                           }}
//                         />
//                       )}
//                     </Field>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <Field name="description">
//                       {({ field, meta }) => (
//                         <TextField
//                           label="Description"
//                           multiline
//                           rows={4}
//                           fullWidth
//                           error={meta.touched && !!meta.error}
//                           helperText={meta.touched && meta.error}
//                           {...field}
//                           placeholder="Enter description"
//                           InputLabelProps={{
//                             shrink: true,
//                             style: { ...globalModalTextfieldLabelStyle },
//                           }}
//                           InputProps={{
//                             style: { ...globalStyle, ...globalModalTextfieldStyle },
//                           }}
//                         />
//                       )}
//                     </Field>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <Button
//                       variant="contained"
//                       sx={{ ...globalStyle }}
//                       type="submit"
//                       fullWidth
//                       disabled={isLoading}
//                     >
//                       {isLoading ? <CircularProgress size={24} /> : "Edit Enquiry"}
//                     </Button>
//                   </Grid>
//                 </Grid>
//               </Form>
//             )}
//           </Formik>
//         </Box>
//       </Modal>
//     </>
//   );
// });

// export default EnquiryEditAdmin;