import React, { createContext, useContext, useState } from "react";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

export const AlertDialogSlideContext = createContext({
  isOpen: false,
  open: async () => {},
});

export const useAlertDialogSlide = () => {
  return useContext(AlertDialogSlideContext);
};

export const AlertDialogSlideContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [resolveFn, setResolveFn] = useState(() => {});
  const [rejectFn, setRejectFn] = useState(() => {});

  const [props, setProps] = useState({
    title: "",
    message: "",
    transitionProps: { direction: "up" },
    dialogProps: { fullWidth: true, open: false },
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    onConfirm: async () => {},
  });

  const open = (props) => {
    setIsOpen(true);
    setProps(props);
    return new Promise((resolve, reject) => {
      setResolveFn(() => () => resolve());
      setRejectFn(() => () => reject());
    });
  };

  const handleClose = () => {
    setIsOpen(false);
    rejectFn();
  };

  const handleConfirm = async () => {
    setIsOpen(false);
    resolveFn();

    if (props.onConfirm) await props.onConfirm();
  };

  return (
    <AlertDialogSlideContext.Provider value={{ isOpen, open }}>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Slide}
        TransitionProps={{ ...props.transitionProps }}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>{props.message}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{props.cancelButtonText}</Button>
          <Button onClick={handleConfirm}>{props.confirmButtonText}</Button>
        </DialogActions>
      </Dialog>
      {children}
    </AlertDialogSlideContext.Provider>
  );
};
