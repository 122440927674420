import React from "react";
 import { Typography } from "@mui/material";
//  import useAuth from "../../hook/useAuth";
const Welcome=()=>{
    // const {displayName}=useAuth();
    return(
        <>
        <Typography sx={{my:"150px"}}>Welcome</Typography>
        </>
    )
}

export default Welcome;