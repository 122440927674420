// import React, { useEffect, useState } from "react";
// import {
//   Table,
//   TableBody,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
//   TableCell,
//   Tooltip,
//   TablePagination,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import useGetAllQuotes from "../../../hook/routing/useGetAllQuotes";
// import useAuth from "../../../hook/useAuth";
// import {
//   globalStyle,
// } from "../../../constant/globalStyle";

// const columns = [
//   { field: "quoteId", headerName: "Quote ID", width: 130 },
//   { field: "services", headerName: "Services", width: 130 },
//   { field: "totalSiteLoad", headerName: "Total Site Load", width: 130 },
//   { field: "status", headerName: "Admin Status", width: 130 },
//   { field: "action", headerName: "Actions", width: 130 },
// ];

// const AdminQuoteList = () => {
//   const { array, isLoading, refetch } = useGetAllQuotes();
//   const { isAdmin, isIntegrationEngineer } = useAuth();
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   const openAlertBox = async ({ title, message, confirmButtonText, cancelButtonText, onConfirm }) => {
//     if (window.confirm(message)) {
//       await onConfirm();
//     }
//   };

//   const deleteUser = async (user) => {
//     console.log("Deleting user:", user);
//   };
//   const handleDelete = async (object) => {
//     try {
//       await openAlertBox({
//         title: "Confirmation",
//         message: "Are you sure you want to permanently delete this record?",
//         confirmButtonText: "Delete",
//         cancelButtonText: "Cancel",
//         onConfirm: () => deleteUser(object),
//       });
//     } catch (error) {
//       console.error("Error during deletion:", error);
//     }
//   };

//   const handleEdit = (object) => {
//     console.log("Editing quote:", object);
//     // Add your logic to open the edit form or navigate to the edit page
//   };

//   useEffect(() => {
//     refetch();
//   }, [refetch]);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   return (
//     <>
// {/* <AdminAppbar/>
// <AdminSidebar/> */}

//       <Paper elevation={2} sx={{ borderRadius: "15px",my:2, mx: 4 }}>
//         <TableContainer>
//           <Table aria-label="simple table">
//             <TableHead>
//               <TableRow>
//                 {columns.map((column) => (
//                   <TableCell
//                     key={column.field}
//                     align="left"
//                     sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
//                   >
//                     {column.headerName}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>

//             <TableBody>
//               {isLoading ? (
//                 <TableRow>
//                   <TableCell colSpan={columns.length}>Loading...</TableCell>
//                 </TableRow>
//               ) : (
//                 array
//                   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                   .map((row) => (
//                     <TableRow key={row.quoteId}>
//                       <TableCell>{row.quoteId}</TableCell>
//                       <TableCell>{row.services}</TableCell>
//                       <TableCell>{row.totalSiteLoad}</TableCell>
//                       <TableCell>{row.status}</TableCell>
//                       <TableCell>
//                       <Tooltip title="Edit">
//                         <IconButton
//                           color="primary"
//                           onClick={() => handleEdit(row)}
//                         >
//                           <EditIcon />
//                         </IconButton>
//                       </Tooltip>
//                         <Tooltip title="Delete">
//                           <IconButton
//                             color="error"
//                             onClick={() => handleDelete(row)}
//                           >
//                             <DeleteIcon />
//                           </IconButton>
//                         </Tooltip>
//                       </TableCell>

//                     </TableRow>
//                   ))
//               )}
//             </TableBody>
//           </Table>
//           <TablePagination
//             rowsPerPageOptions={[5, 10, 25]}
//             component="div"
//             count={array.length}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </TableContainer>
//       </Paper>
//     </>
//   );
// };

// export default AdminQuoteList;

// import React, { useEffect,useOptimistic,useCallback } from "react";
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import {
//   dataGridCurrentPage,
//   dataGridRowsPerPage,
// } from "../../../constant/dataGrid";
// import { Paper, IconButton, Tooltip, Select, MenuItem } from '@mui/material';
// import DataGridHeader from "../../../component/data-grid/DataGridHeader";
// import CustomNoRowsOverlay from "../../../component/data-grid/CustomNoRowsOverlay";
// // import AnnouncementEdit from "./AnnouncementEdit";
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
// import { useAlertDialogSlide } from "../../../component/alert-dialog/AlertDialogProvider";
// // import { useDeleteAnnouncementMutation } from "../../redux/reducer/api/announcementApiSlice";
// import {useDeleteQuoteMutation ,useUpdateQuoteStatusMutation}  from "../../../redux/reducer/api/quoteApiSlice";
// import { deleteMutation } from "../../../util/helper-function";
// import useGetAllQuotes from "../../../hook/routing/useGetAllQuotes";
// import { useSelector } from "react-redux";
// // import { selectSmartNumberSelected } from "../../redux/reducer/shared/smartNumberSlice"

// const AdminQuoteList = () => {
//   const [pageSize, setPageSize] = React.useState(dataGridCurrentPage);
//   // const selected = useSelector(selectSmartNumberSelected);
//   // const { array, isLoading, refetch } = useAnnouncementByDidId(selected?.id);
//   const { array, refetch , isLoading} = useGetAllQuotes();
//   const { open: openAlertBox } = useAlertDialogSlide();
//   // const [deleteAnnouncement] = useDeleteAnnouncementMutation();
//   const [deleteQuote] = useDeleteQuoteMutation();
//   const [updateQuoteStatus] = useUpdateQuoteStatusMutation();
//    // Optimistic state
//    const [optimisticQuotes, setOptimisticQuotes] = useOptimistic(array);
//   // console.log(open)
//   async function handleDelete(row) {
//     const { quoteId } = row;
//     console.log("Quote Id",quoteId)
//     // console.log("Quote",row)
//     try {
//       await openAlertBox({
//         title: "Confirmation",
//         message: `Are you sure you want to permanently delete ${quoteId}`,
//         confirmButtonText: "Delete",
//         cancelButtonText: "Cancel",
//         onConfirm: () => deleteMutation({quoteId},deleteQuote, refetch),
//       });
//     } catch (err) {
//       console.error("Dialog was closed without confirmation");
//     }
//   }

//   const handleStatusChange = useCallback(async (quoteId, newStatus) => {
//     // Capture the previous state to revert in case of error
//     const previousQuotes = [...optimisticQuotes];

//     // Optimistically update the UI
//     setOptimisticQuotes((prevQuotes) =>
//       prevQuotes.map((quote) =>
//         quote.quoteId === quoteId ? { ...quote, status: newStatus } : quote
//       )
//     );

//     try {
//       // Perform the actual mutation
//       await updateQuoteStatus({ quoteId, status: newStatus }).unwrap();

//       // Optionally refetch to get the latest data if needed
//       refetch();
//     } catch (error) {
//       console.error("Failed to update status", error);

//       // Revert the optimistic update in case of failure
//       setOptimisticQuotes(previousQuotes);

//       // Optional: Display an error notification or message to the user
//       // notification.error("Failed to update quote status. Please try again.");
//     }
//   }, [optimisticQuotes, updateQuoteStatus, refetch]);

//   useEffect(() => {
//     console.log("Refetching data on mount.");
//     refetch();
//   }, [refetch]);

//   return (
//     <>
//       <Paper elevation={2} sx={{ borderRadius: "15px", m: 4 }}>
//         <DataGrid
//           autoHeight
//           pagination
//           pageSize={pageSize}
//           onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//           rowsPerPageOptions={dataGridRowsPerPage}
//           rows={array}
//           loading={isLoading}
//           getRowId={(row) => row.quoteId}
//           slots={{
//           toolbar: GridToolbar,
//           NoRowsOverlay: CustomNoRowsOverlay,
//         }}
//           slotProps={{
//             toolbar: {
//               showQuickFilter: true,
//               quickFilterProps: { debounceMs: 500 },
//             },
//           }}
//           //getRowId={(row) => row.quoteId} // Use quoteId as the unique id
//           columns={[
//             {
//               field: "quoteId",
//               flex: 1,
//               hide: true,
//               renderHeader: () => <DataGridHeader header={"quoteId"} />,
//               headerAlign: "center",
//               align: "center",
//             },
//             {
//               field: "services",
//               flex: 1,
//               renderHeader: () => <DataGridHeader header={"Services"} />,
//               headerAlign: "center",
//               align: "center",
//             },

//             // {
//             //   field: "status",
//             //   flex: 1,
//             //   renderHeader: () => <DataGridHeader header={"Admin Status"} />,
//             //   headerAlign: "center",
//             //   align: "center",
//             // },

//             {
//             field: "status",
//             flex: 1,
//             renderHeader: () => <DataGridHeader header={"Admin Status"} />,
//             headerAlign: "center",
//             align: "center",
//             renderCell: (params) => (
//               <Select
//                 value={params.row.status}
//                 onChange={(e) => handleStatusChange(params.row.quoteId, e.target.value)}
//               >
//                 <MenuItem value="Pending">Pending</MenuItem>
//                 <MenuItem value="Approved">Approved</MenuItem>
//                 <MenuItem value="Declined">Declined</MenuItem>
//               </Select>
//             ),
//           },

//             // {
//             //   field: "status",
//             //   flex: 1,
//             //   renderHeader: () => <DataGridHeader header={"Recordings"} />,
//             //   headerAlign: "center",
//             //   align: "center",
//             //   renderCell: (params) => (
//             //     <div sx={{ pb: 10 }}>
//             //       <audio key={params.row.fileName} controls>
//             //         <source
//             //           src={`http://172.20.150.241:4059/public/announcements/${params.row.fileName}`}
//             //           type="audio/wav"
//             //         />
//             //         Your browser does not support the audio element.
//             //       </audio>
//             //     </div>
//             //   ),
//             // },
//             {
//               field: "actions",
//               flex: 1,
//               renderHeader: () => <DataGridHeader header={"Actions"} />,
//               headerAlign: "center",
//               align: "center",
//               renderCell: (params) => (
//                 <>
//                   <Tooltip title="Delete" sx={{ p: 1 }}>
//                     <IconButton
//                       color="error"
//                       cursor="pointer"
//                       onClick={() => handleDelete(params.row)}
//                     >
//                       <DeleteOutlineOutlinedIcon />
//                     </IconButton>
//                   </Tooltip>

//                   {/* <AnnouncementEdit record={params.row} /> */}
//                 </>
//               ),
//             },
//           ]}
//         />
//       </Paper>
//     </>
//   );
// };

// export default AdminQuoteList;

// import React, { useEffect } from "react";
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { Paper, IconButton, Tooltip, Select, MenuItem } from '@mui/material';
// import DataGridHeader from "../../../component/data-grid/DataGridHeader";
// import CustomNoRowsOverlay from "../../../component/data-grid/CustomNoRowsOverlay";
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
// import { useAlertDialogSlide } from "../../../component/alert-dialog/AlertDialogProvider";
// import { useDeleteQuoteMutation, useUpdateQuoteStatusMutation } from "../../../redux/reducer/api/quoteApiSlice";
// import useGetAllQuotes from "../../../hook/routing/useGetAllQuotes";
// import { deleteMutation } from "../../../util/helper-function";

// const AdminQuoteList = () => {
//   const [pageSize, setPageSize] = React.useState(10);
//   const { array, refetch, isLoading } = useGetAllQuotes();
//   const { open: openAlertBox } = useAlertDialogSlide();
//   const [deleteQuote] = useDeleteQuoteMutation();
//   const [updateQuoteStatus] = useUpdateQuoteStatusMutation();

//   useEffect(() => {
//     refetch().then(({ data }) => {
//       console.log("Fetched data:", data);
//     }).catch((error) => {
//       console.error("Error fetching data:", error);
//     });
//   }, [refetch]);

//   async function handleDelete(row) {
//     const { quoteId } = row;
//     console.log("Quote Id", quoteId);

//     try {
//       await openAlertBox({
//         title: "Confirmation",
//         message: `Are you sure you want to permanently delete ${quoteId}`,
//         confirmButtonText: "Delete",
//         cancelButtonText: "Cancel",
//         onConfirm: () => deleteMutation({ quoteId }, deleteQuote, refetch),
//       });
//     } catch (err) {
//       console.error("Dialog was closed without confirmation");
//     }
//   }

//   const handleStatusChange = async (quoteId, newStatus) => {
//     try {
//       await updateQuoteStatus({ quoteId, status: newStatus }).unwrap();
//       refetch();
//     } catch (error) {
//       console.error("Failed to update status", error);
//     }
//   };

//   console.log("Array:", array);

//   return (
//     <Paper elevation={2} sx={{ borderRadius: "15px", m: 4 }}>
//       <DataGrid
//         autoHeight
//         pagination
//         pageSize={pageSize}
//         onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
//         rowsPerPageOptions={[5, 10, 20]}
//         rows={array}
//         loading={isLoading}
//         getRowId={(row) => row.quoteId}
//         slots={{
//           toolbar: GridToolbar,
//           NoRowsOverlay: CustomNoRowsOverlay,
//         }}
//         slotProps={{
//           toolbar: {
//             showQuickFilter: true,
//             quickFilterProps: { debounceMs: 500 },
//           },
//         }}
//         columns={[
//           {
//             field: "quoteId",
//             flex: 1,
//             hide: true,
//             renderHeader: () => <DataGridHeader header={"quoteId"} />,
//             headerAlign: "center",
//             align: "center",
//           },
//           {
//             field: "services",
//             flex: 1,
//             renderHeader: () => <DataGridHeader header={"Services"} />,
//             headerAlign: "center",
//             align: "center",
//           },
//           {
//             field: "status",
//             flex: 1,
//             renderHeader: () => <DataGridHeader header={"Admin Status"} />,
//             headerAlign: "center",
//             align: "center",
//             renderCell: (params) => (
//               <Select
//                 value={params.row.status}
//                 onChange={(e) => handleStatusChange(params.row.quoteId, e.target.value)}
//               >
//                 <MenuItem value="Pending">Pending</MenuItem>
//                 <MenuItem value="Approved">Approved</MenuItem>
//                 <MenuItem value="Declined">Declined</MenuItem>
//               </Select>
//             ),
//           },
//           {
//             field: "actions",
//             flex: 1,
//             renderHeader: () => <DataGridHeader header={"Actions"} />,
//             headerAlign: "center",
//             align: "center",
//             renderCell: (params) => (
//               <>
//                 <Tooltip title="Delete" sx={{ p: 1 }}>
//                   <IconButton
//                     color="error"
//                     cursor="pointer"
//                     onClick={() => handleDelete(params.row)}
//                   >
//                     <DeleteOutlineOutlinedIcon />
//                   </IconButton>
//                 </Tooltip>
//               </>
//             ),
//           },
//         ]}
//       />
//     </Paper>
//   );
// };

// export default AdminQuoteList;

import React, { useEffect } from "react";
import { DataGrid, GridToolbar, useGridApiContext } from "@mui/x-data-grid";
import {
  Paper,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
// import AddIcon from "@mui/icons-material/Add";
import DataGridHeader from "../../../component/data-grid/DataGridHeader";
import CustomNoRowsOverlay from "../../../component/data-grid/CustomNoRowsOverlay";
import { useAlertDialogSlide } from "../../../component/alert-dialog/AlertDialogProvider";
import {
  useDeleteQuoteMutation,
  useUpdateQuoteStatusMutation,
} from "../../../redux/reducer/api/quoteApiSlice";
import useGetAllQuotes from "../../../hook/routing/useGetAllQuotes";
import { deleteMutation } from "../../../util/helper-function";
import QuoteViewAdmin from "./QuoteViewAdmin";
import { useNavigate } from "react-router-dom";

const AdminQuoteList = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = React.useState(10);
  const { array, refetch, isLoading } = useGetAllQuotes();
  const { open: openAlertBox } = useAlertDialogSlide();
  const [deleteQuote] = useDeleteQuoteMutation();
  const [updateQuoteStatus] = useUpdateQuoteStatusMutation();

  useEffect(() => {
    refetch().catch((error) => {
      console.error("Error fetching data:", error);
    });
  }, [refetch]);

  const handleDelete = async (row) => {
    const { quoteId } = row;
    try {
      await openAlertBox({
        title: "Confirmation",
        message: `Are you sure you want to permanently delete ${quoteId}?`,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        onConfirm: () => deleteMutation({ quoteId }, deleteQuote, refetch),
      });
    } catch (err) {
      console.error("Dialog was closed without confirmation");
    }
  };

  const handleStatusChange = async (id, field, value) => {
    try {
      await updateQuoteStatus({ quoteId: id, status: value }).unwrap();
      refetch();
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };

  // const handleQuote = () => {
  //   navigate("/customer/create-quote");
  // };
  const onClickEdit = (quoteId) => {
    console.log(quoteId);
    navigate(`/edit-quote/${quoteId}`);
  };
  const handleEditQuotes = (object) => {
    console.log("Editing quote:", object);
    // Add your logic to open the edit form or navigate to the edit page
  };

  const SelectEditInputCell = (props) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      const newValue = event.target.value;
      await apiRef.current.setEditCellValue({ id, field, value: newValue });
      apiRef.current.stopCellEditMode({ id, field });
      handleStatusChange(id, field, newValue);
    };

    return (
      <Select
        value={value}
        onChange={handleChange}
        size="small"
        sx={{ height: 1 }}
        native
        autoFocus
      >
        <option value="Pending">Pending</option>
        <option value="Approved">Approved</option>
        <option value="Declined">Declined</option>
      </Select>
    );
  };

  const renderSelectEditInputCell = (params) => {
    return <SelectEditInputCell {...params} />;
  };

  return (
    <Paper elevation={2} sx={{ borderRadius: "15px" }}>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: "#1976d2",
            '&:hover': {
              backgroundColor: "#1565c0",
            }
          }}
          onClick={handleQuote} 
        >
          Add Quote
        </Button>
      </Box> */}
      <DataGrid
        autoHeight
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        rows={array}
        loading={isLoading}
        getRowId={(row) => row.quoteId}
        slots={{
          toolbar: GridToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        columns={[
          {
            field: "quoteId",
            flex: 1,
            hide: true,
            renderHeader: () => <DataGridHeader header={"Quote Id"} />,
            headerAlign: "center",
            align: "center",
          },
          {
            field: "services",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Services"} />,
            headerAlign: "center",
            align: "center",
          },

          {
            field: "expectedDate",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Expected Date"} />,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              // Check if the expectedDate exists and format it
              const expectedDate = params.row?.expectedDate
                ? new Date(params.row.expectedDate).toISOString().split("T")[0]
                : ""; // Fallback if expectedDate is missing

              return expectedDate;
            },
          },
          {
            field: "quoteDeadline",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Quote Deadline"} />,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              // Check if the quoteDeadline exists and format it
              const quoteDeadline = params.row?.quoteDeadline
                ? new Date(params.row.quoteDeadline).toISOString().split("T")[0]
                : ""; // Fallback if quoteDeadline is missing

              return quoteDeadline;
            },
          },
          {
            field: "totalSiteLoad",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Total Site Load"} />,
            headerAlign: "center",
            align: "center",
          },
          {
            field: "status",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Admin Status"} />,
            headerAlign: "center",
            align: "center",
            editable: true,
            renderEditCell: renderSelectEditInputCell,
          },
          // {
          //   field: "actions",
          //   sortable: false,
          //   filterable: false,
          //   hideable: false,
          //   // hideFil
          //   flex: 1,
          //   renderHeader: () => <DataGridHeader header={"Actions"} />,
          //   headerAlign: "center",
          //   align: "center",
          //   renderCell: (params) => (
          //     <Tooltip title="Delete" sx={{ p: 1 }}>
          //       <IconButton
          //         color="error"
          //         cursor="pointer"
          //         onClick={() => handleDelete(params.row)}
          //       >
          //         <DeleteOutlineOutlinedIcon />
          //       </IconButton>
          //     </Tooltip>
          //   ),
          // },
          {
            field: "actions",
            sortable: false,
            filterable: false,
            // hideable: false,
            // hideFil
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Actions"} />,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              return (
                <>
                  <Tooltip title="Delete" sx={{ p: 1 }}>
                    <IconButton
                      color="error"
                      cursor="pointer"
                      onClick={() => handleDelete(params.row)}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>

                  {
                    <Tooltip title="Edit">
                      <IconButton
                        color="primary"
                        //onClick={() => handleEditQuotes(params.row)}
                        onClick={() => onClickEdit(params.row.quoteId)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  {<QuoteViewAdmin props={params.row} />}
                </>
              );
            },
          },
        ]}
      />
    </Paper>
  );
};

export default AdminQuoteList;
