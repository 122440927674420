// import { useState } from 'react';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import {
//   Box,
//   Stepper,
//   Step,
//   StepLabel,
//   Grid,
//   FormHelperText,
//   Button
// } from '@mui/material';
// import PersonalInfo from './PersonalInfo';
// import AccountDetails from './AccountDetails';
// import ReviewInfo from './ReviewInfo';

// const steps = [' Account Details', 'Personal Info', 'Review and Submit'];

// const Form = () => {
//   const [activeStep, setActiveStep] = useState(0);

//   const handleBack = () => {
//     setActiveStep((prevStep) => prevStep - 1);
//   };

//   const formik = useFormik({
//     initialValues: {
//       email: '',
//       password: '',
//       confirmPassword: '',
//       firstName: '',
//       lastName: '',
//       phone: '',
//       residence: ''
//     },
//     validationSchema: Yup.object().shape({
//       email: Yup.string()
//         .required('Email is required')
//         .email('Invalid email'),
//       password: Yup.string()
//         .min(8),
//       confirmPassword: Yup.string()
//         .min(8)
//         .oneOf([Yup.ref('password')], 'Passwords do not match'),
//       firstName: Yup.string()
//         .required('First Name is required'),
//       lastName: Yup.string()
//         .required('Last Name is required'),
//     }),
//     onSubmit: (values) => {
//       if (activeStep === steps.length - 1) {
//         console.log('Form values:', values);
//         console.log('last step');
//       } else {
//         setActiveStep((prevStep) => prevStep + 1);
//       }
//     }
//   });

//   const formContent = (step) => {
//     switch(step) {
//       case 0:
//         return <AccountDetails formik={formik} />;
//       case 1:
//         return <PersonalInfo formik={formik} />;
//       case 2:
//         return <ReviewInfo formik={formik} />;
//       default:
//         return <div>404: Not Found</div>
//     }
//   };

//   return (
//     <Box
//       sx={{
//         maxWidth: '600px',
//         padding: 2
//       }}
//     >
//       <Stepper
//         activeStep={activeStep}
//         orientation="horizontal"
//       >
//         {steps.map((label, index) => (
//           <Step key={index}>
//             <StepLabel>{label}</StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//       <Grid container>
//         <Grid
//           item
//           xs={12}
//           sx={{ padding: '20px' }}
//         >
//           {formContent(activeStep)}
//         </Grid>
//         {formik.errors.submit && (
//           <Grid
//             item
//             xs={12}
//           >
//             <FormHelperText error>
//               {formik.errors.submit}
//             </FormHelperText>
//           </Grid>
//         )}
//         <Grid
//           item
//           xs={12}
//         >
//           <Button
//             disabled={activeStep === 0}
//             onClick={handleBack}
//           >
//             Back
//           </Button>
//           {activeStep === steps.length - 1 ? (
//             <Button>
//               Submit
//             </Button>
//           ) : (
//             <Button onClick={formik.handleSubmit}>
//               Next
//             </Button>
//           ) }
//         </Grid>
//       </Grid>
//     </Box>
//   )
// }

// export default Form;





import React, { useState } from "react";
import { Formik, Field, Form,useField,FieldArray } from "formik";
import * as yup from "yup";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Radio,
  InputLabel,
  Select, MenuItem,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  
  Snackbar,
  
} from "@mui/material";

import {
  globalAddPaper,
  globalStyle,
  globalModalBtnStyle,
  globalModalTitleStyle,
  globalModalTextfieldLabelStyle,
  globalModalTextfieldStyle,
} from "../../../constant/globalStyle";

import { useId } from "react";
import { uploadFileFormat } from "../../../constant/uploadFileFormat";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useCreateQuoteMutation } from '../../../redux/reducer/api/quoteApiSlice';
const validationSchema = yup.object().shape({
  username: yup.string().required("Username is required"),
  email: yup.string().email("Invalid email format").required("Email is required"),
  contactNo: yup.string().required("Contact Number is required"),
  companyName: yup.string().required("Company Name is required"),
  
});

const plotTypes = [
  "1 Bedroom Property", "1 Bedroom Bungalow", "1 Bedroom Detached","1 Bedroom Terrace", "1 Bedroom Semi-Detached",
 "Pumpstation",
 "Fiber OSCP",
 "Other"
];
const MySelect = ({ label, placeholder, ...props }) => {
  const [field, meta, helpers] = useField(props);
  return (
    <FormControl fullWidth error={meta.touched && !!meta.error}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        {...props}
        displayEmpty
        renderValue={(selected) => {
          if (selected === '') {
            return <Typography color="textSecondary">{placeholder}</Typography>;
          }
          return selected;
        }}
      >
        <MenuItem value="" disabled>{placeholder}</MenuItem>
        {props.children}
      </Select>
      {meta.touched && meta.error ? (
        <Typography color="error" variant="body2">{meta.error}</Typography>
      ) : null}
    </FormControl>
  );
};
const QuoteAdd = () => {
  const [formStage, setFormStage] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [createQuote, { isLoading }] = useCreateQuoteMutation();

  const idh = useId();
  const [services,setServicesType]=useState("");
  const [heatingType,setHeatingType]=useState("");
  const [developmentType, setDevelopmentType] = useState("");
  const [typeOfLoad, setTypeOfLoad] = useState("");
  const [typeOfLandlord, setTypeOfLandlord] = useState("");
  

  const [worksOffer, setFileUpload] = useState("");

  // const [sitePlaneFile, setSitePlaneFile] = useState("");
  // const [sitePlaneFiles, setSitePlaneFiles] = useState("");
const [offerLetterFile,setOfferLetterFile]=useState('');
 
  // const [openSnackbar, setOpenSnackbar] = useState(false);

  const [sitePlanFiles, setSitePlanFiles] = useState([]);
 

  
  const totalStages = 4; 
  
  const initialValues = {
    username: "", email: "", contactNo: "", companyName: "",
    siteAddress: "", quoteDeadline: "", expectedDate: "", totalSiteLoad: "",
    postalCode: "", services: "", heatingType: "", specialNote: "",
    plots: [{ plotType: "", plotName: "", units: "", loadPerPlot: "" }],
    offerLetterFile: null, sitePlanFiles: []
  };


const handleFileUpload = (event, setFieldValue) => {
  const file = event.target.files[0];
  if (file) {
      setFieldValue('offerLetterFile', file);  // Setting the file object directly
      setTimeout(() => {
          setOpenSnackbar(true); // Show success message
      }, 1500);
  } else {
      setFieldValue('offerLetterFile', null);  // Ensure null is set if no file is selected
  }
};
  const handleFileChange = (event, setFieldValue, fieldName) => {
    const files = event.target.files;
    if (files && files.length > 0) {
        const fileList = Array.from(files);  // Convert FileList to Array
        setFieldValue(fieldName, fileList);  // Update Formik's state with all files
    } else {
        console.error("No files found in the event object");
        setFieldValue(fieldName, []);  // Ensure existing files are cleared if no files selected
    }
};
  const fileDisplay = files => files.map((file, index) => (
    <Typography key={index}>{uploadFileFormat(file.name)} - {Math.round(file.size / 1024)} KB</Typography>
  ));

const onSubmitHandle = async (values, { setSubmitting }) => {
  const formData = new FormData();
  Object.keys(values).forEach(key => {
      if (key === 'plots') {
          formData.append(key, JSON.stringify(values[key]));  // Handle JSON serialization of array or object
      } else if (values[key] instanceof File) {
          formData.append(key, values[key]);  // Handle single file
      } else if (Array.isArray(values[key]) && values[key].some(item => item instanceof File)) {
          // Append each file under the same name for arrays of files
          values[key].forEach(file => {
              if (file instanceof File) {
                  formData.append(key, file);
              }
          });
      } else {
          formData.append(key, values[key]);  // Handle other form fields
      }
  });
  try {
    console.log('Quote created successfully:', formData);
      //const response = await createQuote(formData).unwrap();
      //console.log('Quote created successfully:', response);
      setOpenSnackbar(true);
  } catch (error) {
      console.error('Error creating quote:', error);
      setOpenSnackbar(true);
  } finally {
      setSubmitting(false);
  }
};
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const commonAreaStyle = {
    height: "49px",
    width: "100%",
    marginLeft: "2px",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandle}
      
    >
      {({ setFieldValue, values, isSubmitting,submitForm }) => (
        <Form method="POST" enctype="multipart/form-data">
          <Paper elevation={2} sx={{ ...globalAddPaper, marginTop: 5, marginBottom:10, }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography sx={{ ...globalModalTitleStyle, p: 2 }}>
                  Create a New Quote
                </Typography>
              </Grid>
              {formStage === 0 && (
                <>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }} >
                <Field name="username">
                  {({ field, meta }) => (
                    <TextField
                      required
                      id={`${idh}-username`}
                      label="Name"
                      placeholder="Name"
                      fullWidth
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue("username", event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      type="text"
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="email">
                  {({ field, meta }) => (
                    <TextField
                      required
                      id={`${idh}-email`}
                      label="Email"
                      placeholder="Email"
                      fullWidth
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue("email", event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      type="text"
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="contactNo">
                  {({ field, meta }) => (
                    <TextField
                      required
                      id={`${idh}-contactNo`}
                      label="Contact No"
                      placeholder="Contact No"
                      fullWidth
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue("contactNo", event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="companyName">
                  {({ field, meta }) => (
                    <TextField
                      required
                      id={`${idh}-companyName`}
                      label=" Company Name  &nbsp;"
                      placeholder="Company Name"
                      fullWidth
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue("Company Name", event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
 {/* New Address Field */}
 <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="siteAddress">
                  {({ field, meta }) => (
                    <TextField
                      
                      id={`${idh}-siteAddress`}
                      label=" Site Address "
                      placeholder="Site Address"
                      fullWidth
                      {...field}
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="quoteDeadline">
                  {({ field, form, meta }) => (
                    <TextField
                      id={`${idh}-quoteDeadline`}
                      label=" Quote Deadline &nbsp; &nbsp; &nbsp; &nbsp;"
                      placeholder="Quote Deadline"
                      type="date"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => {
                        setFieldValue("quoteDeadline", event.target.value);
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="expectedDate">
                  {({ field, form, meta }) => (
                    <TextField
                      id={`${idh}-expectedDate`}
                      label="Project Start Date &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                      placeholder="Project Start Date"
                      type="date"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => {
                        setFieldValue("expectedDate", event.target.value);
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="totalSiteLoad">
                  {({ field, meta }) => (
                    <TextField
                      
                      id={`${idh}-totalSiteLoad`}
                      label="Total Site Load (kVA) &nbsp; "
                      placeholder="Total Site Load (kVA)"
                      fullWidth
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue("totalSiteLoad", event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      type="number"
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="postalCode">
                  {({ field, meta }) => (
                    <TextField                     
                      id={`${idh}-postalCode`}
                      label="Post Code"
                      placeholder="Post Code"
                      fullWidth
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue("postalCode", event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
<Grid
  item
  xs={12}
  sm={6}
  sx={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    p: 2,
  }}
>
  <Grid
    item
    xs={12}
    sm={6}
    marginLeft="12px"
    sx={{ height: "90px" }}
  >
    <FormControl component="fieldset" fullWidth>
      <FormLabel component="legend">
        Do you have the valid Non-Contestable works offer from DNO?
      </FormLabel>
      <RadioGroup
        row
        aria-label="worksOffer"
        name="worksOffer"
        value={values.worksOffer}
        onChange={(event) => {
          setFieldValue("worksOffer", event.target.value);
          setFileUpload(event.target.value);
        }}
      >
        <FormControlLabel
          value="yes"
          control={<Radio />}
          label="Yes"
        />
        <FormControlLabel
          value="no"
          control={<Radio />}
          label="No"
        />
      </RadioGroup>
    </FormControl>
  </Grid>
  {worksOffer === "yes" && ( 
    <Grid item xs={12} sm={6} sx={{ p: 2 }}>
  <Typography variant="subtitle1">
    Please upload the offer letter and drawing.
  </Typography>
  <Button variant="contained" component="label" sx={{ width: '100%' }}>
    Choose File
    <input
    type="file"
    hidden
    onChange={(event) => handleFileUpload(event, setFieldValue)}
/>
  </Button>
  {offerLetterFile && (
    <Typography sx={{ mt: 2 }}>
      {uploadFileFormat(offerLetterFile)}
    </Typography>
  )}
</Grid>
  )}
  {worksOffer === "no" && (
    <Grid
      item
      xs={12}
      sm={6}
      sx={{
        height: "90px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">
          Do you want us to apply on your behalf?
        </FormLabel>
        <RadioGroup
          row
          aria-label="applyOnBehalf"
          name="applyOnBehalf"
          value={values.applyOnBehalf}
          onChange={(event) => {
            setFieldValue("applyOnBehalf", event.target.value);
          }}
        >
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            value="no"
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  )}
</Grid>
<Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="fullAddress">
                  {({ field, meta }) => (
                    <TextField                
                      id={`${idh}-fullAddress`}
                      label="Full address&nbsp;"
                      placeholder="Full address"
                      fullWidth
                      {...field}
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="specialNote">
                  {({ field }) => (
                    <TextField
                      id={`${idh}-specialNote`}
                      label="Any Special Note &nbsp; &nbsp;"
                      placeholder="Any Special Note"
                      fullWidth
                      {...field}
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                    />
                  )}
                </Field>
              </Grid>
              </>
              )}
{/*  ...................................    Next First  ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, */}

{formStage === 1 && (
                <>
<Grid item xs={12} marginLeft={"12px"}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend">
                        Which services do you need?
                      </FormLabel>
                      <RadioGroup
                        row
                        name="services"
                        value={values.services}
                        onChange={(event) => {
                          setFieldValue("services", event.target.value);
                          setServicesType(event.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="1.Electricity_Connections"
                          control={<Radio />}
                          label="Electricity connections"
                        />
                        <FormControlLabel
                          value="2.Gas_Connections"
                          control={<Radio />}
                          label="Gas connections"
                        />
                        <FormControlLabel
                          value="3.Water_Services"
                          control={<Radio />}
                          label="Water services"
                        />
                        <FormControlLabel
                          value="4.Fiber_Services"
                          control={<Radio />}
                          label="Fiber services"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} marginLeft={"12px"}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend">
                        Types of Heating.
                      </FormLabel>
                      <RadioGroup
                        row
                        name="heatingType"
                        value={values.heatingType}
                        onChange={(event) => {
                          setFieldValue("heatingType", event.target.value);
                          setHeatingType(event.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="1.Electric"
                          control={<Radio />}
                          label="Electric"
                        />
                        <FormControlLabel
                          value="2.Gas"
                          control={<Radio />}
                          label="Gas"
                        />
                        <FormControlLabel
                          value="3.ASHP"
                          control={<Radio />}
                          label="ASHP"
                        />
                        <FormControlLabel
                          value="4.District_Heating"
                          control={<Radio />}
                          label="District Heating"
                        />
                         <FormControlLabel
                          value="5.Other"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {heatingType === "5.Other" && (
                        <>                      
                          <Grid item xs={12} sm={6} sx={{ p: 2 }}>
                            <Field name="customHeatingType">
                              {({ field, meta }) => (
                                <TextField
                                  required
                                  id={`${idh}-customHeatingType`}
                                  label="Please enter type of heating."
                                  fullWidth
                                  value={field.value}
                                  onChange={(event) =>
                                    setFieldValue(
                                      "customHeatingType",
                                      event.target.value
                                    )
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                    style: {
                                      ...globalModalTextfieldLabelStyle,
                                    },
                                  }}
                                  InputProps={{
                                    style: {
                                      ...globalStyle,
                                      ...globalModalTextfieldStyle,
                                      ...commonAreaStyle,
                                      borderRadius: "5px",
                                    },
                                  }}
                                  {...field}
                                  error={meta.touched && !!meta.error}
                                  helperText={meta.touched ? meta.error : ""}
                                />
                              )}
                            </Field>
                          </Grid>   
                        </>
                      )}
</>
)}
{/* /*  ...................................    Next 2nd  ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, */} 

{formStage === 2 && (
                <>
                <FieldArray name="plots">
  {({ push, remove }) => (
    <>
      {values.plots.length > 0 && values.plots.map((plot, index) => (
        <React.Fragment key={index}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <MySelect
                // label="Plot Type"
                placeholder="Select a plot type..."
                name={`plots[${index}].plotType`}
                onChange={e => {
                  setFieldValue(`plots[${index}].plotType`, e.target.value);
                  // Reset plotName if not 'Other'
                  if (e.target.value !== "Other") {
                    setFieldValue(`plots[${index}].plotName`, '');
                  }
                }}
              >
                {plotTypes.map((type, idx) => (
                  <MenuItem key={idx} value={type}>{type}</MenuItem>
                ))}
              </MySelect>
            </Grid>

            {plot.plotType === "Other" && (
              <>
<Grid item xs={12} sm={6}> </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  name={`plots[${index}].plotName`}
                  label="Plot Name"
                  fullWidth
                />
              </Grid>
              </>
            )}
<Grid item xs={12} sm={6}>
              
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                name={`plots[${index}].units`}
                label="Units"
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                name={`plots[${index}].loadPerPlot`}
                label="Load Per Plot (KVA)"
                type="number"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={() => remove(index)}>Remove</Button>
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => push({ plotType: "", plotName: "", units: "", loadPerPlot: "" })}
        >
          Add More Plot Type
        </Button>
      </Grid>
    </>
  )}
</FieldArray>
          
</>
)}

{/* .....................................4th Stage........................................ */}

{formStage === 3 && (
  <>
    <Grid item ml={2}>
      <Typography variant="h6">Send us any relevant files</Typography>
      <Typography>
        If you've got plans and documents you are able to share, please upload them here.
        These will help us produce a more accurate quote for the work.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: "bold" }}>Accommodational Schedule/Plan Layout/Other</Typography>
          <Button variant="contained" component="label">
            Choose File
            <input          
  type="file"
  multiple
  onChange={(event) => handleFileChange(event, setFieldValue, 'sitePlanFiles')}
            />
          </Button>
          {sitePlanFiles.length > 0 && (
            <div style={{ marginTop: '10px' }}>
              {fileDisplay(sitePlanFiles)}
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography>Max file size: 25MB</Typography>
          <Typography>Max number of files: 3</Typography>
        </Grid>
      </Grid>
    </Grid>
  </>
)}
<Grid item xs={12} sm={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
  {formStage > 0 && (
    <Button
      // variant="outlined"
      type="button" 
      variant="contained"
      onClick={(event) => {
        event.preventDefault();
        setFormStage(formStage - 1)
      }
      }
      sx={{ ...globalModalBtnStyle, width: "150px", height: "55px", mr: 2 }}
    >
      Previous Page
    </Button>
  )}
  {formStage < totalStages - 1 ? (
    <Button
    type="button"   // Explicitly set the type to "button"
      variant="contained"
      fullWidth
      onClick={(event) => {
        event.preventDefault();
        setFormStage(formStage + 1);
      }}
      // width: formStage < totalStages - 1 ? "auto" : "45%"
      sx={{ ...globalModalBtnStyle, width: "150px", height: "55px" }}
      disabled={isSubmitting}
    >
      Next Page
    </Button>
  ) : (
    
    <Button type="submit"  // This button triggers form submission
    fullWidth variant="contained"
     color="primary" 
     disabled={isSubmitting || isLoading}
     sx={{ textTransform: 'none' }} 
     >
      {isLoading ? 'Submitting...' : 'Submit'}
    </Button>
  )}
</Grid>
            </Grid>
          </Paper>
        
<Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            message={isLoading ? "Submitting..." : "Operation completed successfully"}
          />
        </Form>
      )}
    </Formik>
  );
};
export default QuoteAdd;

































