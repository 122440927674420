import React from 'react';
import Box from "@mui/material/Box";
import Slider from './Slider';
import Offer from './Offer';
import About from './About';
import Goals from './Goals';

const Home = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt:12
      
      }}
    >
     
    
      <Slider />
   
      <Offer />
     
       <About />
       <Goals/>
       
      
    </Box>
  );
}

export default Home;
