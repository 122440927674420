// import React from "react";
// // import Dashboard from "../feature/dashboard";
// // import { useGetCitiesQuery } from "../redux/reducer/api/cityApiSlice";
// // import { querySubscriptionOption } from "../constant/queryListener";
// import useTitle from "../hook/useTitle";
// // import useAuth from "../hook/useAuth";

// const DashboardPage = () => {
//   useTitle("Dashboard");

//   // useGetCitiesQuery(undefined, {
//   //   ...querySubscriptionOption,
//   // });

//   return (
//     <>
//       <h1>Hello Dashboard Content</h1>
//     </>
//   );
// };

// export default DashboardPage;


import React from "react";
import QuoteStatItem from "./QuoteStatItem";
import EnquiryStatItem from "./EnquiryStatItem";
import Grid from "@mui/material/Grid";
// import CallIcon from "@mui/icons-material/Call";
import DescriptionIcon from "@mui/icons-material/Description";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const QuoteStatArray = [
  {
    title: "Total Quotes",
    percentage: "100%",
    total: "5500",
    logo: <DescriptionIcon  />,
  },
  {
    title: "Approved Quotes",
    percentage: "54.55%",
    total: "3000",
    logo: <DescriptionIcon  />,
  },
  {
    title: "Pending Quotes",
    percentage: "36.36%",
    total: "2000",
    logo: <DescriptionIcon  />,
  },
  {
    title: "Rejected Quotes",
    percentage: "9.09%",
    total: "500",
    logo: <DescriptionIcon  />,
  },
];
const EnquiryStatArray = [
  {
    title: "Total Enquiries",
    percentage: "100%", // Total is always 100%
    total: "4500",
    logo: <HelpOutlineIcon />,
  },
  {
    title: "Approved Enquiries",
    percentage: "77.78%", // Updated percentage
    total: "3500",
    logo: <HelpOutlineIcon/>,
  },
  {
    title: "Pending Enquiries",
    percentage: "11.11%", // Updated percentage
    total: "500",
    logo: <HelpOutlineIcon/>,
  },
  {
    title: "Rejected Enquiries",
    percentage: "11.11%", // Updated percentage
    total: "500",
    logo: <HelpOutlineIcon/>,
  },
];


const Stats = () => {
  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="wrap"
      >
        {QuoteStatArray?.map((item, index) => (
          <Grid item lg md={6} xs={12} key={index}>
            <QuoteStatItem
              title={item?.title}
              percentage={item?.percentage}
              total={item?.total}
              logo={item?.logo}
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        mt={3}
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="wrap"
      >
        {EnquiryStatArray?.map((item, index) => (
          <Grid item lg md={6} xs={12} key={index}>
            <EnquiryStatItem
              title={item?.title}
              percentage={item?.percentage}
              total={item?.total}
              logo={item?.logo}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Stats;

