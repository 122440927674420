// import React from 'react'
// import Box from "@mui/material/Box";
// import { IconButton, Tooltip } from "@mui/material";
// import { Modal } from "@mui/material";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// const EnquiryViewAdmin = ({props}) => {
//     console.log(props)
//     const [open, setOpen] = React.useState(false);

//     const handleOpen = () => setOpen(true);
//     const handleClose = () => setOpen(false);
//   return (
//     <>
//       <Tooltip title="View">
//         <IconButton onClick={handleOpen}>
//           <VisibilityIcon
//             style={{ color: "#294289", cursor: "pointer" }}
//           />
//         </IconButton>
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box>
//             ascd
//         </Box>
//       </Modal>
//     </>
//   )
// }

// export default EnquiryViewAdmin



import React from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Modal,
  Grid,
  Divider,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

const EnquiryViewAdmin = ({ props }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const inquiry = props || {};
  //console.log(inquiry)
  const quote = inquiry.quoteId || {};
  //console.log(inquiry.quoteId)
 // console.log(inquiry.quoteId.user.email)
  const user = inquiry.userId || {};
//   const customer = inquiry.quoteId.user || {};
const customer = quote.user || {};
  //console.log("Customer Detail",customer)

   const formatDate = (dateString) => {
    // Return an empty string if the dateString is null or invalid
    if (!dateString) {
      return "";
    }
  
    const date = new Date(dateString);
  
    // Check if the date is invalid
    if (isNaN(date.getTime())) {
      return "";
    }
  
    return date.toISOString().split('T')[0];  // Format as YYYY-MM-DD
  };

  return (
    <>
      <Tooltip title="View">
        <IconButton onClick={handleOpen}>
          <VisibilityIcon style={{ color: "#294289", cursor: "pointer" }} />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "95%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            overflowY: "auto",
            maxHeight: "80vh",
          }}
        >
         
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
            <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Enquiry Details
          </Typography>
          <Divider />
              <Typography variant="subtitle1"><strong>Enquiry ID:</strong> {inquiry.inquiryId}</Typography>
              <Typography variant="subtitle1"><strong>Offer Expiry Date:</strong> {inquiry.offerExpiryDate}</Typography>
              <Typography variant="subtitle1"><strong>Total Price:</strong> {inquiry.totalPrice}</Typography>
              <Typography variant="subtitle1"><strong>Admin Status:</strong> {inquiry.adminStatus}</Typography>
              <Typography variant="subtitle1"><strong>Customer Status:</strong> {inquiry.customerStatus}</Typography>
              <Typography variant="subtitle1"><strong>Drawing File</strong></Typography>
              {inquiry.drawingFile ? (
                <Button
                  variant="contained"
                  color="primary"
                  href={inquiry.drawingFile}
                  target="_blank"
                  sx={{ textTransform: "none", mt: 1 }}
                >
                  View File
                </Button>
              ) : (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  No offer letter available
                </Typography>
              )}
              <Typography variant="subtitle1"><strong>Proposal File</strong></Typography>
              {inquiry.proposalFile ? (
                <Button
                  variant="contained"
                  color="primary"
                  href={inquiry.proposalFile}
                  target="_blank"
                  sx={{ textTransform: "none", mt: 1 }}
                >
                  View File
                </Button>
              ) : (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  No offer letter available
                </Typography>
              )}
              <Typography variant="subtitle1"><strong>Additional Documents</strong></Typography>
              {inquiry.additionalDocuments?.map((file, index) => file && (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  href={file}
                  target="_blank"
                  sx={{ textTransform: "none", mt: 1, mr: 1 }}
                >
                  File {index + 1}
                </Button>
              ))}
            </Grid>

            <Grid item xs={12} md={6}>
            <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Comapny Details
          </Typography>
          <Divider />
              <Typography variant="subtitle1"><strong>Username:</strong> {user.username}</Typography>
              <Typography variant="subtitle1"><strong>Email:</strong> {user.email}</Typography>
              <Typography variant="subtitle1"><strong>Contact Number:</strong> {user.contactNumber}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" component="h3" sx={{ mt: 2 }}>Customer  Details</Typography>
              <Divider />
              <Typography variant="subtitle1"><strong>Quote ID:</strong> {quote.quoteId}</Typography>
            <Typography variant="subtitle1"><strong>Name:</strong> {quote.name}</Typography>
            <Typography variant="subtitle1"><strong>Email:</strong> {quote.email}</Typography>
            <Typography variant="subtitle1"><strong>Comapny Name:</strong> {quote.companyName}</Typography>
            <Typography variant="subtitle1"><strong>Site Address:</strong> {quote.postalCode}</Typography>
            <Typography variant="subtitle1"><strong>Site Address:</strong> {quote.siteAddress}</Typography>
            <Typography variant="subtitle1"><strong>Full Address:</strong> {quote.fullAddress}</Typography>
            
           
              
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h6" component="h3" sx={{ mt: 2 }}>Quote Details</Typography>
              <Divider />
              <Typography variant="subtitle1"><strong>Status:</strong> {quote.status}</Typography>
              <Typography variant="subtitle1"><strong>Services:</strong> {quote.services ? quote.services.replace(/[0-9.]/g, "") : ""}</Typography>
              <Typography variant="subtitle1"><strong>Grid Reference:</strong> {quote.GridReference}</Typography>
              <Typography variant="subtitle1"><strong>DNO:</strong> {quote.DNO}</Typography>
              <Typography variant="subtitle1"><strong>Heating Type:</strong> {quote.heatingType ? quote.heatingType.replace(/[0-9.]/g, "") : ""}</Typography>
              <Typography variant="subtitle1"><strong>Custom Heating Type:</strong> {quote.customHeatingType}</Typography>
              <Typography variant="subtitle1"><strong>Total Site Load:</strong> {quote.totalSiteLoad }</Typography>
              <Typography variant="subtitle1"><strong>Quote deadline:</strong> {formatDate(quote.quoteDeadline)}</Typography>
              <Typography variant="subtitle1"><strong>Expected date:</strong> {formatDate(quote.expectedDate)}</Typography>
              <Typography variant="subtitle1"><strong>Offer Letter File:</strong></Typography>
              {quote.offerLetterFile ? (
                <Button
                  variant="contained"
                  color="primary"
                  href={quote.offerLetterFile}
                  target="_blank"
                  sx={{ textTransform: "none", mt: 1 }}
                >
                  View File
                </Button>
              ) : (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                 
                </Typography>
              )}
              <Typography variant="subtitle1"><strong>Site Plan Files:</strong></Typography>
              {quote.sitePlanFiles?.map((file, index) => file && (
                <Button
                  key={index}
                  variant="contained"
                  color="primary"
                  href={file}
                  target="_blank"
                  sx={{ textTransform: "none", mt: 1, mr: 1 }}
                >
                  File {index + 1}
                </Button>
              ))}
              
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" component="h3" sx={{ mt: 2 }}>Account Details</Typography>
              <Divider />
              <Typography variant="subtitle1"><strong>Username:</strong> {customer.username || ""}</Typography>
              <Typography variant="subtitle1"><strong>Email:</strong> {customer.email || ""}</Typography>
              <Typography variant="subtitle1"><strong>Contact Number:</strong> {customer.contactNumber || ""}</Typography>
            </Grid>

            <Grid item xs={12}>
             <Typography variant="subtitle1"><strong>Plots:</strong></Typography>
              <TableContainer component={Paper}>
                <Table aria-label="plots table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Plot Type</strong></TableCell>
                      <TableCell><strong>Plot Name</strong></TableCell>
                      <TableCell><strong>Units</strong></TableCell>
                      <TableCell><strong>Load per Plot</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quote.plots?.map((plot, index) => (
                      <TableRow key={index}>
                        <TableCell>{plot.plotType}</TableCell>
                        <TableCell>{plot.plotName || ""}</TableCell>
                        <TableCell>{plot.units}</TableCell>
                        <TableCell>{plot.loadPerPlot}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

           
         
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                sx={{ textTransform: "none", mr: 2 }}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default EnquiryViewAdmin;
