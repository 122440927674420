import { useGetQuotesByCustomerQuery } from "../../redux/reducer/api/quoteApiSlice";
import { queryListener } from "../../constant/object";
const useQuotesByCustomerQuery = (option = queryListener.default) => {
  let array = [];

  const { data, isLoading, isSuccess, refetch } = useGetQuotesByCustomerQuery({},option);

  if (isSuccess) {
     array = data || []; 
    // array = Object.values(data) || [];
  }
// console.log("All Quotes array is :",array)
  return {
    array,
    isLoading,
    refetch,
  };
};

export default useQuotesByCustomerQuery;





