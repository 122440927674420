// import { apiSlice } from "../../service/apiSlice"
// import { logOut,setToken } from "../shared/authSlice";
// export const quoteApiSlice = apiSlice.injectEndpoints({
//     endpoints: builder => ({
//         createQuote:builder.mutation({
//             query:(payload)=>({
//                     url:'/quote',
//                     method:"POST",
//                     body:payload
//                 }),          
//         }),
//     })
// })
// export const {
//     useCreateQuoteMutation,
// } = quoteApiSlice 



// // import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// // // import { selectCurrentToken } from './authSlice';  // Adjust the path as necessary to import your auth slice correctly
// // import {selectCurrentToken} from '../shared/authSlice'

// // // Setting up the base query with authorization headers
// // const baseQuery = fetchBaseQuery({
// //     baseUrl: 'http://localhost:3500',  // Your server's base URL
// //     prepareHeaders: (headers, { getState }) => {
// //         const token = selectCurrentToken(getState());
// //         if (token) {
// //             headers.set('Authorization', `Bearer ${token}`);
// //         }
// //         return headers;
// //     }
// // });

// // export const apiSlice = createApi({
// //     reducerPath: 'api',
// //     baseQuery: baseQuery,
// //     endpoints: builder => ({
// //         createQuote: builder.mutation({
// //             query: (payload) => ({
// //                 url: '/quote',
// //                 method: 'POST',
// //                 body: payload
// //             }),
// //         }),
// //         // Add other endpoints here
// //     })
// // });

// // export const { useCreateQuoteMutation } = apiSlice;



// import { apiSlice } from "../../service/apiSlice";
// import { logOut, setToken } from "../shared/authSlice"; // Make sure paths are correct.

// export const quoteApiSlice = apiSlice.injectEndpoints({
//     endpoints: builder => ({
//         createQuote: builder.mutation({
//             query: (payload) => ({
//                 url: '/quote',
//                 method: "POST",
//                 body: payload
//             }),
//         }),
//     })
// });

// export const {
//     useCreateQuoteMutation,
// } = quoteApiSlice;


// import { apiSlice } from './apiSlice';
import { apiSlice } from "../../service/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";

const adapter = createEntityAdapter({
  selectId: (quote) => quote.quoteId, // Ensuring quoteId is used as the identifier
});

const initialState = adapter.getInitialState();

export const quoteApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createQuote: builder.mutation({
            query: (quoteData) => ({
                url: '/quote',
                method: 'POST',
                body: quoteData,
            }),
        }),
        // Add more endpoints as needed
        getAllQuotes: builder.query({
            query: () => `/quote/admin/quotes`,
            validateStatus: (response, result) => {
              return response.status === 200;
            },
            transformResponse: (response) => {
              return response?.data ?? [];
            },
            
          }),
          getQuotesByCustomer: builder.query({
            query: () => `/quote/customer/quotes`,
            validateStatus: (response, result) => {
              return response.status === 200;
            },
            transformResponse: (response) => {
              console.log(response)
              return response?.data ?? [];
            },
            
          }),
          getQuotesByCompany: builder.query({
            query: () => `/quote/company/quotes`,
            validateStatus: (response, result) => {
              return response.status === 200;
            },
            transformResponse: (response) => {
              return response?.data ?? [];
            },
            
          }),
      
deleteQuote: builder.mutation({
    query: ({ quoteId }) => ({
        url: `/quote/${quoteId}`, // Use quoteId in the URL
        method: "DELETE",
    }),
    onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
            const result = await queryFulfilled;
            console.log('Delete successful', result);
        } catch (error) {
            console.error('Delete failed', error);
        }
    }
}),
deleteQuoteFile: builder.mutation({
    query: ({ quoteId,fileField,fileIndex }) => ({
        url: `/quote/${quoteId}/files/${fileField}/${fileIndex}`, 
        method: "DELETE",
    }),
    onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
            const result = await queryFulfilled;
            console.log('File Delete successful', result);
        } catch (error) {
            console.error('File Delete failed', error);
        }
    }
}),

updateQuoteStatus: builder.mutation({
      query: (payload) => ({
        url: `/quote/update-quote-status`,
        method: 'PATCH',
        body: payload,
      }),
    }),

    updateQuote: builder.mutation({
      query: ({ quoteId, payload }) => ({
        url: `/quote/${quoteId}`,
        method: 'PUT',
        body: payload,  // This should be FormData, so don't stringify it
      }),
    }),
getQuoteById: builder.query({
      query: (quoteId) => `/quote/admin/${quoteId}`,
      validateStatus: (response, result) => {
        return response.status === 200;
      },
      transformResponse: (response) => {
        
        // Ensure only the quote data is processed, not the entire response object
        if (response && response.data) {
          return adapter.setAll(initialState, [response.data]);
        }
        return initialState; // Return empty initialState if no data
      },
    }),
  

    }),
    overrideExisting: false,
});

export const {
    useCreateQuoteMutation,
    useGetAllQuotesQuery,
    useGetQuotesByCustomerQuery,
    useGetQuotesByCompanyQuery,
    useDeleteQuoteMutation,
    useUpdateQuoteStatusMutation,
    useUpdateQuoteMutation,
    useGetQuoteByIdQuery,
    useDeleteQuoteFileMutation
} = quoteApiSlice;
