
// import React, { useState, useEffect } from "react";
// import { Formik, Field, Form, FieldArray, useField } from "formik";
// import * as yup from "yup";
// import PlotTypes from './PlotTypes';
// import {
//   FormLabel, RadioGroup, FormControlLabel,
//   Grid, Typography, Paper, TextField, Button, Radio,
//   InputLabel, Select, MenuItem, FormControl, Snackbar
// } from "@mui/material";
// import { useParams } from "react-router-dom";
// import dayjs from 'dayjs';
// import useGetQuoteById from "../../../hook/routing/useGetQuoteById";
// import { useUpdateQuoteMutation } from '../../../redux/reducer/api/quoteApiSlice';
// import { notification } from "../../../util/notification";
// import { removeEmptyValues } from "../../../util/helper-function";
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { useNavigate } from "react-router-dom";

// const statusTypes = ['Pending', 'Declined', 'Approved'];
// const MySelect = ({ label, placeholder, isBoolean = false, ...props }) => {
//   const [field, meta] = useField(props);

//   const renderDisplayValue = (selected) => {
//     if (selected === "" || selected === null || selected === undefined) {
//       return <Typography color="textSecondary">{placeholder}</Typography>;
//     }
//     if (isBoolean) {
//       return selected === true ? "True" : "False"; // Display "True" or "False" for boolean values
//     }
//     return selected; // Display the actual value for non-boolean fields
//   };

//   return (
//     <FormControl fullWidth error={meta.touched && !!meta.error}>
//       <InputLabel>{label}</InputLabel>
//       <Select
//         {...field}
//         {...props}
//         value={field.value === undefined || field.value === null ? "" : field.value} // Ensure value is correct
//         displayEmpty
//         renderValue={renderDisplayValue}
//       >
//         <MenuItem value="" disabled>{placeholder}</MenuItem>
//         {isBoolean ? (
//           [
//             <MenuItem key="true" value={true}>True</MenuItem>,
//             <MenuItem key="false" value={false}>False</MenuItem>
//           ]
//         ) : (
//           props.children
//         )}
//       </Select>
//       {meta.touched && meta.error ? (
//         <Typography color="error" variant="body2">{meta.error}</Typography>
//       ) : null}
//     </FormControl>
//   );
// };
// const QuoteEditAdmin = () => {
//   const navigate = useNavigate();
//   const { quoteId } = useParams();
//   console.log(quoteId);
//   const { quote,  refetch } = useGetQuoteById(quoteId);
//    console.log("ww",quote)
//   const [updateQuote, { isLoading: isUpdating }] = useUpdateQuoteMutation();
//   const [openSnackbar, setOpenSnackbar] = React.useState(false);
//   const [heatingType, setHeatingType] = useState(quote?.heatingType || '');

//   useEffect(() => {
//     refetch().catch((error) => {
//       console.error("Error fetching data:", error);
//     });
//   }, [refetch]);

//   // const [offerLetterFile, setOfferLetterFile] = useState(null);
//   // const [sitePlanFiles, setSitePlanFiles] = useState([]);

//   const [offerLetterFile, setOfferLetterFile] = useState(quote?.offerLetterFile || null);
// const [sitePlanFiles, setSitePlanFiles] = useState(quote?.sitePlanFiles || []);
//   const initialValues = {
//     dno: quote?.DNO || "",
//     gridReference: quote?.GridReference || "",
//     totalSiteLoad: quote?.totalSiteLoad || "",
//     expectedDate: quote?.expectedDate ? dayjs(quote?.expectedDate).format('YYYY-MM-DD') : "",
//     quoteDeadline: quote?.quoteDeadline ? dayjs(quote?.quoteDeadline).format('YYYY-MM-DD') : "",
//     status: quote?.status || "",
//     services: quote?.services || "",
//     worksOffer: quote?.worksOffer || false,
//     offerLetterFile: quote?.offerLetterFile || null,
//     sitePlanFiles: quote?.sitePlanFiles || [],
//     fullAddress: quote?.fullAddress || "",
//     siteAddress: quote?.siteAddress || "",
//     heatingType: quote?.heatingType || "",
//     customHeatingType: quote?.heatingType === "5.Other" ? quote?.customHeatingType || "" : "",
//     plots: quote?.plots || [{ plotType: "", plotName: "", units: "", loadPerPlot: "" }],
//     postalCode: quote?.postalCode || "",
//     specialNote: quote?.specialNote || ""
//   };
//   // const handleSubmit = async (values) => {
//   //   console.log("Form Values:", values);
//   //   try {
//   //     const payload = new FormData();
//   //     for (const key in values) {
//   //       if (key === "plots") {
//   //         payload.append(key, JSON.stringify(values[key]));
//   //       } else if (key === "offerLetterFile") {
//   //         if (values.offerLetterFile) {
//   //           payload.append(key, values.offerLetterFile);
//   //         }
//   //       } else if (key === "sitePlanFiles") {
//   //         values.sitePlanFiles.forEach((file, index) => {
//   //           if (file instanceof File) {
//   //             payload.append(`sitePlanFiles[${index}]`, file);
//   //           }
//   //         });
//   //       } else if (key === "customHeatingType") {
//   //         if (values.heatingType === "5.Other" && values.customHeatingType) {
//   //           payload.append(key, values.customHeatingType);
//   //         }
//   //       } else if (key === "worksOffer") {
//   //         payload.append(key, values[key] ? "yes" : "no"); // Convert boolean to string
//   //       } else {
//   //         payload.append(key, values[key]);
//   //       }
//   //     }
  
//   //     await updateQuote({ quoteId, payload }).unwrap();
//   //     notification.success("Quote updated successfully!");
//   //   } catch (err) {
//   //     console.error("Error updating quote:", err);
//   //     notification.error("Failed to update quote. Please try again.");
//   //   }
//   // };
  
//   const handleSubmit = async (values) => {
//     console.log("Form Values:", values);
//     try {
//       const payload = new FormData();

//       for (const key in values) {
//         if (key === "plots") {
//           payload.append(key, JSON.stringify(values[key]));
//         } else if (key === "offerLetterFile") {
//           // Include the existing offer letter if no new file is selected
//           if (values.offerLetterFile instanceof File) {
//             payload.append(key, values.offerLetterFile);
//           } else if (values.offerLetterFile) {
//             payload.append(key, values.offerLetterFile); // Preserve existing URL
//           }
//         } else if (key === "sitePlanFiles") {
//           // Preserve existing site plan files and include new ones
//           values.sitePlanFiles.forEach((file, index) => {
//             if (file instanceof File) {
//               payload.append(`sitePlanFiles[${index}]`, file);
//             } else if (file) {
//               payload.append(`sitePlanFiles[${index}]`, file); // Preserve existing URL
//             }
//           });
//         } else if (key === "customHeatingType") {
//           if (values.heatingType === "5.Other" && values.customHeatingType) {
//             payload.append(key, values.customHeatingType);
//           }
//         } else if (key === "worksOffer") {
//           payload.append(key, values[key] ? "yes" : "no");
//         } else {
//           payload.append(key, values[key]);
//         }
//       }

//       await updateQuote({ quoteId, payload }).unwrap();
//       notification.success("Quote updated successfully!");
//     } catch (err) {
//       console.error("Error updating quote:", err);
//       notification.error("Failed to update quote. Please try again.");
//     }
//   };
//   // const handleSubmit = async (values) => {
//   //   console.log("Form Values before submission:", values);  // Debugging step
//   //   try {
//   //     const payload = new FormData();
  
//   //     // Append non-file fields
//   //     for (const key in values) {
//   //       if (key === "plots") {
//   //         payload.append(key, JSON.stringify(values[key]));
//   //       } else if (key === "worksOffer") {
//   //         payload.append(key, values[key] ? "yes" : "no");
//   //       } else {
//   //         payload.append(key, values[key]);
//   //       }
//   //     }
//   //     // Handle offerLetterFile
//   //     if (values.offerLetterFile instanceof File) {
//   //       payload.append("offerLetterFile", values.offerLetterFile);
//   //     } else if (values.offerLetterFile) {
//   //       payload.append("offerLetterFile", values.offerLetterFile); // Preserve existing URL
//   //     }
//   //     // Handle sitePlanFiles
//   //     values.sitePlanFiles.forEach((file, index) => {
//   //       if (file instanceof File) {
//   //         payload.append(`sitePlanFiles[${index}]`, file);
//   //       } else if (file) {
//   //         payload.append(`sitePlanFiles[${index}]`, file); // Preserve existing URL
//   //       }
//   //     });
  
//   //     await updateQuote({ quoteId, payload }).unwrap();
//   //     notification.success("Quote updated successfully!");
//   //   } catch (err) {
//   //     console.error("Error updating quote:", err);
//   //     notification.error("Failed to update quote. Please try again.");
//   //   }
//   // };
  
//   const validationSchema = yup.object({
//      });
//   return (
//     <Formik
//   initialValues={initialValues}
//   validationSchema={validationSchema}
//   onSubmit={handleSubmit}
//   enableReinitialize
// >
//       {({ setFieldValue, values,errors,touched,isSubmitting }) => (
//         <Form>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <Field
//                 name="dno"
//                 label="DNO"
//                 as={TextField}
//                 fullWidth
//                 required
//               />
//             </Grid>

//             <Grid item xs={12} sm={6}>
//               <Field
//                 name="gridReference"
//                 label="Grid Reference"
//                 as={TextField}
//                 fullWidth
//                 required
//               />
//             </Grid>

//             <Grid item xs={12} sm={6}>
//               <Field
//                 name="totalSiteLoad"
//                 label="Total Site Load"
//                 as={TextField}
//                 type="number"
//                 fullWidth
//                 required
//               />
//             </Grid>

//             <Grid item xs={12} sm={6}>
//               <Field
//                 name="expectedDate"
//                 label="Expected Date"
//                 as={TextField}
//                 type="date"
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 fullWidth
//                 required
//               />
//             </Grid>

//             <Grid item xs={12} sm={6}>
//               <Field
//                 name="quoteDeadline"
//                 label="Quote Deadline"
//                 as={TextField}
//                 type="date"
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 fullWidth
//                 required
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <MySelect
//                 name="status"
//                 label="Status"
//                 placeholder="Select Status"
//               >
//                 {statusTypes.map(status => (
//                   <MenuItem key={status} value={status}>
//                     {status}
//                   </MenuItem>
//                 ))}
//               </MySelect>
//             </Grid>
//                   {/* Services Selection */}
//                   <Grid item xs={12} sx={{ p: 2 }}>
//                     <FormControl component="fieldset" fullWidth>
//                        <FormLabel component="legend">Which services do you need?</FormLabel>
//                       <RadioGroup
//                         row
//                         name="services"
//                         value={values.services}
//                         onChange={(event) => setFieldValue("services", event.target.value)}
//                       >
//                         <FormControlLabel
//                           value="1.Electricity_Connections"
//                           control={<Radio />}
//                           label="Electricity connections"
//                         />
//                         <FormControlLabel
//                           value="2.Gas_Connections"
//                           control={<Radio />}
//                           label="Gas connections"
//                         />
//                         <FormControlLabel
//                           value="3.Water_Services"
//                           control={<Radio />}
//                           label="Water services"
//                         />
//                         <FormControlLabel
//                           value="4.Fiber_Services"
//                           control={<Radio />}
//                           label="Fiber services"
//                         />
//                       </RadioGroup>
//                       {touched.services && errors.services && (
//                         <Typography color="error" variant="caption">
//                           {errors.services}
//                         </Typography>
//                       )}
//                     </FormControl>
//                   </Grid>
// <Grid item xs={12} sm={6}>
//   <MySelect
//     name="worksOffer"
//     label="Works Offer"
//     placeholder="Select Works Offer"
//     isBoolean={true} // This ensures that it handles boolean values
//     value={values.worksOffer}
//     onChange={(e) => setFieldValue("worksOffer", e.target.value)}
//   />
// </Grid>
//             <Grid item xs={12} sm={6}>
//               <Field
//                 name="siteAddress"
//                 label="Site Address"
//                 as={TextField}
//                 fullWidth
//                 required
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <Field
//                 name="fullAddress"
//                 label="Full Address"
//                 as={TextField}
//                 fullWidth
//                 required
//               />
//             </Grid>
// {/* Heating Type Selection */}
// <Grid item xs={12}>
//           <FormControl component="fieldset" fullWidth>
//             <FormLabel component="legend">Types of Heating</FormLabel>
//             <RadioGroup
//               row
//               name="heatingType"
//               value={values.heatingType}
//               onChange={(event) => {
//                 setFieldValue("heatingType", event.target.value);
//                 setHeatingType(event.target.value); // Save the selected heating type in state
//               }}
//             >
//               <FormControlLabel
//                 value="1.Electric"
//                 control={<Radio />}
//                 label="Electric"
//               />
//               <FormControlLabel
//                 value="2.Gas"
//                 control={<Radio />}
//                 label="Gas"
//               />
//               <FormControlLabel
//                 value="3.ASHP"
//                 control={<Radio />}
//                 label="ASHP"
//               />
//               <FormControlLabel
//                 value="4.District_Heating"
//                 control={<Radio />}
//                 label="District Heating"
//               />
//               <FormControlLabel
//                 value="5.Other"
//                 control={<Radio />}
//                 label="Other"
//               />
//             </RadioGroup>
//           </FormControl>
//         </Grid>

//         {/* Custom Heating Type Input */}
//         {values.heatingType === "5.Other" && (
//           <Grid item xs={12} sm={6}>
//             <Field name="customHeatingType">
//               {({ field, meta }) => (
//                 <TextField
//                   required
//                   id="customHeatingType"
//                   label="Please enter type of heating."
//                   fullWidth
//                   value={field.value || ""} // Ensure the field value is not undefined
//                   onChange={(event) =>
//                     setFieldValue(
//                       "customHeatingType",
//                       event.target.value
//                     )
//                   }
//                   InputLabelProps={{ shrink: true }}
//                   error={meta.touched && !!meta.error}
//                   helperText={meta.touched ? meta.error : ""}
//                   {...field}
//                 />
//               )}
//             </Field>
//           </Grid>
//         )}
//             <Grid item xs={12}>
//             <FieldArray name="plots">
//   {({ push, remove }) => (
//     <>
//       {values.plots.map((plot, index) => (
//         <React.Fragment key={index}>
//           <Grid container spacing={2}>
//             <Grid item xs={3}>
//               <MySelect
//                 placeholder="Select a plot type..."
//                 name={`plots[${index}].plotType`}
//                 onChange={(e) => {
//                   setFieldValue(`plots[${index}].plotType`, e.target.value);
//                   if (e.target.value !== "Other") {
//                     setFieldValue(`plots[${index}].plotName`, '');
//                   }
//                 }}
//                 fullWidth
//               >
//                 {PlotTypes.map((type, idx) => (
//                   <MenuItem key={idx} value={type}>{type}</MenuItem>
//                 ))}
//               </MySelect>
//             </Grid>

//             {plot.plotType === "Other" && (
//               <Grid item xs={3}>
//                 <Field
//                   name={`plots[${index}].plotName`}
//                   label="Plot Name"
//                   as={TextField}
//                   fullWidth
//                   required
//                 />
//               </Grid>
//             )}
//             <Grid item xs={2}>
//               <Field
//                 name={`plots[${index}].units`}
//                 label="Units"
//                 as={TextField}
//                 type="number"
//                 fullWidth
//                 required
//               />
//             </Grid>
//             <Grid item xs={2}>
//               <Field
//                 name={`plots[${index}].loadPerPlot`}
//                 label="Load per Plot (KVA)"
//                 as={TextField}
//                 type="number"
//                 fullWidth
//                 required
//               />
//             </Grid>
//             <Grid item xs={2}>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={() => remove(index)}
//               >
//                 Remove
//               </Button>
//             </Grid>
//           </Grid>
//         </React.Fragment>
//       ))}
//       <Grid item xs={12}>
//         <Button
//           variant="contained"
//           color="primary"
//           startIcon={<AddCircleOutlineIcon />}
//           onClick={() => push({ plotType: "", plotName: "", units: "", loadPerPlot: "" })}
//         >
//           Add Plot
//         </Button>
//       </Grid>
//     </>
//   )}
// </FieldArray>
// </Grid>
//             <Grid item xs={12} sm={6}>
//               <Field
//                 name="postalCode"
//                 label="Postal Code"
//                 as={TextField}
//                 fullWidth
//                 required
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <Field
//                 name="specialNote"
//                 label="Special Note"
//                 as={TextField}
//                 multiline
//                 rows={4}
//                 fullWidth
//               />
//             </Grid>

//             <Grid item xs={12} sm={6} sx={{ p: 2 }}>
//               <Typography variant="subtitle1">Current Offer Letter File:</Typography>
//               {values.offerLetterFile ? (
//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                   <a
//                     href={values.offerLetterFile instanceof File ? URL.createObjectURL(values.offerLetterFile) : values.offerLetterFile}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     {values.offerLetterFile instanceof File ? values.offerLetterFile.name : values.offerLetterFile?.split('/').pop()}
//                   </a>
//                   <Button
//                     variant="outlined"
//                     color="secondary"
//                     size="small"
//                     onClick={() => {
//                       setFieldValue('offerLetterFile', null); // Clear the offer letter file
//                       setOfferLetterFile(null); // Update state
//                     }}
//                     sx={{ ml: 2 }}
//                   >
//                     Remove
//                   </Button>
//                 </div>
//               ) : (
//                 <Typography variant="body2" color="textSecondary">
//                   No offer letter file available.
//                 </Typography>
//               )}
//               <TextField
//                 type="file"
//                 name="offerLetterFile"
//                 fullWidth
//                 onChange={(event) => {
//                   const file = event.currentTarget.files[0];
//                   setFieldValue('offerLetterFile', file); // Set the selected file for the offer letter
//                   setOfferLetterFile(file); // Update state
//                 }}
//               />
//             </Grid>

//             {/* Site Plan Files Section */}
//             <Grid item xs={12} sm={6} sx={{ p: 2 }}>
//               <Typography variant="subtitle1">Current Site Plan Files:</Typography>
//               {Array.isArray(values.sitePlanFiles) && values.sitePlanFiles.length > 0 ? (
//                 values.sitePlanFiles.map((file, index) => (
//                   <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
//                     {file && (
//                       <>
//                         <a
//                           href={file instanceof File ? URL.createObjectURL(file) : file}
//                           target="_blank"
//                           rel="noopener noreferrer"
//                         >
//                           {file instanceof File ? file.name : file?.split('/').pop()}
//                         </a>
//                         <Button
//                           variant="outlined"
//                           color="secondary"
//                           size="small"
//                           onClick={() => {
//                             const updatedFiles = values.sitePlanFiles.map((f, i) =>
//                               i === index ? null : f // Set specific file to null
//                             );
//                             setFieldValue('sitePlanFiles', updatedFiles);
//                             setSitePlanFiles(updatedFiles); // Update state
//                           }}
//                           sx={{ ml: 2 }}
//                         >
//                           Remove
//                         </Button>
//                       </>
//                     )}
//                   </div>
//                 ))
//               ) : (
//                 <Typography variant="body2" color="textSecondary">
//                   No site plan files available.
//                 </Typography>
//               )}
//               <TextField
//                 type="file"
//                 name="sitePlanFiles"
//                 fullWidth
//                 inputProps={{ multiple: true }}
//                 onChange={(event) => {
//                   const files = Array.from(event.currentTarget.files);
//                   const updatedFiles = values.sitePlanFiles.filter(file => file !== null).concat(files);
//                   setFieldValue('sitePlanFiles', updatedFiles);
//                   setSitePlanFiles(updatedFiles); // Update state
//                 }}
//               />


//             </Grid>


//             {/* <Grid item xs={12} sm={6} sx={{ p: 2 }}>
//   <Typography variant="subtitle1">Current Offer Letter File:</Typography>
//   {values.offerLetterFile ? (
//     <div style={{ display: 'flex', alignItems: 'center' }}>
//       <a
//         href={values.offerLetterFile instanceof File ? URL.createObjectURL(values.offerLetterFile) : values.offerLetterFile}
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         {values.offerLetterFile instanceof File ? values.offerLetterFile.name : values.offerLetterFile?.split('/').pop()}
//       </a>
//       <Button
//         variant="outlined"
//         color="secondary"
//         size="small"
//         onClick={() => {
//           setFieldValue('offerLetterFile', null); // Clear the offer letter file
//         }}
//         sx={{ ml: 2 }}
//       >
//         Remove
//       </Button>
//     </div>
//   ) : (
//     <Typography variant="body2" color="textSecondary">
//       No offer letter file available.
//     </Typography>
//   )}
//   <TextField
//     type="file"
//     name="offerLetterFile"
//     fullWidth
//     onChange={(event) => {
//       const file = event.currentTarget.files[0];
//       setFieldValue('offerLetterFile', file); // Set the selected file for the offer letter
//     }}
//     error={touched.offerLetterFile && Boolean(errors.offerLetterFile)}
//     helperText={touched.offerLetterFile && errors.offerLetterFile}
//   />
// </Grid>

// <Grid item xs={12} sm={6} sx={{ p: 2 }}>
//   <Typography variant="subtitle1">Current Site Plan Files:</Typography>
//   {Array.isArray(values.sitePlanFiles) && values.sitePlanFiles.length > 0 ? (
//     values.sitePlanFiles.map((file, index) => (
//       <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
//         {file && (
//           <>
//             <a
//               href={file instanceof File ? URL.createObjectURL(file) : file}
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               {file instanceof File ? file.name : file?.split('/').pop()}
//             </a>
//             <Button
//               variant="outlined"
//               color="secondary"
//               size="small"
//               onClick={() => {
//                 const updatedFiles = values.sitePlanFiles.map((f, i) =>
//                   i === index ? null : f // Set specific file to null
//                 );
//                 setFieldValue('sitePlanFiles', updatedFiles);
//               }}
//               sx={{ ml: 2 }}
//             >
//               Remove
//             </Button>
//           </>
//         )}
//       </div>
//     ))
//   ) : (
//     <Typography variant="body2" color="textSecondary">
//       No site plan files available.
//     </Typography>
//   )}
//   <TextField
//     type="file"
//     name="sitePlanFiles"
//     fullWidth
//     inputProps={{ multiple: true }}
//     onChange={(event) => {
//       const files = Array.from(event.currentTarget.files);
//       const updatedFiles = values.sitePlanFiles.filter(file => file !== null).concat(files);
//       setFieldValue('sitePlanFiles', updatedFiles);
//     }}
//     error={touched.sitePlanFiles && Boolean(errors.sitePlanFiles)}
//     helperText={touched.sitePlanFiles && errors.sitePlanFiles}
//   />
// </Grid> */}

//             <Grid item xs={12}>
//               <Button
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 disabled={isSubmitting}
//               >
//                 {isSubmitting ? "Updating..." : "Update Quote"}
//               </Button>
//             </Grid>
//           </Grid>
//           <Snackbar
//             open={openSnackbar}
//             autoHideDuration={6000}
//             onClose={() => setOpenSnackbar(false)}
//             message="Quote updated successfully!"
//           />
//         </Form>
//       )}
//     </Formik>
//   );
// };

// export default QuoteEditAdmin


// {/* //................Current Site Plan File................... */}

//             {/* Multiple Files Removal */}
//           {/* <Grid item xs={12} sm={6} sx={{ p: 2 }}>
//             <Typography variant="subtitle1">Current Site Plan Files:</Typography>
//             {Array.isArray(values.sitePlanFiles) && values.sitePlanFiles.map((file, index) => (
//               <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
//                 <a
//                   href={file instanceof File ? URL.createObjectURL(file) : file}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   {file instanceof File ? file.name : file.split('/').pop()}
//                 </a>
//                 <Button
//                   variant="outlined"
//                   color="secondary"
//                   size="small"
//                   onClick={() => {
//                     const updatedFiles = values.sitePlanFiles.filter((_, i) => i !== index);
//                     setFieldValue('sitePlanFiles', updatedFiles);
//                   }}
//                   sx={{ ml: 2 }}
//                 >
//                   Remove
//                 </Button>
//               </div>
//             ))}
//             <TextField
//               type="file"
//               name="sitePlanFiles"
//               fullWidth
//               inputProps={{ multiple: true }}
//               onChange={(event) => {
//                 const files = Array.from(event.currentTarget.files);
//                 const updatedFiles = [...values.sitePlanFiles, ...files];
//                 setFieldValue('sitePlanFiles', updatedFiles);
//               }}
//               error={touched.sitePlanFiles && Boolean(errors.sitePlanFiles)}
//               helperText={touched.sitePlanFiles && errors.sitePlanFiles}
//             />
//           </Grid> */}













import React, { useState, useEffect } from "react";
import { Formik, Field, Form, FieldArray, useField } from "formik";
import * as yup from "yup";
import PlotTypes from './PlotTypes';
import {
  FormLabel, RadioGroup, FormControlLabel,
  Grid, Typography, Paper, TextField, Button, Radio,
  InputLabel, Select, MenuItem, FormControl, Snackbar,Box
} from "@mui/material";
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';
import useGetQuoteById from "../../../hook/routing/useGetQuoteById";
import { useUpdateQuoteMutation,useDeleteQuoteFileMutation } from '../../../redux/reducer/api/quoteApiSlice';
import { notification } from "../../../util/notification";
import { removeEmptyValues } from "../../../util/helper-function";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import { uploadFileFormat } from "../../../constant/uploadFileFormat";

const statusTypes = ['Pending', 'Declined', 'Approved'];
const MySelect = ({ label, placeholder, isBoolean = false, ...props }) => {
  const [field, meta] = useField(props);

  const renderDisplayValue = (selected) => {
    if (selected === "" || selected === null || selected === undefined) {
      return <Typography color="textSecondary">{placeholder}</Typography>;
    }
    if (isBoolean) {
      return selected === true ? "True" : "False"; // Display "True" or "False" for boolean values
    }
    return selected; // Display the actual value for non-boolean fields
  };

  return (
    <FormControl fullWidth error={meta.touched && !!meta.error}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        {...props}
        value={field.value === undefined || field.value === null ? "" : field.value} // Ensure value is correct
        displayEmpty
        renderValue={renderDisplayValue}
      >
        <MenuItem value="" disabled>{placeholder}</MenuItem>
        {isBoolean ? (
          [
            <MenuItem key="true" value={true}>True</MenuItem>,
            <MenuItem key="false" value={false}>False</MenuItem>
          ]
        ) : (
          props.children
        )}
      </Select>
      {meta.touched && meta.error ? (
        <Typography color="error" variant="body2">{meta.error}</Typography>
      ) : null}
    </FormControl>
  );
};
const EditQuote = () => {
  const navigate = useNavigate(); 
  const { quoteId } = useParams();
  const { quote,refetch} = useGetQuoteById(quoteId);
  const [updateQuote] = useUpdateQuoteMutation();
  const [deleteQuoteFile] = useDeleteQuoteFileMutation();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [heatingType, setHeatingType] = useState(quote?.heatingType || '');

  // const [offerLetterFile, setOfferLetterFile] = useState(null);
  // const [sitePlanFiles, setSitePlanFiles] = useState([]);

 // const [offerLetterFilestate, setOfferLetterFile] = useState(quote?.offerLetterFile || null);
  const [offerLetterFilestate, setOfferLetterFile] = useState(null);
// const [sitePlanFiles, setSitePlanFiles] = useState(quote?.sitePlanFiles || []);
const [sitePlanFiles, setSitePlanFiles] = useState([]);
  const initialValues = {
    name:quote?.name || "",
    email:quote?.email || "",
    contactNo:quote?.contactNo || "",
    companyName:quote?.companyName || "",
    DNO: quote?.DNO || "",
    GridReference: quote?.GridReference || "",
    totalSiteLoad: quote?.totalSiteLoad || "",
    expectedDate: quote?.expectedDate ? dayjs(quote?.expectedDate).format('YYYY-MM-DD') : "",
    quoteDeadline: quote?.quoteDeadline ? dayjs(quote?.quoteDeadline).format('YYYY-MM-DD') : "",
    status: quote?.status || "",
    services: quote?.services || "",
    worksOffer: quote?.worksOffer || false,
    offerLetterFile: quote?.offerLetterFile || null,
    //offerLetterFile: null,  // Initially set to null
  // sitePlanFiles: [],      // Initially set to empty array
    sitePlanFiles: quote?.sitePlanFiles || [],
    fullAddress: quote?.fullAddress || "",
    siteAddress: quote?.siteAddress || "",
    heatingType: quote?.heatingType || "",
    customHeatingType: quote?.heatingType === "5.Other" ? quote?.customHeatingType || "" : "",
    plots: quote?.plots || [{ plotType: "", plotName: "", units: "", loadPerPlot: "" }],
    postalCode: quote?.postalCode || "",
    specialNote: quote?.specialNote || ""
  };
  // const handleSubmit = async (values) => {
  //   console.log("Form Values:", values);
  //   try {
  //     const payload = new FormData();
  //     for (const key in values) {
  //       if (key === "plots") {
  //         payload.append(key, JSON.stringify(values[key]));
  //       } else if (key === "offerLetterFile") {
  //         if (values.offerLetterFile) {
  //           payload.append(key, values.offerLetterFile);
  //         }
  //       } else if (key === "sitePlanFiles") {
  //         values.sitePlanFiles.forEach((file, index) => {
  //           if (file instanceof File) {
  //             payload.append(`sitePlanFiles[${index}]`, file);
  //           }
  //         });
  //       } else if (key === "customHeatingType") {
  //         if (values.heatingType === "5.Other" && values.customHeatingType) {
  //           payload.append(key, values.customHeatingType);
  //         }
  //       } else if (key === "worksOffer") {
  //         payload.append(key, values[key] ? "yes" : "no"); // Convert boolean to string
  //       } else {
  //         payload.append(key, values[key]);
  //       }
  //     }
  
  //     await updateQuote({ quoteId, payload }).unwrap();
  //     notification.success("Quote updated successfully!");
  //   } catch (err) {
  //     console.error("Error updating quote:", err);
  //     notification.error("Failed to update quote. Please try again.");
  //   }
  // };
  
  const handleSubmit = async (values, {resetForm}) => {
    console.log("Form Values:", values);
    try {
      const payload = new FormData();

      for (const key in values) {
        if (key === "plots") {
          payload.append(key, JSON.stringify(values[key]));
        } else if (key === "offerLetterFile") {
          // Include the existing offer letter if no new file is selected
          if (values.offerLetterFile instanceof File) {
            payload.append(key, values.offerLetterFile);
          } 
          else if (values.offerLetterFile) {
            payload.append(key, values.offerLetterFile); // Preserve existing URL
          }
        } else if (key === "sitePlanFiles") {
          // Preserve existing site plan files and include new ones
          values.sitePlanFiles.forEach((file, index) => {
            if (file instanceof File) {
              payload.append(`sitePlanFiles[${index}]`, file);
            } else if (file) {
              payload.append(`sitePlanFiles[${index}]`, file); // Preserve existing URL
            }
          });
        } else if (key === "customHeatingType") {
          if (values.heatingType === "5.Other" && values.customHeatingType) {
            payload.append(key, values.customHeatingType);
          }
        } else if (key === "worksOffer") {
          payload.append(key, values[key] ? "yes" : "no");
        } else {
          payload.append(key, values[key]);
        }
      }
      // console.log("Payload of form",values.offerLetterFile);
      // setOfferLetterFile(null);
      // console.log("Offer letter file",offerLetterFilestate);
      // const response =  await updateQuote({ quoteId, payload }).unwrap();
      // setOfferLetterFile(null);
      // console.log(response)

      // refetch();
      // notification.success("Quote updated successfully!");
      // if (response?.success) {
      //   notification?.success(response?.message);
      //   refetch();
      // } else {
      //   notification?.error(response?.message);
      // }
     await updateQuote({ quoteId, payload }).unwrap();
    
     setOfferLetterFile(null);
     setSitePlanFiles([]);
      // console.log("Offer letter file",response);
      console.log("Offer letter file",offerLetterFilestate);
      // resetForm();
      // refetch();
      //navigate("/admin/quote"); 
       //resetForm();
     
      // console.log("Offer letter file",offerLetterFilestate)
      // setSitePlanFiles(values.sitePlanFiles);
      // console.log("Site Plan file",sitePlanFiles);

      // if (response?.success) {
      //   notification?.success(response?.message);
      //   resetForm(); 
      
      // setOfferLetterFile(null);
      // console.log("Offer letter file",offerLetterFilestate)
      // setSitePlanFiles(values.sitePlanFiles);
      // console.log("Site Plan file",sitePlanFiles);

      // // Refetch data and reset form state
      // refetch();
      // } else {
      //   notification?.error(response?.message);
      // }
      refetch()
    } catch (err) {
      console.error("Error updating quote:", err);
      notification.error("Failed to update quote. Please try again.");
    }
  };
  // const handleSubmit = async (values) => {
  //   console.log("Form Values before submission:", values);  // Debugging step
  //   try {
  //     const payload = new FormData();
  
  //     // Append non-file fields
  //     for (const key in values) {
  //       if (key === "plots") {
  //         payload.append(key, JSON.stringify(values[key]));
  //       } else if (key === "worksOffer") {
  //         payload.append(key, values[key] ? "yes" : "no");
  //       } else {
  //         payload.append(key, values[key]);
  //       }
  //     }
  //     // Handle offerLetterFile
  //     if (values.offerLetterFile instanceof File) {
  //       payload.append("offerLetterFile", values.offerLetterFile);
  //     } else if (values.offerLetterFile) {
  //       payload.append("offerLetterFile", values.offerLetterFile); // Preserve existing URL
  //     }
  //     // Handle sitePlanFiles
  //     values.sitePlanFiles.forEach((file, index) => {
  //       if (file instanceof File) {
  //         payload.append(`sitePlanFiles[${index}]`, file);
  //       } else if (file) {
  //         payload.append(`sitePlanFiles[${index}]`, file); // Preserve existing URL
  //       }
  //     });
  
  //     await updateQuote({ quoteId, payload }).unwrap();
  //     notification.success("Quote updated successfully!");
  //   } catch (err) {
  //     console.error("Error updating quote:", err);
  //     notification.error("Failed to update quote. Please try again.");
  //   }
  // };


  const handleRemoveFile = async (fileIndex, fileField) => {
    try {
      // const updatedFiles = [fileField].map((file, index) =>
      //   index === fileIndex ? null : file
      // );
      // console.log(updatedFiles)

        // Trigger the deleteQuoteFile mutation
        const response = await deleteQuoteFile({
        quoteId: quoteId, // Replace with the actual quoteId from your form values
        fileField,
        fileIndex
      }).unwrap();
      //refetch();
      if (response?.success) {
        notification?.success(response?.message);
        // refetch();
      } else {
        notification?.error(response?.message);
      }
      if (fileField === 'offerLetterFile') {
        console.log(fileIndex,fileField);
        setOfferLetterFile(null);
        console.log(offerLetterFilestate)
      } else if (fileField === 'sitePlanFiles') {
        const updatedFiles = sitePlanFiles.filter((_, index) => index !== fileIndex);
        console.log(fileIndex,fileField);
        setSitePlanFiles(updatedFiles);
      }
 refetch()

      // Trigger the deleteQuoteFile mutation
      // await deleteQuoteFile({
      //   quoteId: quoteId, // Replace with the actual quoteId from your form values
      //   fileField,
      //   fileIndex
      // }).unwrap();

      // Clear the file in Formik and your local state
      // const updatedFiles = [fileField].map((file, index) =>
      //   index === fileIndex ? null : file
      // );
      //setFieldValue(fileField, updatedFiles);
    } catch (error) {
      alert(`File deletion failed: ${error.data?.message || error.status || "Please try again later."}`);
     // console.error('File deletion failed:', error);
    }
  };

  const fileDisplay = (files) =>
    files.map((file, index) => {
      if (file && file.name) {
        return (
          <Typography key={index}>
            {uploadFileFormat(file.name)} - {Math.round(file.size / 1024)} KB
          </Typography>
        );
      }
      return null;
    }).filter(Boolean);
  
  const validationSchema = yup.object({
     });
  return (
    <Formik
  initialValues={initialValues}
  validationSchema={validationSchema}
  onSubmit={handleSubmit}
  enableReinitialize
>
      {({ setFieldValue, values,errors,touched,isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
              <Field
                name="name"
                label="Name"
                as={TextField}
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="email"
                label="Email"
                as={TextField}
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="contactNo"
                label="Contact No"
                as={TextField}
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="companyName"
                label="Company Name"
                as={TextField}
                fullWidth
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="DNO"
                label="DNO"
                as={TextField}
                fullWidth
                //required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name="GridReference"
                label="Grid Reference"
                as={TextField}
                fullWidth
                // required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name="totalSiteLoad"
                label="Total Site Load"
                as={TextField}
                type="number"
                fullWidth
                // required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name="expectedDate"
                label="Expected Date"
                as={TextField}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                name="quoteDeadline"
                label="Quote Deadline"
                as={TextField}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="siteAddress"
                label="Site Address"
                as={TextField}
                fullWidth
                required
              />
            </Grid>
                 
<Grid item xs={12} sm={6} >
<InputLabel >Works Offer</InputLabel>
  <MySelect
    name="worksOffer"
    //label="Works Offer"
    placeholder="Select Works Offer"
    isBoolean={true} // This ensures that it handles boolean values
    value={values.worksOffer}
    onChange={(e) => setFieldValue("worksOffer", e.target.value)}
  />
</Grid>
           <Grid item xs={12} sm={6}>
           <InputLabel >Status</InputLabel>
              <MySelect
                name="status"
                // label="Status"
                placeholder="Select Status"
              >
                {statusTypes.map(status => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </MySelect>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="fullAddress"
                label="Full Address"
                as={TextField}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="postalCode"
                label="Postal Code"
                as={TextField}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
            <FieldArray name="plots">
  {({ push, remove }) => (
    <>
      {values.plots.map((plot, index) => (
        <React.Fragment key={index}>
            <Typography>Select a plot type</Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}  md={3}>
              <MySelect
                placeholder="Select a plot type..."
                name={`plots[${index}].plotType`}
                onChange={(e) => {
                  setFieldValue(`plots[${index}].plotType`, e.target.value);
                  if (e.target.value !== "Other") {
                    setFieldValue(`plots[${index}].plotName`, '');
                  }
                }}
               
                fullWidth
              >
                {PlotTypes.map((type, idx) => (
                  <MenuItem key={idx} value={type}>{type}</MenuItem>
                ))}
              </MySelect>
            </Grid>

            {plot.plotType === "Other" && (
              <Grid item xs={12} sm={4} md={3} >
                <Field
                  name={`plots[${index}].plotName`}
                  label="Plot Name"
                  as={TextField}
                  fullWidth
                  required
                />
              </Grid>
            )}
            <Grid item xs={12} sm={4} md={3}>
              <Field
                name={`plots[${index}].units`}
                label="Units"
                as={TextField}
                type="number"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2} >
              <Field
                name={`plots[${index}].loadPerPlot`}
                label="Load per Plot (KVA)"
                as={TextField}
                type="number"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Button
              // sx={{lineHeight:"2.72", minWidth:"15%" , margin:"2px 2px 25px 0px"}}

              sx={{
                // lineHeight: "2.7", 
                 //minWidth: "5%", 
                // margin:"2px 2px 25px 0px",
                // ml: 2, // Adjust margin-left to separate the button from the link
                //padding: "6px 12px", // Adjust padding for better appearance
                backgroundColor: '#d32f2f', // Button background color (red)
                '&:hover': {
                  backgroundColor: '#c62828', // Darker red on hover
                }
              }}
                variant="contained"
                color="secondary"
                
                onClick={() => remove(index)}
              >
                 <DeleteIcon />
              </Button>
            </Grid>
            
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <Button
         sx={{lineHeight:"2.7", minWidth:"15%"}}
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => push({ plotType: "", plotName: "", units: "", loadPerPlot: "" })}
        >
          Add Plot
        </Button>
      </Grid>
    </>
  )}
</FieldArray>
</Grid>

{/* Heating Type Selection */}
<Grid item xs={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Types of Heating</FormLabel>
            <RadioGroup
              row
              name="heatingType"
              value={values.heatingType}
              onChange={(event) => {
                setFieldValue("heatingType", event.target.value);
                setHeatingType(event.target.value); // Save the selected heating type in state
              }}
            >
              <FormControlLabel
                value="1.Electric"
                control={<Radio />}
                label="Electric"
              />
              <FormControlLabel
                value="2.Gas"
                control={<Radio />}
                label="Gas"
              />
              <FormControlLabel
                value="3.ASHP"
                control={<Radio />}
                label="ASHP"
              />
              <FormControlLabel
                value="4.District_Heating"
                control={<Radio />}
                label="District Heating"
              />
              <FormControlLabel
                value="5.Other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* Custom Heating Type Input */}
        {values.heatingType === "5.Other" && (
          <Grid item xs={12} sm={6}>
            <Field name="customHeatingType">
              {({ field, meta }) => (
                <TextField
                  required
                  id="customHeatingType"
                  label="Please enter type of heating."
                  fullWidth
                  value={field.value || ""} // Ensure the field value is not undefined
                  onChange={(event) =>
                    setFieldValue(
                      "customHeatingType",
                      event.target.value
                    )
                  }
                  InputLabelProps={{ shrink: true }}
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched ? meta.error : ""}
                  {...field}
                />
              )}
            </Field>
          </Grid>
        )}
            
           
          
 {/* Services Selection */}
 <Grid item xs={12} sx={{ p: 2 }}>
                    <FormControl component="fieldset" fullWidth>
                       <FormLabel component="legend">Which services do you need?</FormLabel>
                      <RadioGroup
                        row
                        name="services"
                        value={values.services}
                        onChange={(event) => setFieldValue("services", event.target.value)}
                      >
                        <FormControlLabel
                          value="1.Electricity_Connections"
                          control={<Radio />}
                          label="Electricity connections"
                        />
                        <FormControlLabel
                          value="2.Gas_Connections"
                          control={<Radio />}
                          label="Gas connections"
                        />
                        <FormControlLabel
                          value="3.Water_Services"
                          control={<Radio />}
                          label="Water services"
                        />
                        <FormControlLabel
                          value="4.Fiber_Services"
                          control={<Radio />}
                          label="Fiber services"
                        />
                      </RadioGroup>
                      {touched.services && errors.services && (
                        <Typography color="error" variant="caption">
                          {errors.services}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
            {/* <Grid item xs={12} sm={6} sx={{ p: 2 }}>
              <Typography variant="subtitle1">Current Offer Letter File:</Typography>
              {values.offerLetterFile ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <a
                    href={values.offerLetterFile instanceof File ? URL.createObjectURL(values.offerLetterFile) : values.offerLetterFile}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {values.offerLetterFile instanceof File ? values.offerLetterFile.name : values.offerLetterFile?.split('/').pop()}
                  </a>
                  <Button
                   sx={{lineHeight:"2", minWidth:"15%" , margin:"2px 2px 20px 0px"}}
                    // variant="outlined"
                    color="secondary"
                    // size="small"
                    variant="contained"
                // color="secondary"
                    onClick={() => {
                      setFieldValue('offerLetterFile', null); // Clear the offer letter file
                      setOfferLetterFile(null); // Update state
                    }}
                    // sx={{ ml: 2 }}
                  >
                    Remove
                  </Button>
                 
             
               



                </div>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No offer letter file available.
                </Typography>
              )}
              <TextField
                type="file"
                name="offerLetterFile"
                fullWidth
                onChange={(event) => {
                  const file = event.currentTarget.files[0];
                  setFieldValue('offerLetterFile', file); // Set the selected file for the offer letter
                  setOfferLetterFile(file); // Update state
                }}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} sx={{ p: 2 }}>
  <Typography variant="subtitle1">Current Offer Letter File:</Typography>
  {values.offerLetterFile ? (
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 2,  // Adjust margin bottom as needed
        // border: '1px solid black', // Add black border
        // borderRadius: '2px', // Optional: add border radius for a smoother appearance
      }}
    >
      <Button
        // href={values.offerLetterFile instanceof File ? URL.createObjectURL(values.offerLetterFile) : values.offerLetterFile}
        href={quote.offerLetterFile}
        target="_blank"
         variant="contained"
                  color="primary"
        //rel="noopener noreferrer"
        //style={{ textDecoration: 'none', color: '#1976d2' }} // Style link if needed
        // sx={{ textTransform: "none", mt: 1 }}
      >
      Offer Letter File
        {/* {values.offerLetterFile instanceof File ? values.offerLetterFile.name : values.offerLetterFile?.split('/').pop()} */}
      </Button>
      <Button
        sx={{
          lineHeight: "2", 
          minWidth: "15%", 
          ml: 2, // Adjust margin-left to separate the button from the link
          padding: "6px 12px", // Adjust padding for better appearance
          backgroundColor: '#d32f2f', // Button background color (red)
          '&:hover': {
            backgroundColor: '#c62828', // Darker red on hover
          },
        
        }}
        variant="contained"
        color="secondary"
        // onClick={() => {
        //   setFieldValue('offerLetterFile', null); // Clear the offer letter file
        //   setOfferLetterFile(null); // Update state
        // }}
        onClick={() => handleRemoveFile(null, 'offerLetterFile')}
      >
         <DeleteIcon />
      </Button>
    </Box>
  ) : (
    <Typography variant="body2" color="textSecondary">
      No offer letter file available.
    </Typography>
  )}
  <Typography variant="subtitle1">
         Update the offer letter file.
      </Typography>
  <Button
   variant="contained"
        component="label"
  >
    Choose File
  <input
    type="file"
    hidden
    name="offerLetterFile"
    // fullWidth
    onChange={(event) => {
      const file = event.currentTarget.files[0];
      setOfferLetterFile(file.name);
      setFieldValue('offerLetterFile', file); // Set the selected file for the offer letter
    //  setOfferLetterFile(file.name); // Update state
    // setOfferLetterFile(file ? file.name : null);
    }}
  />
   {offerLetterFilestate && (
        <Typography sx={{ ml: 2 }}>
          {uploadFileFormat(offerLetterFilestate)}
        </Typography>
      )}
      </Button>
      <Grid item sx={{ mt: 2 }}>
              <Field
                name="specialNote"
                label="Special Note"
                as={TextField}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
</Grid>


            {/* Site Plan Files Section */}
            {/* <Grid item xs={12} sm={6} sx={{ p: 2 }}>
              <Typography variant="subtitle1">Current Site Plan Files:</Typography>
              {Array.isArray(values.sitePlanFiles) && values.sitePlanFiles.length > 0 ? (
                values.sitePlanFiles.map((file, index) => (
                  // <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                  <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 0.6,  // Adjust margin bottom as needed
  border:"1px solid black",
          borderRadius:"2px"
      }}
    >
                    {file && (
                      <>
                        <a
                          href={file instanceof File ? URL.createObjectURL(file) : file}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none', color: '#1976d2' }} // Style link if needed
                        >
                          {file instanceof File ? file.name : file?.split('/').pop()}
                        </a>
                        <Button
                         sx={{
                          lineHeight: "2", 
                          minWidth: "15%", 
                          ml: 2, // Adjust margin-left to separate the button from the link
                          padding: "6px 12px", // Adjust padding for better appearance
                          backgroundColor: '#d32f2f', // Button background color (red)
                          '&:hover': {
                            backgroundColor: '#c62828', // Darker red on hover
                          }
                        }}
                          variant="contained"
                          color="secondary"
                          // size="small"
                          // onClick={() => {
                          //   const updatedFiles = values.sitePlanFiles.map((f, i) =>
                          //     i === index ? null : f // Set specific file to null
                          //   );
                          //   setFieldValue('sitePlanFiles', updatedFiles);
                          //   setSitePlanFiles(updatedFiles); // Update state
                          // }}
                          onClick={() => handleRemoveFile(index, 'sitePlanFiles')}
                          
                        >
                          Remove
                        </Button>
                      </>
                    )}
                  </Box>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No site plan files available.
                </Typography>
              )}
              <TextField
                type="file"
                name="sitePlanFiles"
                fullWidth
                inputProps={{ multiple: true }}
                onChange={(event) => {
                  const files = Array.from(event.currentTarget.files);
                  const updatedFiles = values.sitePlanFiles.filter(file => file !== null).concat(files);
                  setFieldValue('sitePlanFiles', updatedFiles);
                  setSitePlanFiles(updatedFiles); // Update state
                }}
              />


            </Grid> */}

            {/* <Grid item xs={12} sm={6} sx={{ p: 2 }}>
  <Typography variant="subtitle1">Current Site Plan Files:</Typography>
  {Array.isArray(values.sitePlanFiles) && values.sitePlanFiles.length > 0 ? (
    values.sitePlanFiles.map((file, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 0.6,
          border: "1px solid black",
          borderRadius: "2px"
        }}
      >
        {file ? (
          <>
            <a
              href={file instanceof File ? URL.createObjectURL(file) : file}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#1976d2' }}
            >
              {file instanceof File ? file.name : file?.split('/').pop()}
            </a>
            <Button
              sx={{
                lineHeight: "2",
                minWidth: "15%",
                ml: 2,
                padding: "6px 12px",
                backgroundColor: '#d32f2f',
                '&:hover': {
                  backgroundColor: '#c62828',
                }
              }}
              variant="contained"
              color="secondary"
              onClick={() => handleRemoveFile(index, 'sitePlanFiles')}
            >
              Remove
            </Button>
          </>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No file at this index.
          </Typography>
        )}
      </Box>
    ))
  ) : (
    <Typography variant="body2" color="textSecondary">
      No site plan files available.
    </Typography>
  )}
  <TextField
    type="file"
    name="sitePlanFiles"
    fullWidth
    inputProps={{ multiple: true }}
    onChange={(event) => {
      const files = Array.from(event.currentTarget.files);
      const updatedFiles = values.sitePlanFiles.filter(file => file !== null).concat(files);
      setFieldValue('sitePlanFiles', updatedFiles);
      setSitePlanFiles(updatedFiles); // Update state
    }}
  />
</Grid> */}


<Grid item xs={12} sm={6} sx={{ p: 2 }}>
  <Typography variant="subtitle1">Current Site Plan Files:</Typography>
  {Array.isArray(values.sitePlanFiles) && values.sitePlanFiles.length > 0 ? (
    values.sitePlanFiles.map((file, index) => (
      <Grid item container
        key={index} direction="column"
        sx={{
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'space-between',
          mb: 0.6,
          // border: "1px solid black",
          // borderRadius: "2px"
        }}
      >
      
        {file ? (
          <>
           <Grid  container justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
             <Button
        // href={values.offerLetterFile instanceof File ? URL.createObjectURL(values.offerLetterFile) : values.offerLetterFile}
        href={file instanceof File ? URL.createObjectURL(file) : file}
        target="_blank"
         variant="contained"
                  color="primary"
      >
      Site Plan File
     
      </Button>
            <Button
              sx={{
                lineHeight: "2",
                minWidth: "15%",
                mt: 1,
                // padding: "6px 12px",
                backgroundColor: '#d32f2f',
                '&:hover': {
                  backgroundColor: '#c62828',
                }
              }}
              variant="contained"
              color="secondary"
              onClick={() => handleRemoveFile(index, 'sitePlanFiles')}
            >
              <DeleteIcon />
            </Button>
            </Grid>
          </>
        ) : (
        
          <Typography variant="subtitle1">
          No Site Plan file 
      </Typography>
        )}
       
        {/* <Typography variant="subtitle1">
         Update Site Plan File
      </Typography> */}
        <Button
   variant="contained"
        component="label"
       

  >
    Choose File
        <input
          type="file"
          hidden
          name={`sitePlanFiles-${index}`}
          // inputProps={{ multiple: false }}
          multiple={false}
          onChange={(event) => {
            const files = Array.from(event.currentTarget.files);
            const updatedFiles = [...values.sitePlanFiles];
            updatedFiles[index] = files[0] || null;
            setFieldValue('sitePlanFiles', updatedFiles);
            setSitePlanFiles(updatedFiles); // Update state
          }}
          sx={{ ml: 2 }} // Adjust margin to separate the file input from other elements
        />
        </Button>
        <Box sx={{ mt: 1 }}>
              {file && fileDisplay([file])}
            </Box>
      </Grid>
    
    ))
  ) : (
    <Typography variant="body2" color="textSecondary">
      No site plan files available.
    </Typography>
  )}
 
</Grid>


{/* <Grid item xs={12} sm={6} sx={{ p: 2 }}>
      <Typography variant="subtitle1">Current Site Plan Files:</Typography>
      {Array.isArray(values.sitePlanFiles) && values.sitePlanFiles.length > 0 ? (
        values.sitePlanFiles.map((file, index) => (
          <Grid key={index} container direction="column" sx={{ mb: 2, border: "1px solid black", borderRadius: "2px", p: 1 }}>
            {file ? (
              <>
                <Grid item container justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                  <Button
                    href={file instanceof File ? URL.createObjectURL(file) : file}
                    target="_blank"
                    variant="contained"
                    color="primary"
                  >
                    Site Plan File
                  </Button>
                  <Button
                    sx={{
                      lineHeight: "2",
                      minWidth: "15%",
                      padding: "6px 12px",
                      backgroundColor: '#d32f2f',
                      '&:hover': { backgroundColor: '#c62828' }
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRemoveFile(index, 'sitePlanFiles')}
                  >
                    Remove
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    component="label"
                    // fullWidth
                  >
                    Choose File
                    <input
                      type="file"
                      hidden
                      name={`sitePlanFiles-${index}`}
                      onChange={(event) => {
                        const files = Array.from(event.currentTarget.files);
                        const updatedFiles = [...values.sitePlanFiles];
                        updatedFiles[index] = files[0] || null;
                        setFieldValue('sitePlanFiles', updatedFiles);
                        setSitePlanFiles(updatedFiles);
                      }}
                    />
                  </Button>
                  {sitePlanFiles.length > 0 && (
                  <div style={{ marginTop: "10px" }}>
                    {fileDisplay(sitePlanFiles)}
                  </div>
                )}
                </Grid>
              </>
            ) : (
              <Typography variant="body2" color="textSecondary">
                No file
              </Typography>
            )}
          </Grid>
        ))
      ) : (
        <Typography variant="body2" color="textSecondary">
          No site plan files available.
        </Typography>
      )}
    </Grid> */}






            {/* <Grid item xs={12} sm={6} sx={{ p: 2 }}>
  <Typography variant="subtitle1">Current Offer Letter File:</Typography>
  {values.offerLetterFile ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <a
        href={values.offerLetterFile instanceof File ? URL.createObjectURL(values.offerLetterFile) : values.offerLetterFile}
        target="_blank"
        rel="noopener noreferrer"
      >
        {values.offerLetterFile instanceof File ? values.offerLetterFile.name : values.offerLetterFile?.split('/').pop()}
      </a>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={() => {
          setFieldValue('offerLetterFile', null); // Clear the offer letter file
        }}
        sx={{ ml: 2 }}
      >
        Remove
      </Button>
    </div>
  ) : (
    <Typography variant="body2" color="textSecondary">
      No offer letter file available.
    </Typography>
  )}
  <TextField
    type="file"
    name="offerLetterFile"
    fullWidth
    onChange={(event) => {
      const file = event.currentTarget.files[0];
      setFieldValue('offerLetterFile', file); // Set the selected file for the offer letter
    }}
    error={touched.offerLetterFile && Boolean(errors.offerLetterFile)}
    helperText={touched.offerLetterFile && errors.offerLetterFile}
  />
</Grid>

<Grid item xs={12} sm={6} sx={{ p: 2 }}>
  <Typography variant="subtitle1">Current Site Plan Files:</Typography>
  {Array.isArray(values.sitePlanFiles) && values.sitePlanFiles.length > 0 ? (
    values.sitePlanFiles.map((file, index) => (
      <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
        {file && (
          <>
            <a
              href={file instanceof File ? URL.createObjectURL(file) : file}
              target="_blank"
              rel="noopener noreferrer"
            >
              {file instanceof File ? file.name : file?.split('/').pop()}
            </a>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                const updatedFiles = values.sitePlanFiles.map((f, i) =>
                  i === index ? null : f // Set specific file to null
                );
                setFieldValue('sitePlanFiles', updatedFiles);
              }}
              sx={{ ml: 2 }}
            >
              Remove
            </Button>
          </>
        )}
      </div>
    ))
  ) : (
    <Typography variant="body2" color="textSecondary">
      No site plan files available.
    </Typography>
  )}
  <TextField
    type="file"
    name="sitePlanFiles"
    fullWidth
    inputProps={{ multiple: true }}
    onChange={(event) => {
      const files = Array.from(event.currentTarget.files);
      const updatedFiles = values.sitePlanFiles.filter(file => file !== null).concat(files);
      setFieldValue('sitePlanFiles', updatedFiles);
    }}
    error={touched.sitePlanFiles && Boolean(errors.sitePlanFiles)}
    helperText={touched.sitePlanFiles && errors.sitePlanFiles}
  />
</Grid> */}
  
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Updating..." : "Update Quote"}
              </Button>
            </Grid>
          </Grid>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            message="Quote updated successfully!"
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditQuote;


{/* //................Current Site Plan File................... */}

            {/* Multiple Files Removal */}
          {/* <Grid item xs={12} sm={6} sx={{ p: 2 }}>
            <Typography variant="subtitle1">Current Site Plan Files:</Typography>
            {Array.isArray(values.sitePlanFiles) && values.sitePlanFiles.map((file, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <a
                  href={file instanceof File ? URL.createObjectURL(file) : file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file instanceof File ? file.name : file.split('/').pop()}
                </a>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    const updatedFiles = values.sitePlanFiles.filter((_, i) => i !== index);
                    setFieldValue('sitePlanFiles', updatedFiles);
                  }}
                  sx={{ ml: 2 }}
                >
                  Remove
                </Button>
              </div>
            ))}
            <TextField
              type="file"
              name="sitePlanFiles"
              fullWidth
              inputProps={{ multiple: true }}
              onChange={(event) => {
                const files = Array.from(event.currentTarget.files);
                const updatedFiles = [...values.sitePlanFiles, ...files];
                setFieldValue('sitePlanFiles', updatedFiles);
              }}
              error={touched.sitePlanFiles && Boolean(errors.sitePlanFiles)}
              helperText={touched.sitePlanFiles && errors.sitePlanFiles}
            />
          </Grid> */}