// import { apiSlice } from "../../service/apiSlice";
// export const inquiryApiSlice = apiSlice.injectEndpoints({
//     endpoints: (builder) => ({
//         createInquiry: builder.mutation({
//             query: (payload) => ({
//                 url: '/inquiry',
//                 method: 'POST',
//                 body: payload,
//             }),
//         }),
//         // Add more endpoints as needed
//     }),
//     overrideExisting: false,
// });

// export const {
//     useCreateInquiryMutation,
// } = inquiryApiSlice;

import { apiSlice } from "../../service/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";
const adapter = createEntityAdapter({
  selectId: (inquiry) => inquiry.inquiryId, 
});
const initialState = adapter.getInitialState();
export const inquiryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createInquiry: builder.mutation({
      query: (payload) => ({
        url: "/inquiry",
        method: "POST",
        body: payload,
      }),
    }),
    // Add more endpoints as needed

    //get enquiry
    getAllInquiries: builder.query({
      query: () => `/inquiry/admin/inquiries`,
      validateStatus: (response, result) => {
        return response.status === 200;
      },
      transformResponse: (response) => {
        return response?.data ?? [];
      },
    }),
    getEnquiriesByCompany: builder.query({
      query: () => `/inquiry/company/inquiries`,
      validateStatus: (response, result) => {
        return response.status === 200;
      },
      transformResponse: (response) => {
        return response?.data ?? [];
      },
    }),
    getInquiriesByCustomer: builder.query({
      query: (quoteId) => `/inquiry/customer/${quoteId}`,
      validateStatus: (response, result) => {
        return response.status === 200;
      },
      transformResponse: (response) => {
        // console.log('Raw Response:', response);
        return response?.data ?? [];
      },
    }),
    updateInquiryCustomerStatus: builder.mutation({
      query: (payload) => ({
        url: `/inquiry/update-customer-status`,
        method: "PATCH",
        body: payload,
      }),
    }),
    
    updateInquiryAdminStatus: builder.mutation({
      query: (payload) => ({
        url: `/inquiry/update-admin-status`,
        method: "PATCH",
        body: payload,
      }),
    }),
    updateInquiry:builder.mutation({
      query:({payload})=>({
  url:`/inquiry/update-inquiry`,
  method:"PUT",
  body:payload
      })
    }),
    deleteInquiryFile:builder.mutation({
    query:({inquiryId,fileIndex,fileField})=>({
url:`/inquiry/${inquiryId}/${fileField}/${fileIndex}`,
method:"DELETE"
    }),

    onQueryStarted:async(arg,{queryFulfilled})=>{
      try{
        const result=await queryFulfilled;
        console.log("File Deleted Successfully",result)
      }catch(error){
console.log("File Deletion Failed",error)
      }
    }
}),
getInquiryById: builder.query({
      query: (inquiryId) => `/inquiry/admin/${inquiryId}`,
      validateStatus: (response, result) => {
        return response.status === 200;
      },
      transformResponse: (response) => {
        
        // Ensure only the quote data is processed, not the entire response object
        if (response && response.data) {
          return adapter.setAll(initialState, [response.data]);
        }
        return initialState; // Return empty initialState if no data
      },
    }),
    deleteInquiry: builder.mutation({
      query: ({ inquiryId }) => ({
        url: `/inquiry/${inquiryId}`, // Use quoteId in the URL
        method: "DELETE",
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const result = await queryFulfilled;
          console.log("Delete successful", result);
        } catch (error) {
          console.error("Delete failed", error);
        }
      },
    }),
  }),
  


 
  overrideExisting: false,
});

export const {
  useCreateInquiryMutation,
  useGetEnquiriesByCompanyQuery,
  useGetAllInquiriesQuery,
  useGetInquiriesByCustomerQuery,
  useUpdateInquiryCustomerStatusMutation,
  useUpdateInquiryAdminStatusMutation,
  useDeleteInquiryMutation,
  useDeleteInquiryFileMutation,
  useGetInquiryByIdQuery,
  useUpdateInquiryMutation,
} = inquiryApiSlice;

// import { apiSlice } from "../../service/apiSlice";

// export const inquiryApiSlice = apiSlice.injectEndpoints({
//   endpoints: (builder) => ({
//     createInquiry: builder.mutation({
//       query: (payload) => ({
//         url: '/inquiry',
//         method: 'POST',
//         body: payload,
//       }),
//     }),

//     getAllInquiries: builder.query({
//       query: ({ page = 1, limit = 10 }) => ({
//         url: /inquiry/admin/inquiries,
//         params: { page, limit },
//       }),
//       validateStatus: (response, result) => {
//         return response.status === 200;
//       },
//       transformResponse: (response) => {
//         return {
//           data: response.data ?? [],
//           total: response.total ?? 0,
//         };
//       },
//     }),
//   }),
//   overrideExisting: false,
// });

// export const {
//   useCreateInquiryMutation,
//   useGetAllInquiriesQuery,
// } = inquiryApiSlice;
