// import React, { useEffect, useState } from "react";
// import {
//   Table,
//   TableBody,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
//   TableCell,
//   Tooltip,
//   TablePagination,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import useAllInquiries from "../../../hook/routing/useGetAllInquiries";
// import {
//   globalStyle,
// } from "../../../constant/globalStyle";
// import EnquiryViewAdmin from "./EnquiryViewAdmin";

// const columns = [
//     { field: "inquiryId", headerName: "Enquiry ID", width: 130 },
//     { field: "offerExpiryDate", headerName: "Offer Expiry Date", width: 130 },
//     { field: "totalPrice", headerName: "Total Price", width: 130 },
//     { field: "adminStatus", headerName: "Admin Status", width: 130 },
//     { field: "customerStatus", headerName: "Customer Status", width: 130 },
//     { field: "action", headerName: "Actions", width: 130 },
//   ];

// const AdminEnquiryList = () => {
//   const { array, isLoading, refetch } = useAllInquiries();
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   const openAlertBox = async ({ title, message, confirmButtonText, cancelButtonText, onConfirm }) => {
//     if (window.confirm(message)) {
//       await onConfirm();
//     }
//   };

//   const deleteUser = async (user) => {
//     console.log("Deleting user:", user);
//   };
//   const handleDelete = async (object) => {
//     try {
//       await openAlertBox({
//         title: "Confirmation",
//         message: "Are you sure you want to permanently delete this record?",
//         confirmButtonText: "Delete",
//         cancelButtonText: "Cancel",
//         onConfirm: () => deleteUser(object),
//       });
//     } catch (error) {
//       console.error("Error during deletion:", error);
//     }
//   };

//   const handleEdit = (object) => {
//     console.log("Editing quote:", object);
//     // Add your logic to open the edit form or navigate to the edit page
//   };

//   useEffect(() => {
//     refetch();
//   }, [refetch]);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   return (
//     <>
// {/* <AdminAppbar/>
// <AdminSidebar/> */}

//       <Paper elevation={2} sx={{ borderRadius: "15px",my:2, mx: 4 }}>
//         <TableContainer>
//           <Table aria-label="simple table">
//             <TableHead>
//               <TableRow>
//                 {columns.map((column) => (
//                   <TableCell
//                     key={column.field}
//                     align="left"
//                     sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
//                   >
//                     {column.headerName}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>

//             <TableBody>
//               {isLoading ? (
//                 <TableRow>
//                   <TableCell colSpan={columns.length}>Loading...</TableCell>
//                 </TableRow>
//               ) : (
//                 array
//                   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                   .map((row) => (
//                     <TableRow key={row.inquiryId}>
//                       <TableCell>{row.inquiryId}</TableCell>
//                       <TableCell>{row.offerExpiryDate}</TableCell>
//                       <TableCell>{row.totalPrice}</TableCell>
//                       <TableCell>{row.adminStatus}</TableCell>
//                       <TableCell>{row.customerStatus}</TableCell>
//                       <TableCell>
//                       <Tooltip title="Edit">
//                         <IconButton
//                           color="primary"
//                           onClick={() => handleEdit(row)}
//                         >
//                           <EditIcon />
//                         </IconButton>
//                       </Tooltip>
//                         <Tooltip title="Delete">
//                           <IconButton
//                             color="error"
//                             onClick={() => handleDelete(row)}
//                           >
//                             <DeleteIcon />
//                           </IconButton>
//                         </Tooltip>
//                         <EnquiryViewAdmin props={row} />
//                       </TableCell>

//                     </TableRow>
//                   ))
//               )}
//             </TableBody>
//           </Table>
//           <TablePagination
//             rowsPerPageOptions={[5, 10, 25]}
//             component="div"
//             count={array.length}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </TableContainer>
//       </Paper>
//     </>
//   );
// };

// export default AdminEnquiryList;

import React, { useEffect } from "react";
import { DataGrid, GridToolbar, useGridApiContext } from "@mui/x-data-grid";
import {
  Paper,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
// import AddIcon from "@mui/icons-material/Add";
import DataGridHeader from "../../../component/data-grid/DataGridHeader";
import CustomNoRowsOverlay from "../../../component/data-grid/CustomNoRowsOverlay";
import { useAlertDialogSlide } from "../../../component/alert-dialog/AlertDialogProvider";
import { useDeleteInquiryMutation } from "../../../redux/reducer/api/inquiryApiSlice";
import { useUpdateInquiryAdminStatusMutation } from "../../../redux/reducer/api/inquiryApiSlice";
import useAllInquiries from "../../../hook/routing/useGetAllInquiries";
import { deleteInquiryMutation } from "../../../util/helper-function";
import EnquiryViewAdmin from "./EnquiryViewAdmin";
import EnquiryEditAdmin from "./EnquiryEditAdmin";
// import { useNavigate } from "react-router-dom";

const AdminEnquiryList = () => {
  // const navigate = useNavigate();
  const [pageSize, setPageSize] = React.useState(10);
  const { array, refetch, isLoading } = useAllInquiries();
  const { open: openAlertBox } = useAlertDialogSlide();
  const [deleteInquiry] = useDeleteInquiryMutation();
  const [updateInquiryAdminStatus] = useUpdateInquiryAdminStatusMutation();

  useEffect(() => {
    refetch().catch((error) => {
      console.error("Error fetching data:", error);
    });
  }, [refetch]);

  const handleDelete = async (row) => {
    const { inquiryId } = row;
    try {
      await openAlertBox({
        title: "Confirmation",
        message: `Are you sure you want to permanently delete ${inquiryId}?`,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        onConfirm: () =>
          deleteInquiryMutation({ inquiryId }, deleteInquiry, refetch),
      });
    } catch (err) {
      console.error("Dialog was closed without confirmation");
    }
  };

  const handleStatusChange = async (id, field, value) => {
    try {
      await updateInquiryAdminStatus({
        inquiryId: id,
        adminStatus: value,
      }).unwrap();
      refetch();
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };

  const handleEditEnquiry = (object) => {
    console.log("Editing quote:", object);
    // Add your logic to open the edit form or navigate to the edit page
  };

  // const handleQuote = () => {
  //   navigate("/customer/create-quote");
  // };

  const SelectEditInputCell = (props) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      const newValue = event.target.value;
      await apiRef.current.setEditCellValue({ id, field, value: newValue });
      apiRef.current.stopCellEditMode({ id, field });
      handleStatusChange(id, field, newValue);
    };

    return (
      <Select
        value={value}
        onChange={handleChange}
        size="small"
        sx={{ height: 1 }}
        native
        autoFocus
      >
        <option value="Pending">Pending</option>
        <option value="Approved">Approved</option>
        <option value="Declined">Declined</option>
      </Select>
    );
  };

  const renderSelectEditInputCell = (params) => {
    return <SelectEditInputCell {...params} />;
  };

  return (
    <Paper elevation={2} sx={{ borderRadius: "15px" }}>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: "#1976d2",
            '&:hover': {
              backgroundColor: "#1565c0",
            }
          }}
          onClick={handleQuote} 
        >
          Add Quote
        </Button>
      </Box> */}
      <DataGrid
        autoHeight
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        rows={array}
        loading={isLoading}
        getRowId={(row) => row.inquiryId}
        slots={{
          toolbar: GridToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        columns={[
          {
            field: "inquiryId",
            flex: 1,
            hide: true,
            renderHeader: () => <DataGridHeader header={"Inquiry Id"} />,
            headerAlign: "center",
            align: "center",
          },
          {
            field: "offerExpiryDate",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Offer Expiry Date"} />,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              // Check if the offerExpiryDate exists and format it
              const offerExpiryDate = params.row?.offerExpiryDate
                ? new Date(params.row.offerExpiryDate)
                    .toISOString()
                    .split("T")[0]
                : ""; // Fallback if offerExpiryDate is missing

              return offerExpiryDate;
            },
          },
          {
            field: "totalPrice",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Total Price"} />,
            headerAlign: "center",
            align: "center",
          },
          {
            field: "adminStatus",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Admin Status"} />,
            headerAlign: "center",
            align: "center",
            editable: true,
            renderEditCell: renderSelectEditInputCell,
          },
          {
            field: "customerStatus",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Customer Status"} />,
            headerAlign: "center",
            align: "center",
          },
          {
            field: "actions",
            sortable: false,
            filterable: false,
            // hideable: false,
            // hideFil
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Actions"} />,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              return (
                <>
                  <Tooltip title="Delete" sx={{ p: 1 }}>
                    <IconButton
                      color="error"
                      cursor="pointer"
                      onClick={() => handleDelete(params.row)}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>

                  {/* {
                    <Tooltip title="Edit">
                      <IconButton
                        color="primary"
                        onClick={() => handleEditEnquiry(params.row)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  } */}
                  {/* <Tooltip title="Edit">
                  <IconButton>
                  <EnquiryEditAdmin props={params.row} />
                  </IconButton>
                  </Tooltip> */}
                  {<EnquiryEditAdmin props={params.row} />}

                  {<EnquiryViewAdmin props={params.row} />}
                </>
              );
            },
          },
        ]}
      />
    </Paper>
  );
};

export default AdminEnquiryList;
