import { useGetAllQuotesQuery } from "../../redux/reducer/api/quoteApiSlice";
import { queryListener } from "../../constant/object";
const useAllQuotes = (option = queryListener.default) => {
  let array = [];

  const { data, isLoading, isSuccess, refetch } = useGetAllQuotesQuery({},option);

  if (isSuccess) {
    array = data || []; 
  }
//console.log("All Quotes array is :",array)
  return {
    array,
    isLoading,
    refetch,
  };
};

export default useAllQuotes;

// import { useGetAllQuotesQuery } from "../../redux/reducer/api/quoteApiSlice";
// import { queryListener } from "../../constant/object";

// const useAllQuotes = (option = queryListener.default) => {
//   const { data, isLoading, isSuccess, refetch } = useGetAllQuotesQuery(undefined, {
//     pollingInterval: option.pollingInterval, // Set pollingInterval here
//     refetchOnReconnect: option.refetchOnReconnect,
//     refetchOnMountOrArgChange: option.refetchOnMountOrArgChange,
//   });

//   const array = isSuccess ? data || [] : [];

//   return {
//     array,
//     isLoading,
//     refetch,
//   };
// };

// export default useAllQuotes;

