import React from "react";
import { Box, Typography } from "@mui/material";

const Slider = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#003C5F",
        overflow: "hidden",
        p: 2,
        py:15,
      }}
    >
      {/* Add your content here */}
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          // padding: "0 20px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: { xs: "40px", sm: "60px" },
            fontWeight: 700,
            marginBottom: "20px",

            margin: "0 auto",

            lineHeight: { xs: "49px", sm: "81px" },
            color: "white",
          }}
        >
          All-in-One Infrastructure Partner
        </Typography>

        <Typography
          variant="h6"
          sx={{
            fontSize: "35px",
            fontWeight: 500,
            margin: "0 auto",
            lineHeight: "46px",
            color: "white",
          }}
        >
          UK's First Infrastructure and Utility Marketplace
        </Typography>
      </Box>

      
    </Box>
  );
};

export default Slider;
