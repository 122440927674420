// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import Box from "@mui/material/Box";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import Container from "@mui/material/Container";
// import { TextField, Typography, Grid, CircularProgress } from "@mui/material";
// import { useFormik } from 'formik';
// import * as Yup from "yup";
// import { useForgotpasswordOTPMutation, useForgetPasswordMutation } from '../../redux/reducer/api/authApiSlice';

// export default function ForgetPassword() {
//   const navigate = useNavigate();
//   const [forgotpasswordOTP, { isLoading: isOtpLoading }] = useForgotpasswordOTPMutation();
  
//   const [forgetPassword, { isLoading: isResetLoading }] = useForgetPasswordMutation();
//   const [otpRequested, setOtpRequested] = useState(false);

//   const formik = useFormik({
//     initialValues: {
//       email: "",
//       otp: "",
//       newPassword: "",
//       confirmNewPassword: "",
//     },
//     validationSchema: Yup.object({
//       email: Yup.string().email("Invalid email address").required("Email is required"),
//       otp: otpRequested ? Yup.string().required("OTP is required") : Yup.string().nullable(),
//       newPassword: Yup.string()
//         .required("New password is required")
//         .matches(
//           /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/,
//           "Must contain 6 characters and a special character."
//         ),
//       confirmNewPassword: Yup.string()
//         .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
//         .required("Confirm new password is required"),
//     }),
//     onSubmit: async (values) => {
//       if (!otpRequested) {
//         try {
//           await forgotpasswordOTP({ email: values.email }).unwrap();
//           setOtpRequested(true);
//           alert('OTP sent to your email.');
//         } catch (error) {
//           alert(`Failed to send OTP: ${error.data?.error || "Please try again later."}`);
//         }
//       } else {
//         try {
//           await forgetPassword(values).unwrap();
//           alert('Password reset successfully.');
//           navigate("/login");
//         } catch (error) {
//           alert(`Failed to reset password: ${error.data?.message || "Please try again later."}`);
//         }
//       }
//     }
//   });

//   const handleRequestOtp = async () => {
//     const email = formik.values.email.trim();
//     if (!email) {
//       alert("Please enter a valid email to request OTP.");
//       return;
//     } 
//     try {
//       await forgotpasswordOTP({ email }).unwrap();
//       setOtpRequested(true);
//     } catch (error) {
//       // setErrorMe
//       console.error("Error requesting OTP:", error);
//       alert(`Failed to send OTP: ${error.data?.message || error.status || "Please try again later."}`);
//     }
//   };


//   return (
//     <Container component="main" maxWidth="xs">
//       <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
//         <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
//           <LockOutlinedIcon />
//         </Avatar>
//         <Typography component="h1" variant="h5">
//           Reset Password
//         </Typography>
//         <form onSubmit={formik.handleSubmit}>
//           <Grid container spacing={2}>
            
//  <Grid item xs={8}>
//               <TextField
//                 fullWidth
//                 id="email"
//                 name="email"
//                 label="Email Address"
//                 value={formik.values.email}
//                 onChange={formik.handleChange}
//                 error={formik.touched.email && Boolean(formik.errors.email)}
//                 helperText={formik.touched.email && formik.errors.email}
//               />
//             </Grid>
//             <Grid item xs={4}>
//               <Button onClick={handleRequestOtp} variant="outlined" disabled={otpRequested || isOtpLoading}>
//                 {isOtpLoading ? <CircularProgress size={24} /> : "Request OTP"}
//               </Button>
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 id="otp"
//                 name="otp"
//                 label="OTP"
//                 value={formik.values.otp}
//                 onChange={formik.handleChange}
//                 disabled={!otpRequested}
//                 error={formik.touched.otp && Boolean(formik.errors.otp)}
//                 helperText={formik.touched.otp && formik.errors.otp}
//               />
//             </Grid>

//             {/* End */}

//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 type="password"
//                 id="newPassword"
//                 name="newPassword"
//                 label="New Password"
//                 value={formik.values.newPassword}
//                 onChange={formik.handleChange}
//                 disabled={!otpRequested}
//                 error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
//                 helperText={formik.touched.newPassword && formik.errors.newPassword}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 type="password"
//                 id="confirmNewPassword"
//                 name="confirmNewPassword"
//                 label="Confirm New Password"
//                 value={formik.values.confirmNewPassword}
//                 onChange={formik.handleChange}
//                 disabled={!otpRequested}
//                 error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
//                 helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
//               />
//             </Grid>
//             <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb:2 }} disabled={isOtpLoading || isResetLoading}>
//               {otpRequested ? "Reset Password" : "Request OTP"}
//             </Button>
//           </Grid>
//           <Grid container justifyContent="flex-end">
//             <Grid item>
//               <Link to="/login" variant="body2">
//                 Remember your password? Log in
//               </Link>
//             </Grid>
//           </Grid>
//         </form>
//       </Box>
//     </Container>
//   );
// }


// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import Box from "@mui/material/Box";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import Container from "@mui/material/Container";
// import { TextField, Typography, Grid, CircularProgress } from "@mui/material";
// import { useFormik } from 'formik';
// import * as Yup from "yup";
// import { useForgotpasswordOTPMutation, useForgetPasswordMutation } from '../../redux/reducer/api/authApiSlice';

// export default function ForgetPassword() {
//   const navigate = useNavigate();
//   const [forgotpasswordOTP, { isLoading: isOtpLoading }] = useForgotpasswordOTPMutation();
//   const [forgetPassword, { isLoading: isResetLoading }] = useForgetPasswordMutation();
//   const [otpRequested, setOtpRequested] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [timer, setTimer] = useState(0);

//   const formik = useFormik({
//     initialValues: {
//       email: "",
//       otp: "",
//       newPassword: "",
//       confirmNewPassword: "",
//     },
//     validationSchema: Yup.object({
//       email: Yup.string().email("Invalid email address").required("Email is required"),
//       // otp: otpRequested ? Yup.string().required("OTP is required") : Yup.string().nullable(),
//       otp: Yup.string().required("OTP is required") ,
//       newPassword: Yup.string()
//         .required("New password is required")
//         .matches(
//           /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/,
//           "Must contain 6 characters and a special character."
//         ),
//       confirmNewPassword: Yup.string()
//         .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
//         .required("Confirm new password is required"),
//     }),
//     onSubmit: async (values) => {
//       if (!otpRequested) {
//         try {
//          await forgotpasswordOTP({ email: values.email }).unwrap();
//           setOtpRequested(true);
//           setTimer(60); // 4 minutes and 59 seconds = 299 seconds
//           alert('OTP sent to your email.');
//         } catch (error) {
//           alert(`Failed to send OTP: ${error.data?.error || "Please try again later."}`);
//         }
//       } else {
//         try {
//           await forgetPassword(values).unwrap();
//           alert('Password reset successfully.');
//           navigate("/login");
//         } catch (error) {
//           alert(`Failed to reset password: ${error.data?.message || "Please try again later."}`);
//         }
//       }
//     }
//   });

//   useEffect(() => {
//     let interval;
//     if (timer > 0) {
//       interval = setInterval(() => {
//         setTimer((prev) => prev - 1);
//       }, 1000);
//     } else {
//       clearInterval(interval);
//     }
//     return () => clearInterval(interval);
//   }, [timer]);

//   const handleRequestOtp = async () => {
//     formik.setTouched({
//       email: true,
//     }, true); // Trigger validation for email field

//     if (formik.errors.email) {
//       return; // Exit if email is invalid
//     }
//     const email = formik.values.email.trim();
//     // if (!email) {
//     //   alert("Please enter a valid email to request OTP.");
//     //   return;
//     // }
//     try {
//       await forgotpasswordOTP({ email }).unwrap();
//       setOtpRequested(true);
//       setTimer(60); // 4 minutes and 59 seconds = 299 seconds
//     } catch (error) {
//       console.error("Error requesting OTP:", error);
//       alert(`Failed to send OTP: ${error.data?.message || error.status || "Please try again later."}`);
//     }
//   };

//   const formatTime = (time) => {
//     const minutes = String(Math.floor(time / 60)).padStart(2, '0');
//     const seconds = String(time % 60).padStart(2, '0');
//     return `${minutes}:${seconds}`;
//   };

//   return (
//     <Container component="main" maxWidth="xs">
//       <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
//         <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
//           <LockOutlinedIcon />
//         </Avatar>
//         <Typography component="h1" variant="h5">
//           Reset Password
//         </Typography>
//         <form onSubmit={formik.handleSubmit}>
//           <Grid container spacing={2}>
//             <Grid item xs={8}>
//               <TextField
//                 fullWidth
//                 id="email"
//                 name="email"
//                 label="Email Address"
//                 value={formik.values.email}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 error={formik.touched.email && Boolean(formik.errors.email)}
//                 helperText={formik.touched.email && formik.errors.email}
//               />
//             </Grid>
//             <Grid item xs={4}>
//               <Button
//                 onClick={handleRequestOtp}
//                 variant="outlined"
//                 disabled={isOtpLoading || timer > 0}
//               >
//                 {isOtpLoading ? <CircularProgress size={24} /> : timer > 0 ? `Retry in ${formatTime(timer)}` : "Request OTP"}
//               </Button>
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 id="otp"
//                 name="otp"
//                 label="OTP"
//                 value={formik.values.otp}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 // disabled={!otpRequested}
//                 error={formik.touched.otp && Boolean(formik.errors.otp)}
//                 helperText={formik.touched.otp && formik.errors.otp}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 type="password"
//                 id="newPassword"
//                 name="newPassword"
//                 label="New Password"
//                 value={formik.values.newPassword}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 // disabled={!otpRequested}
//                 error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
//                 helperText={formik.touched.newPassword && formik.errors.newPassword}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 type="password"
//                 id="confirmNewPassword"
//                 name="confirmNewPassword"
//                 label="Confirm New Password"
//                 value={formik.values.confirmNewPassword}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 // disabled={!otpRequested}
//                 error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
//                 helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
//               />
//             </Grid>
//             {/* <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               sx={{ mt: 3, mb: 2 }}
//               disabled={isOtpLoading || isResetLoading}
//             >
//               {otpRequested ? "Reset Password" : "Request OTP"}
//             </Button> */}
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               sx={{ mt: 3, mb: 2 }}
//               disabled={isOtpLoading || isResetLoading}
//             >
//               Reset Password
//             </Button>
//           </Grid>
//           <Grid container justifyContent="flex-end">
//             <Grid item>
//               <Link to="/login" variant="body2">
//                 Remember your password? Log in
//               </Link>
//             </Grid>
//           </Grid>
//         </form>
//       </Box>
//     </Container>
//   );
// }







import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { TextField, Typography, Grid, CircularProgress } from "@mui/material";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useForgotpasswordOTPMutation, useForgetPasswordMutation } from '../../redux/reducer/api/authApiSlice';

export default function ForgetPassword() {
  const navigate = useNavigate();
  const [forgotpasswordOTP, { isLoading: isOtpLoading }] = useForgotpasswordOTPMutation();
  const [forgetPassword, { isLoading: isResetLoading }] = useForgetPasswordMutation();
  // const [otpRequested, setOtpRequested] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  const [timer, setTimer] = useState(0);

  const formik = useFormik({
    initialValues: {
      email: "",
      otp: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Email is required"),
      // otp: otpRequested ? Yup.string().required("OTP is required") : Yup.string().nullable(),
      otp: Yup.string().required("OTP is required") ,
      newPassword: Yup.string()
        .required("New password is required")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/,
          "Must contain 6 characters and a special character."
        ),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm new password is required"),
    }),
    onSubmit: async (values) => {
      // if (!otpRequested) {
      //   try {
      //    await forgotpasswordOTP({ email: values.email }).unwrap();
      //     setOtpRequested(true);
      //     setTimer(60); // 4 minutes and 59 seconds = 299 seconds
      //     alert('OTP sent to your email.');
      //   } catch (error) {
      //     alert(`Failed to send OTP: ${error.data?.error || "Please try again later."}`);
      //   }
      // } 
      //else {
        try {
          await forgetPassword(values).unwrap();
          alert('Password reset successfully.');
          navigate("/login");
        } catch (error) {
          alert(`Failed to reset password: ${error.data?.message || "Please try again later."}`);
        }
      }
      
    //}
  });

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleRequestOtp = async () => {
    // formik.setTouched({
    //   email: true,
    // }, true); // Trigger validation for email field
    formik.setFieldTouched('email', true); // Trigger validation for email field
    //await formik.validateField('email'); // Validate email field

    if (formik.errors.email || !formik.values.email.trim()) {
      console.log('Email validation error:', formik.errors.email); // Debugging line
      return; // Exit if email is invalid
    }

    const email = formik.values.email.trim();
    // if (!email) {
    //  alert("Please enter a valid email to request OTP.");
    //   return;
    // }
    try {
      await forgotpasswordOTP({ email }).unwrap();
      //setOtpRequested(true);
      setTimer(60); // 4 minutes and 59 seconds = 299 seconds
    } catch (error) {
      //console.error("Error requesting OTP:", error);
      alert(`Failed to send OTP: ${error.data?.message || error.status || "Please try again later."}`);
    }
  };

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 13, display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email Address"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={handleRequestOtp}
                variant="outlined"
                disabled={isOtpLoading || timer > 0}
              >
                {isOtpLoading ? <CircularProgress size={24} /> : timer > 0 ? `Retry in ${formatTime(timer)}` : "Request OTP"}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="otp"
                name="otp"
                label="OTP"
                value={formik.values.otp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // disabled={!otpRequested}
                error={formik.touched.otp && Boolean(formik.errors.otp)}
                helperText={formik.touched.otp && formik.errors.otp}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                id="newPassword"
                name="newPassword"
                label="New Password"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // disabled={!otpRequested}
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                helperText={formik.touched.newPassword && formik.errors.newPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                id="confirmNewPassword"
                name="confirmNewPassword"
                label="Confirm New Password"
                value={formik.values.confirmNewPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // disabled={!otpRequested}
                error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                helperText={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
              />
            </Grid>
            {/* <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isOtpLoading || isResetLoading}
            >
              {otpRequested ? "Reset Password" : "Request OTP"}
            </Button> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isOtpLoading || isResetLoading}
            >
              Reset Password
            </Button>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/login" variant="body2">
                Remember your password? Log in
              </Link>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
}








