import { useGetAllInquiriesQuery } from "../../redux/reducer/api/inquiryApiSlice";
import { queryListener } from "../../constant/object";
const useAllInquiries = (option = queryListener.default) => {
  let array = [];

  const { data, isLoading, isSuccess, refetch } = useGetAllInquiriesQuery({},option);

  if (isSuccess) {
    array = data || []; 
  }
//console.log("All Inquiries array is :",array)
  return {
    array,
    isLoading,
    refetch,
  };
};

export default useAllInquiries;