
// import React, { useEffect } from "react";
// import Grid from "@mui/material/Grid";
// // import {
// //     globalModalBtnStyle,
   
// //   } from "../../constant/globalStyle";
  
//   import Button from "@mui/material/Button";

// import {
 
//   Table,
//   TableBody,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
//   TableCell,
//   Tooltip,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// //import { globalStyle } from "../../constant/globalStyle";
// //import { useAlertDialogSlide } from "../../component/alert-dialog/AlertDialogProvider";
// //import { deleteMutation } from "../../util/helper-function";
// //import SummaryEdit from "./SummaryEdit";
// //import useSmartNumberByUserId from "../../hook/smartNumber/useSmartNumberByUserId";
// //import useAllUsers from "../../hook/users/useAllUsers";
// //import { useDeleteUserMutation } from "../../redux/reducer/api/userApiSlice";


// const CustomerQuotes = () => {

//   const { array: users, refetch, isLoading } = useAllUsers();
//   const { open: openAlertBox } = useAlertDialogSlide();
//   const [deleteUser] = useDeleteUserMutation();

//   async function handleDelete(object) {
//     try {
//       await openAlertBox({
//         title: "Confirmation",
//         message: "Are you sure you want to permanently delete this record?",
//         confirmButtonText: "Delete",
//         cancelButtonText: "Cancel",
//         onConfirm: () => deleteMutation(object, deleteUser, refetch),
//       });
//     } catch (error) {
//       console.error("Error during deletion:", error);
//     }
//   }
//   useEffect(() => {
//     refetch();
//   }, [refetch]);

//   return (
//     <>
      

//                   <Grid item xs={12} sm={12} sx={{ p: 2 }}>
                    
//                       <Button
//                         variant="contained"
//                         fullWidth
//                         type="submit"
//                         sx={{
//                           ...globalModalBtnStyle,
//                           width: { xs: "100%", sm: "10%" },
//                           float: "right",
//                         }}
//                         // onClick={() => {
//                         //   console.log("Clicked");
//                         // }}
//                       >
//                         Create
//                       </Button>
                    
//                   </Grid>

//                   <Grid>
//                   <Paper elevation={2} sx={{ borderRadius: "15px", m: 4 }}>
//       <TableContainer>
//         <Table aria-label="User Table">
//           <TableHead>
//             <TableRow>
//               <TableCell
//                 align="left"
//                 sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
//               >
//                 Company Name
//               </TableCell>
//               <TableCell
//                 align="left"
//                 sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
//               >
//                 Company Id
//               </TableCell>
//               <TableCell
//                 align="left"
//                 sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
//               >
//                 Login
//               </TableCell>
//               <TableCell
//                 align="left"
//                 sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
//               >
//                 Actions
//               </TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {isLoading ? (
//               <TableRow>
//                 <TableCell colSpan={4}>Loading...</TableCell>
//               </TableRow>
//             ) : (
//               users.map((user) => (
//                 <TableRow key={user.id}>
//                   <TableCell>{user.organizationName}</TableCell>
//                   <TableCell>{user.organizationId}</TableCell>
//                   <TableCell>{user.username}</TableCell>
//                   <TableCell>
//                     <Tooltip title="Delete">
//                       <IconButton
//                         color="error"
//                         cursor="pointer"
//                         onClick={() => handleDelete(user)}
//                       >
//                         <DeleteIcon />
//                       </IconButton>
//                     </Tooltip>
//                     <SummaryEdit user={user} onCancel={() => {}} />
//                   </TableCell>
//                 </TableRow>
//               ))
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Paper>
//                   </Grid>
                
//     </>
//   );
// };

// export default CustomerQuotes;



import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableCell,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

const CustomerQuotes = () => {
  const { array: users, refetch, isLoading } = { array: [], refetch: () => {}, isLoading: false }; // Mocking hook return values
  const openAlertBox = async ({ title, message, confirmButtonText, cancelButtonText, onConfirm }) => {
    if (window.confirm(message)) {
      await onConfirm();
    }
  };
  const deleteUser = async (user) => {
    console.log("Deleting user:", user);
  };

  async function handleDelete(object) {
    try {
      await openAlertBox({
        title: "Confirmation",
        message: "Are you sure you want to permanently delete this record?",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        onConfirm: () => deleteUser(object),
      });
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  }

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <>
      <Grid item xs={12} sm={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
          variant="contained"
          type="submit"
          sx={{
            width: { xs: "100%", sm: "auto"},
            mx:10,
            my:5,
          }}
        >
          Create
        </Button>
      </Grid>

      <Grid>
        <Paper elevation={2} sx={{ borderRadius: "15px", m: 4 }}>
          <TableContainer>
            <Table aria-label="User Table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                  Quote Id
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                    Services
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                    Plot Type
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                    Actions
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                  4445634
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                    heat
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                    3
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                  <VisibilityIcon />
                  <EditIcon />
                  <DeleteIcon />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={4}>Loading...</TableCell>
                  </TableRow>
                ) : (
                  users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.organizationName}</TableCell>
                      <TableCell>{user.organizationId}</TableCell>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>
                        <Tooltip title="Delete">
                          <IconButton
                            color="error"
                            cursor="pointer"
                            onClick={() => handleDelete(user)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        {/* Replace SummaryEdit with a placeholder */}
                        <div>Edit</div>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </>
  );
};

export default CustomerQuotes;