// export function uploadFileFormat(fileName) {
//     const maxWords = 4;
//     const maxChars = 30;
  
//     // First, trim to maxChars to ensure the filename isn't too long
//     let trimmed = fileName.slice(0, maxChars);
  
//     // Then trim by words, splitting by spaces and rejoining
//     trimmed = trimmed.split(' ').slice(0, maxWords).join(' ');
  
//     // Add ellipsis if the original filename is longer than the trimmed version
//     if (fileName.length > trimmed.length) {
//       trimmed += '...';
//     }
  
//     return trimmed;
//   }

// export function uploadFileFormat(fileName) {
//   const maxWords = 4;
//   const maxChars = 30;

//   // First, trim to maxChars to ensure the filename isn't too long
//   let trimmed = fileName.slice(0, maxChars);

//   // Then trim by words, splitting by spaces and rejoining
//   let words = trimmed.split(' ');
//   if (words.length > maxWords) {
//     trimmed = words.slice(0, maxWords).join(' ');
//   }

//   // Add ellipsis if the original filename is longer than the trimmed version
//   if (fileName.length > trimmed.length) {
//     trimmed += '...';
//   }

//   return trimmed;
// }

export function uploadFileFormat(fileName) {
  if (typeof fileName !== 'string') {
    // Handle cases where fileName is not a string
    console.error('Invalid fileName: expected a string');
    return '';
  }

  const maxWords = 4;
  const maxChars = 30;

  // Trim to maxChars to ensure the filename isn't too long
  let trimmed = fileName.slice(0, maxChars);

  // Split by spaces and rejoin if there are more than maxWords
  let words = trimmed.split(' ');
  if (words.length > maxWords) {
    trimmed = words.slice(0, maxWords).join(' ');
  }

  // Add ellipsis if the original filename is longer than the trimmed version
  if (fileName.length > trimmed.length) {
    trimmed += '...';
  }

  return trimmed;
}

