// // import React from "react";
// // import { BrowserRouter, Route, Routes } from "react-router-dom";
// // import MainLayout from "./MainLayout";
// // import Landing from "../feature/home";
// // import Aboutus from "../feature/MenuPage/Aboutus";
// // import OurGoals from "../feature/MenuPage/OurGoals";

// // import OurValues from "../feature/MenuPage/OurValues";

// // import SignUp from "../feature/authentication/Signup";
// // import SignIn from "../feature/authentication/Login";
// // import Userdetails from "../feature/Project/AddProject";
// // import AddBid from "../feature/Bid/AddBid";
// // import Welcome from "../feature/authentication/Welcome";
// // import ForgetPassword from "../feature/authentication/ForgetPassword";
// // // import Dashboard from "../dashboardlayout/Dashboard";

// // import Dashboard from "../feature/authentication/dashboard";
// // import AdminDashboard from "../feature/authentication/AdminDashboard";
// // import CompanyDashboard from "../feature/authentication/CompanyDashboard";
// // import SuperAdminDashboard from "../feature/authentication/SuperAdminDashboard";
// // import CustomerQuotes from "../feature/authentication/CustomerQuotes";
// // import CustomerEnquires from "../feature/authentication/CustomerEnquires";
// // import AdminQuotes from "../feature/authentication/AdminQuotes";

// // import { useSelector } from "react-redux";
// // import {userTypeObject} from '../constant/object'
// // import { logOut, selectCurrentToken } from "../redux/reducer/shared/authSlice";

// // import RequireAuth from "../feature/authentication/RequireAuth";
// // import AdminInquiries from "../feature/authentication/AdminInquiries";




// // const AppRoute = () => {
// //   const accessToken = useSelector(selectCurrentToken);
// //   const { Customer, Company } = userTypeObject;
// //   return (
// //     <BrowserRouter>
// //       <Routes>
// //       <Route
// //               element={
// //                 <RequireAuth
// //                   allowedUserType={[...Object.values(userTypeObject)]}
// //                 />
// //               }
// //             >
// //         {/* <Route path="/dashboard" element={<Dashboard/>}/> */}
// //         <Route path="/dashboard" element={<Dashboard />} />
// //           {/* <Route path="/dashboard/profile" element={<Profile />} /> */}
// //           <Route path="/dashboard/customerquotes" element={<CustomerQuotes />} />
// //           <Route path="/dashboard/customerenquires" element={<CustomerEnquires />} />

// //           <Route path="/admindashboard" element={<AdminDashboard />} />
// //           <Route path="/admindashboard/adminquotes" element={<AdminQuotes />} />
// //           <Route path="/admindashboard/admininquiries" element={<AdminInquiries />} />
// //           <Route path="/superadmindashboard" element={<SuperAdminDashboard />} />
// //           <Route path="/companydashboard" element={<CompanyDashboard />} />

// //         <Route path="/" element={<MainLayout />} />
// //         <Route path="/" element={<Landing />} />
// //         <Route path="/addproject" element={<Userdetails />} />
// //         </Route>
// //           <Route path="/signup" element={<SignUp />} />
// //           <Route path="/login" element={<SignIn />} />
// //           <Route path="/forgetpassword" element={<ForgetPassword/>}/>
// //           <Route path="/addproject" element={<Userdetails />} />
// //           <Route path="/addbid" element={<AddBid />} />
// //           <Route path="/welcome" element={<Welcome/>}/>
          
// //           <Route path="/aboutus" element={<Aboutus />}/>
// //           <Route path="/ourgoals" element={<OurGoals />}/>
          
// //           <Route path="/ourvalues" element={<OurValues />}/>
// //       </Routes>
// //     </BrowserRouter>
// //   );
// // };
// // export default AppRoute;

// // // {/* <Route element={<MainLayout />}> */}//  </Route> 


// // //  <BrowserRouter>
// // {/* <Routes>
    
// // {accessToken ? (
// //  <>
// // <Route path="/dashboard" element={<Dashboard/>}/>
// // <Route path="/" element={<MainLayout />} />
// // <Route path="/addproject" element={<Userdetails />} />
// // </>
// // ) : (
// // <>
// //  <Route path="/signup" element={<SignUp />} />
// //  <Route path="/login" element={<SignIn />} />
// //  <Route path="/forgetpassword" element={<ForgetPassword/>}/>
// //  <Route path="/addproject" element={<Userdetails />} />
// //  <Route path="/addbid" element={<AddBid />} />
// //  <Route path="/welcome" element={<Welcome/>}/>
// //  <Route path="/" element={<MainLayout />} />
// //  </>
// // )}
// // </Routes>
// // </BrowserRouter> */}




// import React from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import MainLayout from "./MainLayout";
// import SignUp from "../feature/authentication/Signup";
// import SignIn from "../feature/authentication/Login";
// import Userdetails from "../feature/Project/AddProject";
// import AddBid from "../feature/Bid/AddBid";
// import Welcome from "../feature/authentication/Welcome";
// import ForgetPassword from "../feature/authentication/ForgetPassword";
// import Landing from "../feature/home";
// import Aboutus from "../feature/MenuPage/Aboutus";
// import OurGoals from "../feature/MenuPage/OurGoals";

// import OurValues from "../feature/MenuPage/OurValues";
// // import Dashboard from "../dashboardlayout/Dashboard";

// import Dashboard from "../feature/authentication/dashboard";
// import AdminDashboard from "../feature/authentication/AdminDashboard";
// import CompanyDashboard from "../feature/authentication/CompanyDashboard";
// import SuperAdminDashboard from "../feature/authentication/SuperAdminDashboard";
// import CustomerQuotes from "../feature/authentication/CustomerQuotes";
// import CustomerEnquires from "../feature/authentication/CustomerEnquires";
// import AdminQuotes from "../feature/authentication/AdminQuotes";

// import { useSelector } from "react-redux";
// import {userTypeObject} from '../constant/object'
// import { logOut, selectCurrentToken } from "../redux/reducer/shared/authSlice";

// import RequireAuth from "../feature/authentication/RequireAuth";
// import AdminInquiries from "../feature/authentication/AdminInquiries";
// import DashboardHeader from "./DashboardHeader";



// const AppRoute = () => {
//   const accessToken = useSelector(selectCurrentToken);
//   const { Customer, Company } = userTypeObject;
//   return (
//     <BrowserRouter>
//       <Routes>
//       <Route
//               element={
//                 <RequireAuth
//                   allowedUserType={[...Object.values(userTypeObject)]}
//                 />
//               }
//             >
//         {/* <Route path="/dashboard" element={<Dashboard/>}/> */}
//         {/* <Route path="/dashboard" element={<Dashboard />} /> */}
//         {/* <Route path="/dashboardheader" element={<DashboardHeader />} /> */}
//           {/* <Route path="/dashboard/profile" element={<Profile />} /> */}
//           <Route path="/dashboard/customerquotes" element={<CustomerQuotes />} />
//           <Route path="/dashboard/customerenquires" element={<CustomerEnquires />} />

//           <Route path="/admindashboard" element={<AdminDashboard />} />
//           <Route path="/admindashboard/adminquotes" element={<AdminQuotes />} />
//           <Route path="/admindashboard/admininquiries" element={<AdminInquiries />} />
//           <Route path="/superadmindashboard" element={<SuperAdminDashboard />} />
//           <Route path="/companydashboard" element={<CompanyDashboard />} />

//         <Route path="/" element={<MainLayout />} >
//         {/* <Route path="/dashboardheader" element={<DashboardHeader />} /> */}
//         {/* <Route path="/" element={<Landing />} /> */}
//         <Route path="/addproject" element={<Userdetails />} />
       
//           <Route path="/signup" element={<SignUp />} />
//           <Route path="/login" element={<SignIn />} />
//           <Route path="/forgetpassword" element={<ForgetPassword/>}/>
//           <Route path="/addproject" element={<Userdetails />} />
//           <Route path="/addbid" element={<AddBid />} />
//           <Route path="/welcome" element={<Welcome/>}/>
//           <Route path="/aboutus" element={<Aboutus />}/>
//           <Route path="/ourgoals" element={<OurGoals />}/>
          
//         <Route path="/ourvalues" element={<OurValues />}/>
//           {/* <Route path="/" element={<MainLayout />} /> */}
//           </Route>
//           </Route>
//       </Routes>
//     </BrowserRouter>
//   );
// };
// export default AppRoute;

// // {/* <Route element={<MainLayout />}> */}//  </Route> 


// //  <BrowserRouter>
// {/* <Routes>
    
// {accessToken ? (
//  <>
// <Route path="/dashboard" element={<Dashboard/>}/>
// <Route path="/" element={<MainLayout />} />
// <Route path="/addproject" element={<Userdetails />} />
// </>
// ) : (
// <>
//  <Route path="/signup" element={<SignUp />} />
//  <Route path="/login" element={<SignIn />} />
//  <Route path="/forgetpassword" element={<ForgetPassword/>}/>
//  <Route path="/addproject" element={<Userdetails />} />
//  <Route path="/addbid" element={<AddBid />} />
//  <Route path="/welcome" element={<Welcome/>}/>
//  <Route path="/" element={<MainLayout />} />
//  </>
// )}
// </Routes>
// </BrowserRouter> */}


// // import React from "react";
// // import { Route, Routes } from "react-router-dom";
// // import Layout from "./Layout";
// // import SignUp from "../feature/authentication/Signup";
// // import SignIn from "../feature/authentication/Login";
// // // import LoginPage from "../page/LoginPage";
// // // import Dashboard from "./Dashboard";
// // import ProfilePage from "../page/ProfilePage";
// // // import DashboardPage from "../page/DashboardPage";
// // // import UserPage from "../page/UserPage";
// // // import CallRecordPage from "../page/CallRecordPage";
// // import InvalidRoute from "../constant/not-found/InvalidRoute";
// // // import SmartNumberPage from "../page/SmartNumberPage";
// // // import PartnerSummaryPage from "../page/PartnerSummaryPage";
// // // import SmartNumberSummaryPage from "../page/SmartNumberSummaryPage";
// // // import AnnouncementPage from "../page/AnnouncementPage";
// // // import TimeControlPage from "../page/TimeControlPage";
// // import RequireAuth from "../feature/authentication/RequireAuth";
// // import PersistLogin from "../feature/authentication/PersistLogin";
// // import Prefetch from "../feature/authentication/Prefetch";
// // import { userTypeObject } from "../constant/object";
// // // import AnswerPointPage from "../page/AnswerPointPage";
// // import ReactToastify from "../constant/notification/ReactToastify";
// // // import ProportionalRoutingPage from "../page/ProportionalRoutingPage";
// // // import NationalRoutingPage from "../page/NationalRoutingPage";
// // // import DirectRoutingPage from "../page/DirectRoutingPage";
// // // import CityBaseRoutingPage from "../page/CityBaseRoutingPage";
// // // import PostCodeRoutingPage from "../page/PostcodeRoutingPage";
// // // import ExchangeRoutingPage from "../page/ExchangeRoutingPage";
// // // import StateBaseRoutingPage from "../page/StateBaseRoutingPage";
// // // import RegionRoutingPage from "../page/RegionRoutingPage";
// // import MainLayout from "./MainLayout";

// // const { admin, customer } = userTypeObject;

// // const AppRoute = () => {
// //   return (
// //     <>
// //       <Routes>
// //         <Route path="/" element={<Layout />}>
// //           {/* <Route index element={<LoginPage />} /> */}
// //           <Route path="/login" element={<SignIn />} />
// //           <Route path="/signup" element={<SignUp />} />
                   
// //           {/* <Route element={<PersistLogin />}>
// //             <Route
// //               element={
// //                 <RequireAuth
// //                   allowedUserType={[...Object.values(userTypeObject)]}
// //                 />
// //               }
// //             >
// //               <Route element={<Prefetch />}> */}
// //                 <Route path="dashboard" element={<MainLayout />}>
// //                   {/* <Route index element={<DashboardPage />} /> */}

// //                   {/* <Route path="smart-number" element={<SmartNumberPage />} />
// //                   <Route
// //                     path="smart-number/announcement"
// //                     element={<AnnouncementPage />}
// //                   />
// //                   <Route
// //                     path="smart-number/time-control"
// //                     element={<TimeControlPage />}
// //                   />
// //                   <Route
// //                     path="smart-number/answer-point"
// //                     element={<AnswerPointPage />}
// //                   />
// //                   <Route
// //                     path="smart-number/national-routing"
// //                     element={<NationalRoutingPage />}
// //                   />
// //                   <Route
// //                     path="smart-number/proportional-routing"
// //                     element={<ProportionalRoutingPage />}
// //                   />
// //                   <Route
// //                     path="smart-number/direct-routing"
// //                     element={<DirectRoutingPage />}
// //                   />
// //                   <Route
// //                     path="smart-number/city-routing"
// //                     element={<CityBaseRoutingPage />}
// //                   />
// //                   <Route
// //                     path="smart-number/postcode-routing"
// //                     element={<PostCodeRoutingPage />}
// //                   />
// //                   <Route
// //                     path="smart-number/exchange-routing"
// //                     element={<ExchangeRoutingPage />}
// //                   />
// //                   <Route
// //                     path="smart-number/state-base-routing"
// //                     element={<StateBaseRoutingPage />}
// //                   />
// //                   <Route
// //                     path="smart-number/region-routing"
// //                     element={<RegionRoutingPage />}
// //                   /> */}

// //                   {/* <Route path="user" element={<UserPage />} />
// //                   <Route path="profile" element={<ProfilePage />} />
// //                   <Route
// //                     path="smart-number-summary"
// //                     element={<SmartNumberSummaryPage />}
// //                   /> */}
// //                   {/* <Route
// //                     element={
// //                       <RequireAuth allowedUserType={[admin, channelpartner]} />
// //                     }
// //                   > */}
// //                   {/* <Route path="call-record" element={<CallRecordPage />} />
// //                   <Route
// //                     path="partner-summary"
// //                     element={<PartnerSummaryPage />}
// //                   /> */}
// //                   {/* </Route> */}
// //                 </Route>
// //               </Route>
// //             {/* </Route>
// //           </Route>
// //         </Route> */}

// //         <Route path="*" element={<InvalidRoute />} />
// //       </Routes>

// //       <ReactToastify />
// //     </>
// //   );
// // };

// // export default AppRoute;
// // //  <Route
// // //                       element={
// // //                         <RequireAuth allowedUserType={[admin, channelpartner]} />
// // //                       }
// // //                     ></Route>




// import React from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import MainLayout from "./MainLayout";
// import Landing from "../feature/home";
// import Aboutus from "../feature/MenuPage/Aboutus";
// import OurGoals from "../feature/MenuPage/OurGoals";

// import OurValues from "../feature/MenuPage/OurValues";

// import SignUp from "../feature/authentication/Signup";
// import SignIn from "../feature/authentication/Login";
// import Userdetails from "../feature/Project/AddProject";
// import AddBid from "../feature/Bid/AddBid";
// import Welcome from "../feature/authentication/Welcome";
// import ForgetPassword from "../feature/authentication/ForgetPassword";
// // import Dashboard from "../dashboardlayout/Dashboard";

// import Dashboard from "../feature/authentication/dashboard";
// import AdminDashboard from "../feature/authentication/AdminDashboard";
// import CompanyDashboard from "../feature/authentication/CompanyDashboard";
// import SuperAdminDashboard from "../feature/authentication/SuperAdminDashboard";
// import CustomerQuotes from "../feature/authentication/CustomerQuotes";
// import CustomerEnquires from "../feature/authentication/CustomerEnquires";
// import AdminQuotes from "../feature/authentication/AdminQuotes";

// import { useSelector } from "react-redux";
// import {userTypeObject} from '../constant/object'
// import { logOut, selectCurrentToken } from "../redux/reducer/shared/authSlice";

// import RequireAuth from "../feature/authentication/RequireAuth";
// import AdminInquiries from "../feature/authentication/AdminInquiries";




// const AppRoute = () => {
//   const accessToken = useSelector(selectCurrentToken);
//   const { Customer, Company } = userTypeObject;
//   return (
//     <BrowserRouter>
//       <Routes>
//       <Route
//               element={
//                 <RequireAuth
//                   allowedUserType={[...Object.values(userTypeObject)]}
//                 />
//               }
//             >
//         {/* <Route path="/dashboard" element={<Dashboard/>}/> */}
//         <Route path="/dashboard" element={<Dashboard />} />
//           {/* <Route path="/dashboard/profile" element={<Profile />} /> */}
//           <Route path="/dashboard/customerquotes" element={<CustomerQuotes />} />
//           <Route path="/dashboard/customerenquires" element={<CustomerEnquires />} />

//           <Route path="/admindashboard" element={<AdminDashboard />} />
//           <Route path="/admindashboard/adminquotes" element={<AdminQuotes />} />
//           <Route path="/admindashboard/admininquiries" element={<AdminInquiries />} />
//           <Route path="/superadmindashboard" element={<SuperAdminDashboard />} />
//           <Route path="/companydashboard" element={<CompanyDashboard />} />

//         <Route path="/" element={<MainLayout />} />
//         <Route path="/" element={<Landing />} />
//         <Route path="/addproject" element={<Userdetails />} />
//         </Route>
//           <Route path="/signup" element={<SignUp />} />
//           <Route path="/login" element={<SignIn />} />
//           <Route path="/forgetpassword" element={<ForgetPassword/>}/>
//           <Route path="/addproject" element={<Userdetails />} />
//           <Route path="/addbid" element={<AddBid />} />
//           <Route path="/welcome" element={<Welcome/>}/>
          
//           <Route path="/aboutus" element={<Aboutus />}/>
//           <Route path="/ourgoals" element={<OurGoals />}/>
          
//           <Route path="/ourvalues" element={<OurValues />}/>
//       </Routes>
//     </BrowserRouter>
//   );
// };
// export default AppRoute;

// // {/* <Route element={<MainLayout />}> */}//  </Route> 


// //  <BrowserRouter>
// {/* <Routes>
    
// {accessToken ? (
//  <>
// <Route path="/dashboard" element={<Dashboard/>}/>
// <Route path="/" element={<MainLayout />} />
// <Route path="/addproject" element={<Userdetails />} />
// </>
// ) : (
// <>
//  <Route path="/signup" element={<SignUp />} />
//  <Route path="/login" element={<SignIn />} />
//  <Route path="/forgetpassword" element={<ForgetPassword/>}/>
//  <Route path="/addproject" element={<Userdetails />} />
//  <Route path="/addbid" element={<AddBid />} />
//  <Route path="/welcome" element={<Welcome/>}/>
//  <Route path="/" element={<MainLayout />} />
//  </>
// )}
// </Routes>
// </BrowserRouter> */}




import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./MainLayout";
import SignUp from "../feature/authentication/Signup";
import SignIn from "../feature/authentication/Login";
import AddBid from "../feature/Bid/AddBid";
import Welcome from "../feature/authentication/Welcome";
import ForgetPassword from "../feature/authentication/ForgetPassword";
import Landing from "../feature/home";
import Aboutus from "../feature/MenuPage/Aboutus";
import OurGoals from "../feature/MenuPage/OurGoals";

import OurValues from "../feature/MenuPage/OurValues";
// import Dashboard from "../dashboardlayout/Dashboard";

import Dashboard from "../feature/authentication/dashboard";
import AdminDashboard from "../feature/authentication/AdminDashboard";
import CompanyDashboard from "../feature/authentication/CompanyDashboard";
import SuperAdminDashboard from "../feature/authentication/SuperAdminDashboard";
import CustomerQuotes from "../feature/authentication/CustomerQuotes";
import CustomerEnquires from "../feature/authentication/CustomerEnquires";
import AdminQuotes from "../feature/authentication/AdminQuotes";

import { useSelector } from "react-redux";
import {userTypeObject} from '../constant/object'
import { logOut, selectCurrentToken } from "../redux/reducer/shared/authSlice";

import RequireAuth from "../feature/authentication/RequireAuth";
import AdminInquiries from "../feature/authentication/AdminInquiries";
import DashboardHeader from "./DashboardHeader";

import DashboardPage from "../dashboard/Stats";

import GeneralLayout from "./GeneralLayout";


//Customer Components
import QuoteAdd from "../feature/customer/quote/QuoteAddCustomer";
import CustomerQuoteList from "../feature/customer/quote/QuoteListCustomer";
import Form from "../feature/customer/multistep-form/Form";
// import EnquiryViewCustomer from "../feature/customer/enquiry/EnquiryViewCustomer";


// Admin Componenets
import AdminQuoteList from "../feature/admin/quote/QuoteList";
import AdminEnquiryList from "../feature/admin/enquiry/EnquiryListAdmin";
import QuoteEditAdmin from "../feature/admin/quote/QuoteEditAdmin";

//Company Components
import CompanyEnquiryList from "../feature/company/enquiry/EnquiryList";
import QuoteListComapny from "../feature/company/quote/QuoteListComapny";

import PersistLogin from "../feature/authentication/PersistLogin";
import Prefetch from "../feature/authentication/Prefetch";




const AppRoute = () => {
  const accessToken = useSelector(selectCurrentToken);
  // const { Customer, Company , IntegrationEngineer } = userTypeObject;
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<GeneralLayout />} >
      <Route path="/" element={<Landing />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/forgetpassword" element={<ForgetPassword/>}/>
      <Route path="/welcome" element={<Welcome/>}/>
          <Route path="/aboutus" element={<Aboutus />}/>
          <Route path="/ourgoals" element={<OurGoals />}/>
          
        <Route path="/ourvalues" element={<OurValues />}/>
        <Route path="/addbid" element={<AddBid />} />
      </Route>
      <Route element={<PersistLogin />}>
      <Route
              element={
                <RequireAuth
                  allowedUserType={[...Object.values(userTypeObject)]}
                />
              }
            >
             <Route element={<Prefetch />}> 
        {/* <Route path="/dashboard" element={<Dashboard/>}/> */}
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        {/* <Route path="/dashboardheader" element={<DashboardHeader />} /> */}
          {/* <Route path="/dashboard/profile" element={<Profile />} /> */}

          {/* <Route path="/dashboard/customerquotes" element={<CustomerQuotes />} />
          <Route path="/dashboard/customerenquires" element={<CustomerEnquires />} />

          <Route path="/admindashboard" element={<AdminDashboard />} />
          <Route path="/admindashboard/adminquotes" element={<AdminQuotes />} />
          <Route path="/admindashboard/admininquiries" element={<AdminInquiries />} />
          <Route path="/superadmindashboard" element={<SuperAdminDashboard />} />
          <Route path="/companydashboard" element={<CompanyDashboard />} /> */}
         


        <Route path="/" element={<MainLayout />} >
        {/* <Route path="/dashboardheader" element={<DashboardHeader />} /> */}
        <Route path="/dashboardheader" element={<DashboardPage />} />
        {/* <Route path="/admindashboard/adminquotes" element={<AdminQuotes />} /> */}
        {/* <Route path="/addproject" element={<Userdetails />} /> */}

        {/* Route for Customer */}
        <Route
                element={<RequireAuth allowedUserType={[userTypeObject.Customer]} />}
              >
          <Route path="/customer/create-quote" element={<QuoteAdd />} />
          {/* <Route path="/customer/create-quote" element={<Form />} /> */}
          <Route path="/customer/quote" element={<CustomerQuoteList />} />
          {/* <Route path="/customer/enquiry" element={<EnquiryViewCustomer />} /> */}
</Route>
           {/* Route for Admin */}
           <Route
                element={<RequireAuth allowedUserType={[userTypeObject.Admin]} />}
              >
          <Route path="/admin/quote" element={<AdminQuoteList />} />
          <Route path="/edit-quote/:quoteId" element={<QuoteEditAdmin/>} />
          <Route path="/admin/inquiry" element={<AdminEnquiryList />} />
          </Route>

           {/* Route for Company */}
           <Route
                element={<RequireAuth allowedUserType={[userTypeObject.Company]} />}
              >
           <Route path="/company/inquiry" element={<CompanyEnquiryList />} />
           <Route path="/company/quote" element={<QuoteListComapny />} />
           </Route>

          
          {/* <Route path="/" element={<MainLayout />} /> */}
          </Route>
          </Route>
          </Route>
          </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default AppRoute;

// {/* <Route element={<MainLayout />}> */}//  </Route> 


//  <BrowserRouter>
{/* <Routes>
    
{accessToken ? (
 <>
<Route path="/dashboard" element={<Dashboard/>}/>
<Route path="/" element={<MainLayout />} />
<Route path="/addproject" element={<Userdetails />} />
</>
) : (
<>
 <Route path="/signup" element={<SignUp />} />
 <Route path="/login" element={<SignIn />} />
 <Route path="/forgetpassword" element={<ForgetPassword/>}/>
 <Route path="/addproject" element={<Userdetails />} />
 <Route path="/addbid" element={<AddBid />} />
 <Route path="/welcome" element={<Welcome/>}/>
 <Route path="/" element={<MainLayout />} />
 </>
)}
</Routes>
</BrowserRouter> */}

