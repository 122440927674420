

import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Prefetch = () => {
  useEffect(() => {
    console.log("-----------------------------------------");
    console.log("prefetch");
    console.log("-----------------------------------------");

 
  }, []);

  return <Outlet />;
};

export default Prefetch;
