import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";

const ButtonLoading = () => {
  return (
    <>
      <LoadingButton
        loading
        fullWidth
        variant="outlined"
        sx={{ mt: 3, mb: 2, backgroundColor: "#34495e" }}
        loadingIndicator={
          <CircularProgress style={{ color: "white" }} size={20} />
        }
      >
        Submit
      </LoadingButton>
    </>
  );
};

export default ButtonLoading;