// import React, { Fragment } from 'react';
// import ContactMailIcon from '@mui/icons-material/ContactMail';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import ReceiptIcon from '@mui/icons-material/Receipt';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';
// import BusinessIcon from '@mui/icons-material/Business';
// import GroupIcon from '@mui/icons-material/Group';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import MessageIcon from '@mui/icons-material/Message';
// import { Link } from 'react-router-dom';

// export const sideArray=[
//     <Fragment>
//         {
//             <ListItemButton>
//       <ListItemIcon>
//         <DashboardIcon />
//       </ListItemIcon>
//       <ListItemText primary="Dashboard" />
//     </ListItemButton>
//         },
//         {
//             <ListItemButton>
//     <ListItemIcon>
//       <GroupIcon />
//     </ListItemIcon>
//     <ListItemText primary="Customers" />
//   </ListItemButton>
//         },
        
// {
//      <ListItemButton>
//      <ListItemIcon>
//        <BusinessIcon />
//      </ListItemIcon>
//      <ListItemText primary="Companies" />
//    </ListItemButton>
// },
// {
//     <ListItemButton>
//     <ListItemIcon>
//       <ReceiptIcon />
//     </ListItemIcon>
//     <ListItemText primary="Quotes" />
//   </ListItemButton>
// },
// {
//     <ListItemButton>
//     <ListItemIcon>
//       <ContactMailIcon />
//     </ListItemIcon>
//     <ListItemText primary="Enquiries" />
//   </ListItemButton>
// },
// {
//     <ListItemButton>
//     <ListItemIcon>
//       <MessageIcon />
//     </ListItemIcon>
//     <ListItemText primary="Messages" />
//   </ListItemButton>
// },
// {
//         <ListItemButton onClick={() => setActivePage('createAccount')}>
//         <ListItemIcon>
//            <PersonAddIcon />
//          </ListItemIcon>
//          <ListItemText primary="Create Account" />
//       </ListItemButton>
//      },

//     </Fragment>
// ];

// export const sideBarArraySuperAdmin=[

//     <Fragment>
// {
//     <ListItemButton>
//       <ListItemIcon>
//         <DashboardIcon />
//       </ListItemIcon>
//       <ListItemText primary="Dashboard" />
//     </ListItemButton>
// },
// {
//     <ListItemButton>
//     <ListItemIcon>
//       <GroupIcon />
//     </ListItemIcon>
//     <ListItemText primary="Customers" />
//   </ListItemButton>
// },
// {
//      <ListItemButton>
//      <ListItemIcon>
//        <BusinessIcon />
//      </ListItemIcon>
//      <ListItemText primary="Companies" />
//    </ListItemButton>
// },
// {
//     <ListItemButton>
//     <ListItemIcon>
//       <ReceiptIcon />
//     </ListItemIcon>
//     <ListItemText primary="Quotes" />
//   </ListItemButton>
// },
// {
//     <ListItemButton>
//     <ListItemIcon>
//       <ContactMailIcon />
//     </ListItemIcon>
//     <ListItemText primary="Enquiries" />
//   </ListItemButton>
// },
// {
//     <ListItemButton>
//     <ListItemIcon>
//       <MessageIcon />
//     </ListItemIcon>
//     <ListItemText primary="Messages" />
//   </ListItemButton>
// }

// </Fragment>

// ];

// export const sideBarArrayAdmin=[

// <Fragment>
//     {
//         <ListItemButton onClick={() => setActivePage('stats')}>
//         <ListItemIcon>
//           <DashboardIcon />
//         </ListItemIcon>
//         <ListItemText primary="Dashboard" />
//       </ListItemButton>
//     },
//     {
//         <ListItemButton onClick={() => setActivePage('quotes')}>
//         <ListItemIcon>
//           <ReceiptIcon />
//         </ListItemIcon>
//         <ListItemText primary="Quotes" />
//       </ListItemButton>
//     },
//      {
//           <ListItemButton onClick={() => setActivePage('customers')}>
//           <ListItemIcon>
//            <GroupIcon />
//         </ListItemIcon>
//         <ListItemText primary="Customers" />
//       </ListItemButton>
//      },
//      {
//   <ListItemButton onClick={() => setActivePage('companies')}>
//   <ListItemIcon>
//     <BusinessIcon />
//   </ListItemIcon>
//   <ListItemText primary="Companies" />
// </ListItemButton>
//      },
//      {
//  <ListItemButton onClick={() => setActivePage('enquiries')}>
//  <ListItemIcon>
//   <ContactMailIcon />
// </ListItemIcon>
// <ListItemText primary="Enquiries" />
// </ListItemButton>
//      },
//      {
//         <ListItemButton onClick={() => setActivePage('createAccount')}>
//         <ListItemIcon>
//            <PersonAddIcon />
//          </ListItemIcon>
//          <ListItemText primary="Create Account" />
//       </ListItemButton>
//      }
// </Fragment>

// ];

// export const sideBarArrayCustomer=[

//     <Fragment>
//          {
//         <ListItemButton onClick={() => setActivePage('stats')}>
//         <ListItemIcon>
//           <DashboardIcon />
//         </ListItemIcon>
//         <ListItemText primary="Dashboard" />
//       </ListItemButton>
//     },
//         {
//  <ListItemButton onClick={() => setActivePage('quotes')}>
//  <ListItemIcon>
//    <ReceiptIcon />
//  </ListItemIcon>
//  <ListItemText primary="Quotes" />
// </ListItemButton>
//         },
//         {
//             <ListItemButton onClick={() => setActivePage('enquiries')}>
//  <ListItemIcon>
//   <ContactMailIcon />
// </ListItemIcon>
// <ListItemText primary="Enquiries" />
// </ListItemButton>
//         },
//         {
//            <ListItemButton>
//            <ListItemIcon>
//              <MessageIcon />
//            </ListItemIcon>
//            <ListItemText primary="Messages" />
//          </ListItemButton>
     
//         }
//     </Fragment>

// ];

// export const sideBarArrayCompany=[

//     <Fragment>
//          {
//         <ListItemButton onClick={() => setActivePage('stats')}>
//         <ListItemIcon>
//           <DashboardIcon />
//         </ListItemIcon>
//         <ListItemText primary="Dashboard" />
//       </ListItemButton>
//     },
//         {
//  <ListItemButton onClick={() => setActivePage('quotes')}>
//  <ListItemIcon>
//    <ReceiptIcon />
//  </ListItemIcon>
//  <ListItemText primary="Quotes" />
// </ListItemButton>
//         },
//         {
//             <ListItemButton onClick={() => setActivePage('enquiries')}>
//  <ListItemIcon>
//   <ContactMailIcon />
// </ListItemIcon>
// <ListItemText primary="Enquiries" />
// </ListItemButton>
//         },
//         {
//            <ListItemButton>
//            <ListItemIcon>
//              <MessageIcon />
//            </ListItemIcon>
//            <ListItemText primary="Messages" />
//          </ListItemButton>
     
//         }
//     </Fragment>

// ];




import DashboardIcon from "../assets/side-bar/dashboardIcon.svg";
import SummaryIcon from "../assets/side-bar/summary.svg";
import CompaniesIcon from "../assets/side-bar/companies.svg";
import NumbersIcon from "../assets/side-bar/numbers.svg";
import CallRecordsIcon from "../assets/side-bar/callRecords.svg";
import AnalyticsIcon from "../assets/side-bar/analytics.svg";
import useAuth from "../hook/useAuth";

export const headerDropDownArray = [
  {
    label: "Logout",
    name: "logout",
    route: "/",
    logo: null,
  },
  {
    label: "Profile",
    name: "profile",
    route: "/",
    logo: null,
  },
];

export const sideArray = [
  {
    label: "Dashboard",
    name: "dashboard",
    route: "/dashboardheader",
    logo: <img src={DashboardIcon} alt="Dash" />,
  },

  {
    label: "Partner Summary",
    name: "partner summary",
    route: "/dashboard/partner-summary",
    logo: <img src={SummaryIcon} alt="Partner Summary" />,
  },
  {
    label: "Companies",
    name: "companies",
    route: "/dashboard/user",
    logo: <img src={CompaniesIcon} alt="Companies" />,
  },
  {
    label: "Numbers",
    name: "numbers",
    route: "/dashboard/smart-number",
    logo: <img src={NumbersIcon} alt="Numbers" />,
  },
  {
    label: "Call Records",
    name: "call records",
    route: "/dashboard/call-record",
    logo: <img src={CallRecordsIcon} alt="Call Records" />,
  },
  {
    label: "Analytics",
    name: "analytics",
    route: "/dashboard/analytic",
    logo: <img src={AnalyticsIcon} alt="Analytics" />,
  },
];

export const sideBarArraySuperAdmin = [
    {
        label: "Dashboard",
        name: "dashboard",
        route: "/dashboardheader",
        logo: <img src={DashboardIcon} alt="stats" />,
      },
    
      {
        label: "Quotes",
        name: "quotes",
        route: "/dashboard/partner-summary",
        logo: <img src={SummaryIcon} alt="Quotes" />,
      },
      {
        label: "Customers",
        name: "customers",
        route: "/dashboard/smart-number",
        logo: <img src={NumbersIcon} alt="Customers" />,
      },
      {
        label: "Companies",
        name: "companies",
        route: "/dashboard/call-record",
        logo: <img src={CallRecordsIcon} alt="Companies" />,
      },
      {
        label: "Enquiries",
        name: "enquiries",
        route: "/dashboard/analytic",
        logo: <img src={AnalyticsIcon} alt="Enquiries" />,
      },
      {
        label:"Messages",
        name:"messages",
        route:"",
        logo:<img src={AnalyticsIcon} alt="Messages"/>
      }
      
];
export const sideBarArrayAdmin = [
  {
    label: "Dashboard",
    name: "dashboard",
    route: "/dashboardheader",
    logo: <img src={DashboardIcon} alt="stats" />,
  },
  {
    label: "Quotes",
    logo: <img src={SummaryIcon} alt="Quotes" />,
    subMenu: [
      {
        label: "Quotes",
        name: "show-quotes",
        route: "/admin/quote",
      },
      // {
      //   label: "Add Quotes",
      //   name: "add-quotes",
      //   route: "/addproject",
      // },
    ],
  },
  // {
  //   label: "Customers",
  //   name: "customers",
  //   route: "/dashboard/smart-number",
  //   logo: <img src={NumbersIcon} alt="Customers" />,
  // },
  // {
  //   label: "Companies",
  //   name: "companies",
  //   route: "/dashboard/call-record",
  //   logo: <img src={CallRecordsIcon} alt="Companies" />,
  // },
  {
    label: "Enquiries",
    name: "enquiries",
    route: "/admin/inquiry",
    logo: <img src={AnalyticsIcon} alt="Enquiries" />,
  },
  // {
  //   label:"Create Account",
  //   name:"create account",
  //   route:"",
  //   logo:<img src={AnalyticsIcon} alt="Create Account"/>
  // }
];
export const sideBarArrayCustomer = [
    {
        label: "Dashboard",
        name: "dashboard",
        route: "/dashboardheader",
        logo: <img src={DashboardIcon} alt="stats" />,
      },
    
      {
        label: "Quotes",
        name: "quotes",
        // route: "/dashboard/partner-summary",
        route: "/customer/quote",
        logo: <img src={SummaryIcon} alt="Quotes" />,
      },
      
      // {
      //   label: "Enquiries",
      //   name: "enquiries",
      //   route: "/customer/enquiry",
      //   logo: <img src={AnalyticsIcon} alt="Enquiries" />,
      // },
      
      // {
      //   label:"Messages",
      //   name:"messages",
      //   route:"",
      //   logo:<img src={AnalyticsIcon} alt="Messages"/>
      // }
];

export const sideBarArrayCompany = [
  {
    label: "Dashboard",
    name: "dashboard",
    route: "/dashboardheader",
    logo: <img src={DashboardIcon} alt="Dash" />,
  },

  {
    label: "Enquiries",
    name: "numbers",
    route: "/company/inquiry",
    logo: <img src={NumbersIcon} alt="Smart Number Summary" />,
  },
  {
    label: "Quotes",
    name: "numbers",
    route: "/company/quote",
    logo: <img src={NumbersIcon} alt="Numbers" />,
  },
];
