// import React from 'react'
// import Box from "@mui/material/Box";
// import { IconButton, Tooltip,Button } from "@mui/material";
// import { Modal } from "@mui/material";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// const EnquiryViewCustomer = ({props}) => {
//     console.log(props)
//     const [open, setOpen] = React.useState(false);

//     const handleOpen = () => setOpen(true);
//     const handleClose = () => setOpen(false);
//   return (
//     <>
//       <Tooltip title="View">
//         {/* <IconButton onClick={handleOpen}>
//           <VisibilityIcon
//             style={{ color: "#294289", cursor: "pointer" }}
//           />
//         </IconButton> */}
//         <Button
//             variant="contained"
//             color="primary"
//             startIcon={<VisibilityIcon/>}
//             onClick={handleOpen}
//             sx={{ textTransform: "none" }}
//           >
//             View Inquiry
//           </Button>
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box>
//             ascd
//         </Box>
//       </Modal>
//     </>
//   )
// }

// export default EnquiryViewCustomer



// import React, { useEffect, useState } from "react";
// import {
//   Table,
//   TableBody,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
//   TableCell,
//   Tooltip,
//   TablePagination,
//   Button,
//   Box,
//   Modal
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import AddIcon from "@mui/icons-material/Add";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import  useGetInquiriesByCustomerQuery  from "../../../hook/routing/useGetInquiriesByCustomerQuery";
// import useAuth from "../../../hook/useAuth"; 
// import {
//   globalStyle,
// } from "../../../constant/globalStyle";
// import { useNavigate } from "react-router-dom";
// const columns = [
//     { field: "inquiryId", headerName: "Enquiry ID", width: 130 },
//     { field: "offerExpiryDate", headerName: "Offer Expiry Date", width: 130 },
//     { field: "totalPrice", headerName: "Total Price", width: 130 },
//     { field: "adminStatus", headerName: "Admin Status", width: 130 },
//     { field: "customerStatus", headerName: "Customer Status", width: 130 },
// ];



// const EnquiryViewCustomer = ({quoteId}) => {
//   const navigate = useNavigate();
//   const { array, isLoading, refetch } = useGetInquiriesByCustomerQuery(quoteId); // Destructure data here
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [open, setOpen] = React.useState(false);
//  const handleOpen = () => setOpen(true);
//  const handleClose = () => setOpen(false);
  

//   const openAlertBox = async ({ title, message, confirmButtonText, cancelButtonText, onConfirm }) => {
//     if (window.confirm(message)) {
//       await onConfirm();
//     }
//   };

//   const deleteUser = async (user) => {
//     console.log("Deleting user:", user);
//   };
//   const handleDelete = async (object) => {
//     try {
//       await openAlertBox({
//         title: "Confirmation",
//         message: "Are you sure you want to permanently delete this record?",
//         confirmButtonText: "Delete",
//         cancelButtonText: "Cancel",
//         onConfirm: () => deleteUser(object),
//       });
//     } catch (error) {
//       console.error("Error during deletion:", error);
//     }
//   };

//   useEffect(() => {
//     refetch();
//   }, [refetch]);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleAddQuote = () => {
//     // Add your logic to open the quote creation form or navigate to the creation page
//     console.log("Add Quote button clicked");
//     navigate('/customer/create-quote');
//   };

//   return (
//     <>
//     <Tooltip title="View">
//         <IconButton onClick={handleOpen}>
//           <VisibilityIcon style={{ color: "#294289", cursor: "pointer" }} />
//         </IconButton>
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-title"
//         aria-describedby="modal-description"
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: "80%",
//             bgcolor: "background.paper",
//             border: "2px solid #000",
//             boxShadow: 24,
//             p: 4,
//             borderRadius: "10px",
//             overflowY: "auto",
//             maxHeight: "80vh",
//           }}
//         >
//     <Paper elevation={2} sx={{ borderRadius: "15px",my:2, mx: 4 }}>
//         <TableContainer>
//           <Table aria-label="simple table">
//             <TableHead>
//               <TableRow>
//                 {columns.map((column) => (
//                   <TableCell
//                     key={column.field}
//                     align="left"
//                     sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
//                   >
//                     {column.headerName}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>

//             <TableBody>
//               {isLoading ? (
//                 <TableRow>
//                   <TableCell colSpan={columns.length}>Loading...</TableCell>
//                 </TableRow>
//               ) : (
//                 array
//                   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                   .map((row) => (
//                     <TableRow key={row.inquiryId}>
//                       <TableCell>{row.inquiryId}</TableCell>
//                       <TableCell>{row.offerExpiryDate}</TableCell>
//                       <TableCell>{row.totalPrice}</TableCell>
//                       <TableCell>{row.adminStatus}</TableCell>
//                       <TableCell>{row.customerStatus}</TableCell>
                     
                     
//                     </TableRow>
//                   ))
//               )}
//             </TableBody>
//           </Table>
//           <TablePagination
//             rowsPerPageOptions={[5, 10, 25]}
//             component="div"
//             count={array.length}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </TableContainer>
//       </Paper>
//       </Box>
//       </Modal>
//     </>
//   );
// };
// export default EnquiryViewCustomer;

















// import React, { useEffect, useState } from "react";
// import {
//   Table,
//   TableBody,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
//   TableCell,
//   Tooltip,
//   TablePagination,
//   Box,
//   Modal,
//   Select,
//   MenuItem,
//   CircularProgress
// } from "@mui/material";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import { useNavigate } from "react-router-dom";
// import  useGetInquiriesByCustomerQuery  from "../../../hook/routing/useGetInquiriesByCustomerQuery";
// import { useUpdateInquiryCustomerStatusMutation } from "../../../redux/reducer/api/inquiryApiSlice";
// import useAuth from "../../../hook/useAuth"; 
// import { globalStyle } from "../../../constant/globalStyle";

// const columns = [
//   { field: "inquiryId", headerName: "Enquiry ID", width: 130 },
//   { field: "offerExpiryDate", headerName: "Offer Expiry Date", width: 130 },
//   { field: "totalPrice", headerName: "Total Price", width: 130 },
//   { field: "adminStatus", headerName: "Admin Status", width: 130 },
//   { field: "customerStatus", headerName: "Customer Status", width: 130 },
// ];

// const EnquiryViewCustomer = ({ quoteId }) => {
//     console.log(quoteId)
//   const navigate = useNavigate();
//   const { array, isLoading, refetch } = useGetInquiriesByCustomerQuery(quoteId); // Destructure data here
//   const [updateInquiryCustomerStatus, { isLoading: isUpdating, error }] = useUpdateInquiryCustomerStatusMutation();
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [open, setOpen] = useState(false);
//   const [optimisticStatus, setOptimisticStatus] = useState(null);

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleStatusChange = async (inquiryId, newStatus) => {
//     // Optimistically update UI
//     console.log(inquiryId,newStatus);
//     setOptimisticStatus((prevStatus) => ({
//       ...prevStatus,
//       [inquiryId]: newStatus
//     }));
//     try {
//       await updateInquiryCustomerStatus({ inquiryId, customerStatus: newStatus }).unwrap();
//       // If successful, you might want to refetch data to ensure consistency
//       refetch();
//     //   console.log(optimisticStatus)
//     } catch (err) {
//       console.error("Error updating customer status:", err);
//       // Rollback to previous status if update fails
//       setOptimisticStatus((prevStatus) => ({
//         ...prevStatus,
//         [inquiryId]: null // or previous status
//       }));
//     }
//   };

//   useEffect(() => {
//     refetch();
//   }, [refetch]);

//   return (
//     <>
//       <Tooltip title="View">
//         <IconButton onClick={handleOpen}>
//           <VisibilityIcon style={{ color: "#294289", cursor: "pointer" }} />
//         </IconButton>
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-title"
//         aria-describedby="modal-description"
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: "80%",
//             bgcolor: "background.paper",
//             border: "2px solid #000",
//             boxShadow: 24,
//             p: 4,
//             borderRadius: "10px",
//             overflowY: "auto",
//             maxHeight: "80vh",
//           }}
//         >
//           <Paper elevation={2} sx={{ borderRadius: "15px", my: 2, mx: 4 }}>
//             <TableContainer>
//               <Table aria-label="simple table">
//                 <TableHead>
//                   <TableRow>
//                     {columns.map((column) => (
//                       <TableCell
//                         key={column.field}
//                         align="left"
//                         sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
//                       >
//                         {column.headerName}
//                       </TableCell>
//                     ))}
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {isLoading ? (
//                     <TableRow>
//                       <TableCell colSpan={columns.length} align="center">
//                         <CircularProgress />
//                       </TableCell>
//                     </TableRow>
//                   ) : (
//                     array
//                       .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                       .map((row) => (
//                         <TableRow key={row.inquiryId}>
//                           <TableCell>{row.inquiryId}</TableCell>
//                           <TableCell>{row.offerExpiryDate}</TableCell>
//                           <TableCell>{row.totalPrice}</TableCell>
//                           <TableCell>{row.adminStatus}</TableCell>
//                           <TableCell>
//                             <Select
//                               value={optimisticStatus?.[row.inquiryId] || row.customerStatus}
//                               onChange={(e) => handleStatusChange(row.inquiryId, e.target.value)}
//                               disabled={isUpdating}
//                             >
//                               <MenuItem value="Pending">Pending</MenuItem>
//                               <MenuItem value="Approved">Approved</MenuItem>
//                               <MenuItem value="Declined">Declined</MenuItem>
//                             </Select>
//                           </TableCell>
//                         </TableRow>
//                       ))
//                   )}
//                 </TableBody>
//               </Table>
//               <TablePagination
//                 rowsPerPageOptions={[5, 10, 25]}
//                 component="div"
//                 count={array.length}
//                 rowsPerPage={rowsPerPage}
//                 page={page}
//                 onPageChange={handleChangePage}
//                 onRowsPerPageChange={handleChangeRowsPerPage}
//               />
//             </TableContainer>
//           </Paper>
//         </Box>
//       </Modal>
//     </>
//   );
// };

// export default EnquiryViewCustomer;






import React, {useState, useEffect } from "react";
import { DataGrid, GridToolbar, useGridApiContext } from "@mui/x-data-grid";
import {
  Paper,
  IconButton,
  Tooltip,
  Select,
  Modal,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import AddIcon from "@mui/icons-material/Add";
import DataGridHeader from "../../../component/data-grid/DataGridHeader";
import CustomNoRowsOverlay from "../../../component/data-grid/CustomNoRowsOverlay";
import { useAlertDialogSlide } from "../../../component/alert-dialog/AlertDialogProvider";
import {
  useDeleteInquiryMutation,
} from "../../../redux/reducer/api/inquiryApiSlice";
// import { useUpdateInquiryAdminStatusMutation } from "../../../redux/reducer/api/inquiryApiSlice";
import { useUpdateInquiryCustomerStatusMutation } from "../../../redux/reducer/api/inquiryApiSlice";
import  useGetInquiriesByCustomerQuery  from "../../../hook/routing/useGetInquiriesByCustomerQuery";
import EnquiryViewCustomer from "./EnquiryViewCustomer";
// import { deleteInquiryMutation } from "../../../util/helper-function";
//import EnquiryViewAdmin from "./EnquiryViewAdmin";
// import { useNavigate } from "react-router-dom";

const EnquiryListCustomer = ({ quoteId }) => {
  // const navigate = useNavigate();
  const [pageSize, setPageSize] = React.useState(10);
  const { array, isLoading, refetch } = useGetInquiriesByCustomerQuery(quoteId); 
  // const { open: openAlertBox } = useAlertDialogSlide();
  // const [deleteInquiry] = useDeleteInquiryMutation();
  const [updateInquiryCustomerStatus] = useUpdateInquiryCustomerStatusMutation();
  const [open, setOpen] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectedInquiry, setSelectedInquiry] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenDetailModal = (inquiry) => {
    console.log("handleOpenDetailModal",inquiry)
    setSelectedInquiry(inquiry);
    setOpenDetailModal(true);
    handleClose()
  };
  const handleCloseDetailModal = () => {
     setOpen(true);
     setOpenDetailModal(false)
    };

  useEffect(() => {
    refetch().catch((error) => {
      console.error("Error fetching data:", error);
    });
  }, [refetch]);

  // const handleDelete = async (row) => {
  //   const { inquiryId } = row;
  //   try {
  //     await openAlertBox({
  //       title: "Confirmation",
  //       message: `Are you sure you want to permanently delete ${inquiryId}?`,
  //       confirmButtonText: "Delete",
  //       cancelButtonText: "Cancel",
  //       onConfirm: () => deleteInquiryMutation({ inquiryId }, deleteInquiry, refetch),
  //     });
  //   } catch (err) {
  //     console.error("Dialog was closed without confirmation");
  //   }
  // };

  const handleStatusChange = async (id, field, value) => {
    try {
      await updateInquiryCustomerStatus({
        inquiryId: id,
        customerStatus: value,
      }).unwrap();
      refetch();
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };

  // const handleEditEnquiry = (object) => {
  //   console.log("Editing quote:", object);
  // };

  // const handleQuote = () => {
  //   navigate("/customer/create-quote");
  // };

  const SelectEditInputCell = (props) => {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      const newValue = event.target.value;
      await apiRef.current.setEditCellValue({ id, field, value: newValue });
      apiRef.current.stopCellEditMode({ id, field });
      handleStatusChange(id, field, newValue);
    };

    return (
      <Select
        value={value}
        onChange={handleChange}
        size="small"
        sx={{ height: 1 }}
        native
        autoFocus
      >
        <option value="Pending">Pending</option>
        <option value="Approved">Approved</option>
        <option value="Declined">Declined</option>
      </Select>
    );
  };

  const renderSelectEditInputCell = (params) => {
    return <SelectEditInputCell {...params} />;
  };

  return (
        <>
      <Tooltip title="View">
        <IconButton onClick={handleOpen}>
          <VisibilityIcon style={{ color: "#294289", cursor: "pointer" }} />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
         <Box
           sx={{
             position: "absolute",
             top: "10%",
             left: "10%",
            //  transform: "translate(-50%, -50%)",
             width: "80%",
             bgcolor: "background.paper",
             border: "2px solid #000",
             boxShadow: 24,
             p: 4,
             borderRadius: "10px",
             overflowY: "auto",
            maxHeight: "80vh",
           }}
         >
    <Paper elevation={2} sx={{ borderRadius: "15px", m: 4 }}>
      <DataGrid
        autoHeight
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        rows={array}
        loading={isLoading}
        getRowId={(row) => row.inquiryId}
        slots={{
          toolbar: GridToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        columns={[
          {
            field: "inquiryId",
            flex: 1,
            hide: true,
            renderHeader: () => <DataGridHeader header={"Inquiry Id"} />,
            headerAlign: "center",
            align: "center",
          },
          {
            field: "offerExpiryDate",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Offer Expiry Date"} />,
            headerAlign: "center",
            align: "center",
          },
          {
            field: "totalPrice",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Total Price"} />,
            headerAlign: "center",
            align: "center",
          },
          {
            field: "adminStatus",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Admin Status"} />,
            headerAlign: "center",
            align: "center",
            // editable: true,
            // renderEditCell: renderSelectEditInputCell,
          },
          {
            field: "customerStatus",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Customer Status"} />,
            headerAlign: "center",
            align: "center",
            editable: true,
            renderEditCell: renderSelectEditInputCell,
          },
          {
                  field: "view",
                  // headerName: "View",
                  renderHeader: () => <DataGridHeader header={"Action"} />,
                  flex: 0.5,
                  align: "center",
                  headerAlign: "center",
                  renderCell: (params) => (
                    <IconButton
                      onClick={() => handleOpenDetailModal(params.row)}
                    >
                      <VisibilityIcon style={{ color: "#294289", cursor: "pointer" }}  />
                    </IconButton>
                  ),
                },
          // {
          //   field: "actions",
          //   sortable: false,
          //   filterable: false,
          //   flex: 1,
          //   renderHeader: () => <DataGridHeader header={"Actions"} />,
          //   headerAlign: "center",
          //   align: "center",
          //   renderCell: (params) => {
          //     return (
          //       <>
          //         <Tooltip title="Delete" sx={{ p: 1 }}>
          //           <IconButton
          //             color="error"
          //             cursor="pointer"
          //             onClick={() => handleDelete(params.row)}
          //           >
          //             <DeleteOutlineOutlinedIcon />
          //           </IconButton>
          //         </Tooltip>

          //         {
          //           <Tooltip title="Edit">
          //             <IconButton
          //               color="primary"
          //               onClick={() => handleEditEnquiry(params.row)}
          //             >
          //               <EditIcon />
          //             </IconButton>
          //           </Tooltip>
          //         }
          //         {/* {
          //           <EnquiryViewAdmin props={params.row} />
          //         } */}
          //       </>
          //     );
          //   },
          // },
        ]}
      />
    </Paper>
    </Box>
    </Modal>
    <EnquiryViewCustomer
        open={openDetailModal}
        onClose={handleCloseDetailModal}
        inquiry={selectedInquiry}
      />
    </>
  );
};

export default EnquiryListCustomer;



