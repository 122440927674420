import { Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { globalStyle } from "../../constant/globalStyle";

const DataGridHeader = ({ header }) => {
  return (
    <>
      <Typography style={{ ...globalStyle, fontWeight: 700 }}>
        {header}
      </Typography>
    </>
  );
};

DataGridHeader.propTypes = {
  header: PropTypes.string.isRequired,
};

export default DataGridHeader;
