import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { bgDotsFooterStyle } from '../constant/globalStyle';

const Footer = () => {
  const navigate = useNavigate();
  
  const handleAboutus = () => {
    navigate("/aboutus");
  };
  const handleOurGoals = () => {
    navigate("/ourgoals");
  };

  const handleOurValues = () => {
    navigate("/ourvalues");
  };

  return (
    <Box sx={{ background: "white", width: "100%", height: "100%", py: "17px", color: "black", ...bgDotsFooterStyle }}>
      <Grid container spacing={2} backgroundColor='rgba(0, 0, 0, 0.1)' sx={{ height: "100%", py: "40px", px: "50px" }}>
      <Grid item xs={12} sm={3}>
          <Typography component="div" variant="body1" color="inherit">Email
            <Typography variant='h5' color="inherit"  sx={{ cursor: "pointer" }}>Info@infraverse.com</Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle1" gutterBottom onClick={handleOurGoals} sx={{ cursor: "pointer" }}>Our Goals</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="subtitle1" gutterBottom onClick={handleOurValues} sx={{ cursor: "pointer" }}>Our Values</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography component="div" variant="body1" color="inherit">Contact Us
            <Typography variant='h5' color="inherit"  sx={{ cursor: "pointer" }}>07774177977</Typography>
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="body2" color="textSecondary" align="center" sx={{ pt: 4 }}>
        &copy; 2024 InfraVerse. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;