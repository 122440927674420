// import {configureStore} from '@reduxjs/toolkit';
// import {setupListeners} from '@reduxjs/toolkit/query';

// const store=configureStore({
//     reducer:{
//         [apiSlice.reducerPath]: apiSlice.reducer,
//         [tagTypeDict.auth]: authSlice,
//         [tagTypeDict.smartNumber]: smartNumberSlice,
//     },
//     middleware:(getDefaultMiddleware)
// })




// import { configureStore } from "@reduxjs/toolkit";
// import { apiSlice } from '../service/apiSlice';
// import { setupListeners } from "@reduxjs/toolkit/query";
// // import authReducer from '../shared/authSlice';
// import authReducer from "../reducer/shared/authSlice";
// // import cartReducer from '../reducer/api/cartSlice'; // Import the cartSlice reducer

// export const store = configureStore({
//     reducer: {
//         [apiSlice.reducerPath]: apiSlice.reducer,
//         auth: authReducer,
//         // cart: cartReducer // Add the cartSlice reducer to the store
//     },
//     middleware: getDefaultMiddleware =>
//         getDefaultMiddleware().concat(apiSlice.middleware),
//     devTools: true
// });

// setupListeners(store.dispatch);


// import { configureStore } from "@reduxjs/toolkit";
// import { apiSlice } from '../service/apiSlice';
// import { setupListeners } from "@reduxjs/toolkit/query";
// import authReducer from "../reducer/shared/authSlice";
// import storage from 'redux-persist/lib/storage'; // defaults to local storage for web
// import { persistStore, persistReducer } from 'redux-persist';
// import { combineReducers } from 'redux';

// // Creating a persist config object
// const persistConfig = {
//     key: 'root', // Key of persisted state in storage
//     storage, // Type of storage to use
//     whitelist: ['auth'] // Reducers you want to persist
// };

// // Combine reducers
// const rootReducer = combineReducers({
//     [apiSlice.reducerPath]: apiSlice.reducer,
//     auth: authReducer
// });

// // Apply persistReducer with the config and rootReducer
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//     reducer: persistedReducer,
//     middleware: getDefaultMiddleware =>
//         getDefaultMiddleware({
//             serializableCheck: {
//                 // Ignore these action types
//                 ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PAUSE', 'persist/PERSIST', 'persist/PURGE', 'persist/REGISTER'],
//             },
//         }).concat(apiSlice.middleware),
//     devTools: true
// });

// // Create a persistor instance
// export const persistor = persistStore(store);

// // Set up listeners for RTK Query
// setupListeners(store.dispatch);


import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { combineReducers } from "redux";
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import authReducer from "../reducer/shared/authSlice"
import { apiSlice } from '../service/apiSlice'; // Ensure the path is correct
// import authReducer from "../features/shared/authSlice"; // Ensure the path is correct

// Configuration for Redux Persist
const persistConfig = {
    key: 'root',  // Key for the persisted state
    storage,  // Storage method
    whitelist: ['auth'],  // Specify which reducers should be persisted
};

// Combining reducers
const rootReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
});

// Applying the persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Actions that are non-serializable can be ignored here
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PAUSE', 'persist/PURGE', 'persist/REGISTER'],
            },
        }).concat(apiSlice.middleware),
    devTools: true,
});

export const persistor = persistStore(store);  // Create a persistor instance

setupListeners(store.dispatch);  // Set up listeners for RTK Query
