// // import React from "react";
// // import Box from "@mui/material/Box";
// // import CssBaseline from "@mui/material/CssBaseline";

// // import Header from "./Header";
// // import Footer from "./Footer";
// // import { Outlet } from "react-router-dom";
// // // import Landing from "../feature/home";
// // const MainLayout = () => {
// //   return (
// //     <>
// //       <Box>
// //         <CssBaseline />

// //         <Header />
// // {/* <Landing/> */}
// //         <Outlet />

// //         <Footer />
// //       </Box>
// //     </>
// //   );
// // };

// // export default MainLayout;

// // import React from "react";
// // import Box from "@mui/material/Box";
// // import CssBaseline from "@mui/material/CssBaseline";

// // import Header from "./Header";
// // import Footer from "./Footer";
// // import { Outlet } from "react-router-dom";
// // // import Landing from "../feature/home";
// // const MainLayout = () => {
// //   return (
// //     <>
// //       <Box>
// //         <CssBaseline />

// //         <Header />
// // {/* <Landing/> */}
// //         <Outlet />

// //         <Footer />
// //       </Box>
// //     </>
// //   );
// // };

// // export default MainLayout;



// import { useState } from "react";
// import Box from "@mui/material/Box";
// import CssBaseline from "@mui/material/CssBaseline";
// import { Outlet } from "react-router-dom";
// import { sideArray } from "./menuArray";
// import { DrawerHeader } from "./style";
// import DashboardHeader from "./Header";
// import ContainerGlobal from "./ContainerGlobal";
// import { AlertDialogSlideContextProvider } from "../constant/alert-dialog/AlertDialogProvider";
// import SideBar from "./SideBar";

// const MainLayout = () => {
//   const [open, setOpen] = useState(false);
//   const toggleSidebar = () => {
//     setOpen((prev) => !prev);
//     const body = document.querySelector("main");
//     if (body) body.style.overflowX = open ? "auto" : "hidden";
//   };

//   return (
//     <Box sx={{ display: "flex" }}>
//       <CssBaseline />
//       <DashboardHeader open={open} toggleSidebar={toggleSidebar} />
//       <SideBar open={open} toggleSidebar={toggleSidebar} sideBarArray={sideArray} />
//       <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
//         <DrawerHeader />
//         <AlertDialogSlideContextProvider>
//           <ContainerGlobal>
//             <Outlet />
//           </ContainerGlobal>
//         </AlertDialogSlideContextProvider>
//       </Box>
//     </Box>
//   );
// };

// export default MainLayout;


import { useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import { sideArray } from "./menuArray";
import { DrawerHeader } from "./style";
import DashboardHeader from "./DashboardHeader";
import ContainerGlobal from "./ContainerGlobal";
// import { AlertDialogSlideContextProvider } from "../constant/alert-dialog/AlertDialogProvider";
import { AlertDialogSlideContextProvider } from "../component/alert-dialog/AlertDialogProvider";
import SideBar from "./SideBar";

const MainLayout = () => {
  const [open, setOpen] = useState(false);
  let sideBarArray = [...sideArray];
  const toggleSidebar = () => {
    // console.log("Hello Toggle")
    setOpen((prev) => !prev);
    const body = document.querySelector("main");
    if (body) body.style.overflowX = open ? "auto" : "hidden";
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <DashboardHeader open={open} toggleSidebar={toggleSidebar} />

        <SideBar
          open={open}
          toggleSidebar={toggleSidebar}
          sideBarArray={sideBarArray}
        />

        <Box component="main" sx={{ flexGrow: 1,pt:2 }}>
          <DrawerHeader />

          <AlertDialogSlideContextProvider>
            <ContainerGlobal>
              <Outlet />
            </ContainerGlobal>
          </AlertDialogSlideContextProvider>
        </Box>
      </Box>
    </>
  );
};

export default MainLayout;

