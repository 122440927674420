import React, { useState } from "react";
import { Formik, Field, Form,useField,FieldArray } from "formik";
import * as yup from "yup";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Radio,
  InputLabel,
  Select, MenuItem,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  
  Snackbar,
  
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import {
  globalAddPaper,
  globalStyle,
  globalModalBtnStyle,
  globalModalTitleStyle,
  globalModalTextfieldLabelStyle,
  globalModalTextfieldStyle,
} from "../../../constant/globalStyle";

import { useId } from "react";
import { uploadFileFormat } from "../../../constant/uploadFileFormat";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useCreateQuoteMutation } from '../../../redux/reducer/api/quoteApiSlice';
import { width } from "@mui/system";
const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email format").required("Email is required"),
  contactNo: yup.string().required("Contact Number is required"),
  companyName: yup.string().required("Company Name is required"),
  
});

const plotTypes = [
  "1 Bedroom Property", "1 Bedroom Bungalow", "1 Bedroom Detached","1 Bedroom Terrace", "1 Bedroom Semi-Detached",
 "Pumpstation",
 "Fiber OSCP",
 "Other"
];
const MySelect = ({ label, placeholder, ...props }) => {
  const [field, meta, helpers] = useField(props);
  return (
    <FormControl fullWidth error={meta.touched && !!meta.error}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        {...props}
        displayEmpty
        renderValue={(selected) => {
          if (selected === '') {
            return <Typography color="textSecondary">{placeholder}</Typography>;
          }
          return selected;
        }}
      >
        <MenuItem value="" disabled>{placeholder}</MenuItem>
        {props.children}
      </Select>
      {meta.touched && meta.error ? (
        <Typography color="error" variant="body2">{meta.error}</Typography>
      ) : null}
    </FormControl>
  );
};
const QuoteAdd = () => {
  const navigate = useNavigate(); 
  const [formStage, setFormStage] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [createQuote, { isLoading }] = useCreateQuoteMutation();

  const idh = useId();
  const [services,setServicesType]=useState("");
  const [heatingType,setHeatingType]=useState("");
  const [developmentType, setDevelopmentType] = useState("");
  const [typeOfLoad, setTypeOfLoad] = useState("");
  const [typeOfLandlord, setTypeOfLandlord] = useState("");
  

  const [worksOffer, setFileUpload] = useState("");

  // const [sitePlaneFile, setSitePlaneFile] = useState("");
  // const [sitePlaneFiles, setSitePlaneFiles] = useState("");
const [offerLetterFile,setOfferLetterFile]=useState('');
 
  // const [openSnackbar, setOpenSnackbar] = useState(false);

  const [sitePlanFiles, setSitePlanFiles] = useState([]);
 

  
  const totalStages = 4; 
  
  const initialValues = {
    name: "", email: "", contactNo: "", companyName: "",fullAddress:"",
    siteAddress: "", quoteDeadline: "", expectedDate: "", totalSiteLoad: "",
    postalCode: "", services: "", heatingType: "", specialNote: "",customHeatingType:"",
    plots: [{ plotType: "", plotName: "", units: "", loadPerPlot: "" }],
    offerLetterFile:"", sitePlanFiles: []
  };


const handleFileUpload = (event, setFieldValue) => {
  const file = event.target.files[0];
  console.log(file)
  // console.log(file)
  if (file) {
    setOfferLetterFile(file.name);
      setFieldValue('offerLetterFile', file);  // Setting the file object directly
      setTimeout(() => {
          setOpenSnackbar(true); // Show success message
      }, 1500);
  } else {
      setFieldValue('offerLetterFile', "");  // Ensure null is set if no file is selected
  }
};
  const handleFileChange = (event, setFieldValue) => {
    const files = event.target.files;
    console.log(files)
    if (files && files.length > 0) {
        const fileList = Array.from(files);  // Convert FileList to Array
        setSitePlanFiles(fileList);
        setFieldValue("sitePlanFiles", fileList);  // Update Formik's state with all files
    } else {
      setSitePlanFiles([]);
        console.error("No files found in the event object");
        setFieldValue("sitePlanFiles", []);  // Ensure existing files are cleared if no files selected
    }
};
  // const fileDisplay = files => files.map((file, index) => (
  //   <Typography key={index}>{uploadFileFormat(file.name)} - {Math.round(file.size / 1024)} KB</Typography>
  // ));

  const fileDisplay = (files) =>
    files.map((file, index) => (
      <Typography key={index}>
        {uploadFileFormat(file.name)} - {Math.round(file.size / 1024)} KB
      </Typography>
    ));

const onSubmitHandle = async (values, {resetForm, setSubmitting,}) => {
  // const sanitizedPlots = values.plots.map(plot => {
  //   if (plot.plotType !== "Other") {
  //     const { plotName, ...rest } = plot;
  //     return rest;
  //   }
  //   return plot;
  // });

  const formData = new FormData();
  //formData.append('plots', JSON.stringify(sanitizedPlots));
  // const formData = new FormData();
  Object.keys(values).forEach(key => {
      if (key === 'plots') {
          formData.append(key, JSON.stringify(values[key]));  // Handle JSON serialization of array or object
        //  formData.append(key, JSON.stringify(sanitizedPlots));  // Handle JSON serialization of array or object
      } else if (values[key] instanceof File) {
          formData.append(key, values[key]);  // Handle single file
      } else if (Array.isArray(values[key]) && values[key].some(item => item instanceof File)) {
          // Append each file under the same name for arrays of files
          values[key].forEach(file => {
              if (file instanceof File) {
                  formData.append(key, file);
              }
          });
      } else {
          formData.append(key, values[key]);  // Handle other form fields
      }
      // console.log('Quote created successfully:',formData.values);
  });
  // 4. Log the FormData object key-value pairs
//   for (let [key, value] of formData.entries()) {
//     console.log(`${key}:`, value);
//     console.log('Quote created successfully:',formData.values);
// }
// Log FormData entries before sending to backend

// for (let [key, value] of formData.entries()) {
//   console.log(`${key}:`, value);
// }

// Collect FormData into a simple object for logging
// const formDataLog = {};
// formData.forEach((value, key) => {
//   formDataLog[key] = value;
// });
// Log the collected form data object
 //console.log('FormData:', formDataLog);

// Collect FormData into a simple object for logging
const formDataLog = {};
formData.forEach((value, key) => {
  // Check if the key is 'plots', and parse it back into an array for better logging
  if (key === 'plots') {
    formDataLog[key] = JSON.parse(value); // Parse the stringified JSON to restore the array
  } else if (key === 'sitePlanFiles') {
    // If it's a file array, log each file's details
    formDataLog[key] = [];
    for (let i = 0; i < values[key].length; i++) {
      const file = values[key][i];
      formDataLog[key].push({
        name: file.name,
        size: file.size,
        type: file.type,
      });
    }
  } else {
    formDataLog[key] = value;
  }
});

// Log the collected form data object
console.log('FormData:', formDataLog);
  try {
    //Create a plain object to store the FormData entries
    const formDataEntries = {};
    for (let [key, value] of formData.entries()) {
      formDataEntries[key] = value;
    }

    //Log the FormData object in a structured way
   console.log('FormData Entries:', formDataEntries);
    //const response = await createQuote(formDataEntries).unwrap();
   // console.log('Quote created successfully:',formData.entries(values));
    const response = await createQuote(formData).unwrap();
    console.log('Quote created successfully:', response);
    setSitePlanFiles([]);
    // setFieldValue("sitePlanFiles", []);
    setOfferLetterFile("");
      setOpenSnackbar(true);
     navigate("/customer/quote"); 
    resetForm();
  } catch (error) {
    alert(`An error occurred during create imquiry: ${error.data?.error || error.data?.message }`);
      console.error('Error creating quote:', error);
      setOpenSnackbar(true);
  } finally {
    setSitePlanFiles([]); // Clear sitePlanFiles state
    setOfferLetterFile(""); // Clear offerLetterFile state
      setSubmitting(false);
  }
};
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const commonAreaStyle = {
    height: "49px",
    width: "100%",
    marginLeft: "2px",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandle}
      
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form>
          <Paper elevation={2} sx={{ ...globalAddPaper, marginTop: 5, marginBottom:10, }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography sx={{ ...globalModalTitleStyle, p: 2 }}>
                  Create a New Quote
                </Typography>
              </Grid>
              {formStage === 0 && (
                <>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }} >
                <Field name="name">
                  {({ field, meta }) => (
                    <TextField
                      required
                      id={`${idh}-name`}
                      label="Name"
                      placeholder="Name"
                      fullWidth
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue("name", event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      type="text"
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="email">
                  {({ field, meta }) => (
                    <TextField
                      required
                      id={`${idh}-email`}
                      label="Email"
                      placeholder="Email"
                      fullWidth
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue("email", event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      type="text"
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="contactNo">
                  {({ field, meta }) => (
                    <TextField
                      required
                      id={`${idh}-contactNo`}
                      label="Contact No"
                      placeholder="Contact No"
                      fullWidth
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue("contactNo", event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="companyName">
                  {({ field, meta }) => (
                    <TextField
                      required
                      id={`${idh}-companyName`}
                      label=" Company Name  &nbsp;"
                      placeholder="Company Name"
                      fullWidth
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue("Company Name", event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
 {/* New Address Field */}
 <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="siteAddress">
                  {({ field, meta }) => (
                    <TextField
                      
                      id={`${idh}-siteAddress`}
                      label=" Site Address "
                      placeholder="Site Address"
                      fullWidth
                      {...field}
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="quoteDeadline">
                  {({ field, form, meta }) => (
                    <TextField
                      id={`${idh}-quoteDeadline`}
                      label=" Quote Deadline &nbsp; &nbsp; &nbsp; &nbsp;"
                      placeholder="Quote Deadline"
                      type="date"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => {
                        setFieldValue("quoteDeadline", event.target.value);
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="expectedDate">
                  {({ field, form, meta }) => (
                    <TextField
                      id={`${idh}-expectedDate`}
                      label="Project Start Date &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                      placeholder="Project Start Date"
                      type="date"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => {
                        setFieldValue("expectedDate", event.target.value);
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="totalSiteLoad">
                  {({ field, meta }) => (
                    <TextField
                      
                      id={`${idh}-totalSiteLoad`}
                      label="Total Site Load (kVA) &nbsp; "
                      placeholder="Total Site Load (kVA)"
                      fullWidth
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue("totalSiteLoad", event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      type="number"
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                      {...field}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="postalCode">
                  {({ field, meta }) => (
                    <TextField                     
                      id={`${idh}-postalCode`}
                      label="Post Code"
                      placeholder="Post Code"
                      fullWidth
                      value={field.value}
                      onChange={(event) =>
                        setFieldValue("postalCode", event.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
{/* <Grid
  item
  xs={12}
  sm={6}
  sx={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    p: 2,
  }}
>
  <Grid
    item
    xs={12}
    sm={6}
    marginLeft="12px"
    sx={{ height: "90px" }}
  >
    <FormControl component="fieldset" fullWidth>
      <FormLabel component="legend">
        Do you have the valid Non-Contestable works offer from DNO?
      </FormLabel>
      <RadioGroup
        row
        aria-label="worksOffer"
        name="worksOffer"
        value={values.worksOffer}
        onChange={(event) => {
          setFieldValue("worksOffer", event.target.value);
          setFileUpload(event.target.value);
        }}
      >
        <FormControlLabel
          value="yes"
          control={<Radio />}
          label="Yes"
        />
        <FormControlLabel
          value="no"
          control={<Radio />}
          label="No"
        />
      </RadioGroup>
    </FormControl>
  </Grid>
  {worksOffer === "yes" && ( 
    <Grid item xs={12} sm={6} sx={{ p: 2 }}>
  <Typography variant="subtitle1">
    Please upload the offer letter and drawing.
  </Typography>
  <Button variant="contained" component="label" sx={{ width: '100%' }}>
    Choose File
    <input
    type="file"
    hidden
    onChange={(event) => handleFileUpload(event, setFieldValue)}
/>
  </Button>
  {offerLetterFile && (
    <Typography sx={{ mt: 2 }}>
      {uploadFileFormat(offerLetterFile)}
    </Typography>
  )}
</Grid>
  )}
  {worksOffer === "no" && (
    <Grid
      item
      xs={12}
      sm={6}
      sx={{
        height: "90px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">
          Do you want us to apply on your behalf?
        </FormLabel>
        <RadioGroup
          row
          aria-label="applyOnBehalf"
          name="applyOnBehalf"
          value={values.applyOnBehalf}
          onChange={(event) => {
            setFieldValue("applyOnBehalf", event.target.value);
          }}
        >
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            value="no"
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  )}
</Grid> */}


{/* <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: 2,
              }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                sx={{  marginLeft: '12px' }}
              >
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend">
                    Do you have the valid Non-Contestable works offer from DNO?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="worksOffer"
                    name="worksOffer"
                    value={values.worksOffer || ''} // Ensure value is never undefined
                    onChange={(event) => {
                      const selectedValue = event.target.value;
                      setFieldValue('worksOffer', event.target.value);
                      setFileUpload(event.target.value);
                       
                    }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {worksOffer === 'yes' && (
                <Grid item xs={12} sm={6} sx={{ p: 2 }}>
                  <Typography variant="subtitle1">
                    Please upload the offer letter and drawing.
                  </Typography>
                  <Button
                    variant="contained"
                    component="label"
                    // sx={{ width: '100%' }}
                  >
                    Choose File
                    <input
                      type="file"
                      hidden
                      onChange={(event) =>
                        handleFileUpload(event, setFieldValue)
                      }
                    />
                  </Button>
                  {offerLetterFile && (
                    <Typography sx={{ mt: 2 }}>
                      {uploadFileFormat(offerLetterFile)}
                    </Typography>
                  )}
                </Grid>
              )}

              {worksOffer === 'no' && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    height: '90px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend">
                      Do you want us to apply on your behalf?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="applyOnBehalf"
                      name="applyOnBehalf"
                      value={values.applyOnBehalf || ''} // Ensure value is never undefined
                      onChange={(event) => {
                        setFieldValue('applyOnBehalf', event.target.value);
                        
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
            </Grid> */}


            <Grid
  container
  spacing={2}
  sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    p: 2,
  }}
>
  <Grid
    item
    xs={12}
    sm={6}
    sx={{ marginLeft: '12px' }}
  >
    <FormControl component="fieldset" fullWidth>
      <FormLabel component="legend">
        Do you have the valid Non-Contestable works offer from DNO?
      </FormLabel>
      <RadioGroup
        row
        aria-label="worksOffer"
        name="worksOffer"
        value={values.worksOffer || ''} // Ensure value is never undefined
        onChange={(event) => {
          const selectedValue = event.target.value;
          setFieldValue('worksOffer',event.target.value);
          setFileUpload(event.target.value);

          // Clear applyOnBehalf and offerLetterFile depending on worksOffer
          if (selectedValue === 'yes') {
            //setFieldValue('worksOffer', selectedValue);
            //setFileUpload(event.target.value);
            setFieldValue('applyOnBehalf', false); // Clear applyOnBehalf when 'yes'
          } else {
            setFieldValue('offerLetterFile', ''); // Clear offerLetterFile when 'no'
            setOfferLetterFile("");
            //setFileUpload(null); // Ensure the file input is reset
          }
        }}
      >
        <FormControlLabel
          value="yes"
          control={<Radio />}
          label="Yes"
        />
        <FormControlLabel
          value="no"
          control={<Radio />}
          label="No"
        />
      </RadioGroup>
    </FormControl>
  </Grid>

  {worksOffer === 'yes' && (
    <Grid item xs={12} sm={6} sx={{ p: 2 }}>
      <Typography variant="subtitle1">
        Please upload the offer letter and drawing.
      </Typography>
      <Button
        variant="contained"
        component="label"
      >
        Choose File
        <input
          type="file"
          hidden
          onChange={(event) =>
            handleFileUpload(event, setFieldValue)
          }
        />
      </Button>
      {offerLetterFile && (
        <Typography sx={{ mt: 2 }}>
          {uploadFileFormat(offerLetterFile)}
        </Typography>
      )}
    </Grid>
  )}

  {values.worksOffer === 'no' && (
    <Grid
      item
      xs={12}
      sm={6}
      sx={{
        height: '90px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">
          Do you want us to apply on your behalf?
        </FormLabel>
        <RadioGroup
          row
          aria-label="applyOnBehalf"
          name="applyOnBehalf"
          value={values.applyOnBehalf || ''} // Ensure value is never undefined
          onChange={(event) => {
            setFieldValue('applyOnBehalf', event.target.value);
          }}
        >
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            value="no"
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  )}
</Grid>

<Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="fullAddress">
                  {({ field, meta }) => (
                    <TextField                
                      id={`${idh}-fullAddress`}
                      label="Full address&nbsp;"
                      placeholder="Full address"
                      fullWidth
                      {...field}
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ p: 2 }}>
                <Field name="specialNote">
                  {({ field }) => (
                    <TextField
                      id={`${idh}-specialNote`}
                      label="Any Special Note &nbsp; &nbsp;"
                      placeholder="Any Special Note"
                      fullWidth
                      {...field}
                      InputLabelProps={{
                        shrink: true,
                        style: { ...globalModalTextfieldLabelStyle },
                      }}
                      InputProps={{
                        style: {
                          ...globalStyle,
                          ...globalModalTextfieldStyle,
                          ...commonAreaStyle,
                          borderRadius: "5px",
                        },
                      }}
                    />
                  )}
                </Field>
              </Grid>
              </>
              )}
{/*  ...................................    Next First  ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, */}

{formStage === 1 && (
                <>
<Grid item xs={12} marginLeft={"12px"}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend">
                        Which services do you need?
                      </FormLabel>
                      <RadioGroup
                        row
                        name="services"
                        value={values.services}
                        onChange={(event) => {
                          setFieldValue("services", event.target.value);
                          setServicesType(event.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="1.Electricity_Connections"
                          control={<Radio />}
                          label="Electricity connections"
                        />
                        <FormControlLabel
                          value="2.Gas_Connections"
                          control={<Radio />}
                          label="Gas connections"
                        />
                        <FormControlLabel
                          value="3.Water_Services"
                          control={<Radio />}
                          label="Water services"
                        />
                        <FormControlLabel
                          value="4.Fiber_Services"
                          control={<Radio />}
                          label="Fiber services"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} marginLeft={"12px"}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend">
                        Types of Heating.
                      </FormLabel>
                      <RadioGroup
                        row
                        name="heatingType"
                        value={values.heatingType}
                        // onChange={(event) => {
                        //   setFieldValue("heatingType", event.target.value);
                        //   setHeatingType(event.target.value);
                        // }}
                        onChange={(event) => {
        const selectedHeatingType = event.target.value;
        setFieldValue("heatingType", selectedHeatingType);
        setHeatingType(selectedHeatingType);

        // Clear customHeatingType if the selected heating type is not "5.Other"
        if (selectedHeatingType !== "5.Other") {
          setFieldValue("customHeatingType", "");
        }
      }}
                      >
                        <FormControlLabel
                          value="1.Electric"
                          control={<Radio />}
                          label="Electric"
                        />
                        <FormControlLabel
                          value="2.Gas"
                          control={<Radio />}
                          label="Gas"
                        />
                        <FormControlLabel
                          value="3.ASHP"
                          control={<Radio />}
                          label="ASHP"
                        />
                        <FormControlLabel
                          value="4.District_Heating"
                          control={<Radio />}
                          label="District Heating"
                        />
                         <FormControlLabel
                          value="5.Other"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {heatingType === "5.Other" && (
                        <>                      
                          <Grid item xs={12} sm={6} sx={{ p: 2 }}>
                            <Field name="customHeatingType">
                              {({ field, meta }) => (
                                <TextField
                                  id={`${idh}-customHeatingType`}
                                  label="Custom heating"
                                   placeholder="Custom heating"
                                  fullWidth
                                  value={field.value}
                                  onChange={(event) =>
                                    setFieldValue("customHeatingType",event.target.value)
                                  }
                                  
                                  InputLabelProps={{
                                    shrink: true,
                                    style: {
                                      ...globalModalTextfieldLabelStyle,
                                    },
                                  }}
                                  InputProps={{
                                    style: {
                                      ...globalStyle,
                                      ...globalModalTextfieldStyle,
                                      ...commonAreaStyle,
                                      borderRadius: "5px",
                                    },
                                  }}
                                  {...field}
                                  error={meta.touched && !!meta.error}
                                  helperText={meta.touched ? meta.error : ""}
                                />
                              )}
                            </Field>
                          </Grid>   
                        </>
                      )}
</>
)}
{/* /*  ...................................    Next 2nd  ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, */} 

{/* {formStage === 2 && (
                <>
                <FieldArray name="plots">
  {({ push, remove }) => (
    <>
      {values.plots.length > 0 && values.plots.map((plot, index) => (
        <React.Fragment key={index}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <MySelect
                // label="Plot Type"
                placeholder="Select a plot type..."
                name={`plots[${index}].plotType`}
                onChange={e => {
                  setFieldValue(`plots[${index}].plotType`, e.target.value);
                  // Reset plotName if not 'Other'
                  if (e.target.value !== "Other") {
                    setFieldValue(`plots[${index}].plotName`, '');
                  }
                }}
              >
                {plotTypes.map((type, idx) => (
                  <MenuItem key={idx} value={type}>{type}</MenuItem>
                ))}
              </MySelect>
            </Grid>

            {plot.plotType === "Other" && (
              <>
<Grid item xs={12} sm={6}> </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  name={`plots[${index}].plotName`}
                  label="Plot Name"
                  fullWidth
                />
              </Grid>
              </>
            )}
<Grid item xs={12} sm={6}>
              
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                name={`plots[${index}].units`}
                label="Units"
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                name={`plots[${index}].loadPerPlot`}
                label="Load Per Plot (KVA)"
                type="number"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={() => remove(index)}>Remove</Button>
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => push({ plotType: "", plotName: "", units: "", loadPerPlot: "" })}
        >
          Add More Plot Type
        </Button>
      </Grid>
    </>
  )}
</FieldArray>
          
</>
)} */}

{formStage === 2 && (
                <FieldArray name="plots">
                  {({ push, remove }) => (
                    <>
                      {values.plots.map((plot, index) => (
                        <React.Fragment key={index}>
                          <Grid container spacing={2} marginLeft={"12px"}>
                            <Grid item xs={12} sm={6}>
                              <MySelect
                              placeholder="Select a plot type..."
                                // label="Plot Type"
                                name={`plots[${index}].plotType`}
                                onChange={(e) => {
                                  setFieldValue(`plots[${index}].plotType`, e.target.value);
                                  if (e.target.value !== "Other") {
                                    setFieldValue(`plots[${index}].plotName`, '');
                                  }
                                }}
                              >
                                <MenuItem value="">Select a plot type...</MenuItem>
                                {plotTypes.map((type) => (
                                  <MenuItem key={type} value={type}>{type}</MenuItem>
                                ))}
                              </MySelect>
                              {touched.plots?.[index]?.plotType && errors.plots?.[index]?.plotType && (
                                <Typography color="error">{errors.plots[index].plotType}</Typography>
                              )}
                            </Grid>

                            {plot.plotType === "Other" && (
                              <Grid item xs={12} sm={6}>
                                <Field
                                  as={TextField}
                                  name={`plots[${index}].plotName`}
                                  label="Plot Name"
                                  fullWidth
                                  error={touched.plots?.[index]?.plotName && !!errors.plots?.[index]?.plotName}
                                  helperText={touched.plots?.[index]?.plotName && errors.plots?.[index]?.plotName}
                                />
                              </Grid>
                            )}

                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                name={`plots[${index}].units`}
                                label="Units"
                                type="number"
                                fullWidth
                                error={touched.plots?.[index]?.units && !!errors.plots?.[index]?.units}
                                helperText={touched.plots?.[index]?.units && errors.plots?.[index]?.units}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Field
                                as={TextField}
                                name={`plots[${index}].loadPerPlot`}
                                label="Load Per Plot (KVA)"
                                type="number"
                                fullWidth
                                error={touched.plots?.[index]?.loadPerPlot && !!errors.plots?.[index]?.loadPerPlot}
                                helperText={touched.plots?.[index]?.loadPerPlot && errors.plots?.[index]?.loadPerPlot}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Button onClick={() => remove(index)}>Remove</Button>
                          </Grid>
                        </React.Fragment>
                      ))}
                      <Grid item xs={12}>
                        <Button
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={() => push({ plotType: "", plotName: "", units: "", loadPerPlot: "" })}
                        >
                          Add More Plot Type
                        </Button>
                      </Grid>
                    </>
                  )}
                </FieldArray>
              )}

{/* .....................................4th Stage........................................ */}

{formStage === 3 && (
  <>
    <Grid item ml={2}>
      <Typography variant="h6">Send us any relevant files</Typography>
      <Typography>
        If you've got plans and documents you are able to share, please upload them here.
        These will help us produce a more accurate quote for the work.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: "bold" }}>Accommodational Schedule/Plan Layout/Other</Typography>
          <Button  component="label" variant="contained">
            Choose File
            <input          
 type="file"
 hidden
               multiple
  onChange={(event) => handleFileChange(event, setFieldValue)}
  // onChange={(event) => handleFileChange(event, setFieldValue)}
            />
          </Button>
          {/* {sitePlanFiles.length > 0 && (
            <div style={{ marginTop: '10px' }}>
              {fileDisplay(sitePlanFiles)}
            </div>
          )} */}
          {sitePlanFiles.length > 0 && (
                  <div style={{ marginTop: "10px" }}>
                    {fileDisplay(sitePlanFiles)}
                  </div>
                )}
        </Grid>
        <Grid item xs={12}>
          <Typography>Max file size: 25MB</Typography>
          <Typography>Max number of files: 3</Typography>
        </Grid>
      </Grid>
    </Grid>
  </>
)}
<Grid item xs={12} sm={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
  {formStage > 0 && (
    <Button
      // variant="outlined"
      type="button" 
      variant="contained"
      onClick={(event) => {
        event.preventDefault();
        setFormStage(formStage - 1)
      }
      }
      sx={{ ...globalModalBtnStyle, width: "20%", height: "55px", mr: 2 }}
    >
      Previous Page
    </Button>
  )}
  {formStage < totalStages - 1 ? (
    <Button
    type="button"   // Explicitly set the type to "button"
      variant="contained"
      fullWidth
      onClick={(event) => {
        event.preventDefault();
        setFormStage(formStage + 1);
      }}
      // width: formStage < totalStages - 1 ? "auto" : "45%"
      sx={{ ...globalModalBtnStyle, width: "150px", height: "55px" }}
      disabled={isSubmitting}
    >
      Next Page
    </Button>
  ) : (
    
    <Button type="submit"  // This button triggers form submission
    // sx={{ width: '100%' }}
     variant="contained"
     color="primary" 
     disabled={isSubmitting || isLoading}
     sx={{ textTransform: 'none',width: '20%' }} 
     >
      {isLoading ? 'Submitting...' : 'Submit'}
    </Button>
  )}
</Grid>
            </Grid>
          </Paper>
        
<Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            message={isLoading ? "Submitting..." : "Operation completed successfully"}
          />
        </Form>
      )}
    </Formik>
  );
};
export default QuoteAdd;
