import React, { useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIos";
import about1 from "../../assets/about/about_3_1.png";
import about2 from "../../assets/about/about_3_2.png";
import laborWorking from "../../assets/about/laborWorking.jpeg";
import { Link, useNavigate } from "react-router-dom";
const guides = [
  {
    // title:
    //   "Our goal is to position ElectricConnect Ltd as the foremost provider of electricity connection solutions within the UK construction industry, capturing a significant market share and establishing our platform as the preferred choice for developers, consultants, and construction firms.",
    title:
      "We empower customers with transparency, efficiency, and cost savings, providing exceptional experiences, personalized support, and educational resources for project success.",
    imageUrl: laborWorking,
    
  },
  {
    title:
      "We empower customers with transparency, efficiency, and cost savings, offering exceptional experiences, personalized support, and educational resources for project success.",
    imageUrl: about2,
    
  },
  {
    title:
      "We foster continuous innovation and technological advancement, developing cutting-edge solutions to address industry needs and expanding our platform to include multi-utility products like fiber optics.",
    imageUrl: about1,
    
  },
  // Additional guides can be added here
];

function Goals() {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isMedium = useMediaQuery(theme.breakpoints.up("md"));
  const isSmall = useMediaQuery(theme.breakpoints.only("sm"));
  const navigate = useNavigate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % guides.length);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + guides.length) % guides.length
    );
  };

  // Determine the number of visible guides based on screen size
  const visibleGuides = guides.slice(
    activeStep,
    activeStep + (isLarge ? 3 : isMedium ? 2 : isSmall ? 2 : 1)
  );
  if (visibleGuides.length < (isLarge ? 3 : isMedium ? 2 : isSmall ? 2 : 1)) {
    visibleGuides.push(
      ...guides.slice(
        0,
        (isLarge ? 3 : isMedium ? 2 : isSmall ? 2 : 1) - visibleGuides.length
      )
    );
  }

  const handleGoals = () => {
    navigate("/ourgoals");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        width: "100%",
        overflowX: "hidden",

        //mb: 4,
        // p: 4,
        p: { xs: 4, sm: 5 },
        // backgroundColor: "#336699",
        backgroundColor: "#003C5F",
      }}
    >
      {/* Heading Box */}
      <Box
        sx={{
          width: { sm: "100%", md: "70%" },
          textAlign: { xs: "center", sm: "center", md: "center" },
          // marginY: 3,
          mb: 6,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "60px",
            fontWeight: 700,
            marginBottom: "20px",
            maxWidth: "960px",
            margin: "0 auto",
            lineHeight: "81px",
            color: "#FFC400",
          }}
        >
          OUR GOALS
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontSize: { xs: "20px", md: "24px", color: "white" } }}
        >
          We are dedicated to empowering our customers with transparency, efficiency, and cost savings through our platform. 
        </Typography>
      </Box>

      {/* Cards */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
          mt: 5,
          mb: 5,
        }}
      >
        {visibleGuides.map((guide, index) => (
          <Card
            key={index}
            sx={{
              flexBasis: {
                lg: "calc(33.33% - 20px)",
                md: "calc(50% - 20px)",
                sm: "calc(50% - 20px)",
                xs: "100%",
              },
              margin: 1,
              backgroundColor: "white",
              borderRadius: "20px",
            }}
          >
            <CardMedia
              component="img"
              height="180"
              image={guide.imageUrl}
              alt={guide.title}
            />
            <CardContent sx={{ textAlign: "justify", color: "black" }}>
              <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                {guide.title}
              </Typography>
            </CardContent>
            <CardActions sx={{ p: 4 }}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                href={guide.readMoreLink}
                onClick={handleGoals}
                sx={{
                  backgroundColor: "#FFC400",
                  color: "black",
                  mx: "auto",
                  borderRadius: "10px",
                  width: { xs: "75%", md: "50%", lg: "50%" },
                }}
              >
                Read More
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>

      {/* Navigation Buttons */}
      <IconButton
        onClick={handleBack}
        sx={{
          position: "absolute",
          left: 0,
          zIndex: 1,
          p: 2,
          top: { xs: "65%", sm: "62%", md: "60%" },
          color: "white",
        }}
      >
        <ArrowBackIosNewIcon sx={{ margin: "auto" }} />
      </IconButton>
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          right: 0,
          zIndex: 1,
          p: 2,
          top: { xs: "65%", sm: "62%", md: "60%" },
          color: "white",
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
}

export default Goals;
