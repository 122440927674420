

import React from "react";
import Stack from "@mui/material/Stack";
import { Box } from '@mui/material';
import Typography from "@mui/material/Typography";
import { keyframes } from "@mui/system";

// Define keyframes for fadeInUp animation
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const OurGoals = () => {
  return (
    <Box sx={{ backgroundColor: "#003C5F", color: "#FFFFFF",py:"40px",}}>
      <Stack
        spacing={2}
        useFlexGap
        sx={{
          width: { xs: "100%", sm: "100%" },
          mt:5,
          pt: { xs: 2, sm: 10 },
          pb: { xs: 2, sm: 10 },
        }}
      >
        <Typography
          component="h5"
          variant="h5"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignSelf: "center",
            textAlign: "center",
            fontSize: { xs: "2rem", sm: "3rem" },
            animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
            color:"#FFC400",
          }}
        >
          Our Goals
        </Typography>

        <Typography
          variant="body1"
          color="inherit"
          sx={{
            lineHeight: "1.6",
            letterSpacing: "0.5px",
            fontSize: "16px",
            gap: "20px",
            padding: "20px",
            textAlign: "justify",
            animation: `${fadeInUp} 1s ease-out`, // Apply fadeInUp animation
          }}
        >
          1. Market Leadership: <br/> Our goal is to position InfraVerse Ltd as the foremost provider of electricity connection solutions within the UK construction industry, capturing a significant market share and establishing our platform as the preferred choice for developers, consultants, and construction firms.
          <br />
          2. Sustainability Champion:<br/> We aim to lead the way towards a carbon-neutral future by facilitating the seamless integration of renewable energy projects, such as solar farms, through our platform. Our objective is to become a driving force in achieving carbon-free emissions goals by incentivizing sustainable energy initiatives and promoting environmental stewardship.
          <br />
          3. Innovation Hub:<br/> At InfraVerse Ltd, we are committed to fostering a culture of continuous innovation and technological advancement. We strive to drive the development of cutting-edge solutions that address evolving industry needs. Our focus is on expanding our platform's capabilities to include multi-utility products, such as fiber optics, and exploring emerging technologies to stay ahead of the curve.
          <br />
          4. Customer Empowerment:<br/> We are dedicated to empowering our customers with transparency, efficiency, and cost savings through our platform. Our aim is to provide exceptional user experiences, personalized support, and educational resources to ensure that our customers have the tools and knowledge they need to succeed in their projects.
          <br />
        </Typography>
      </Stack>
    </Box>
  );
};

export default OurGoals;