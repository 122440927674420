import { notification } from "./notification";

export async function addMutation({ payload, mutation, refetch, errorText }) {
  try {
    const response = await mutation(payload).unwrap();
    console.log(response)
    if (response.success) {
      if (refetch) refetch();
      else notification.success(response.message);
    } else notification.error(errorText || response.message);
  } catch (error) {
    notification.apiFailure();
  }
}

export async function deleteMutation(object, mutation, refetch) {
 // console.log(refetch())
  const { quoteId } = { ...object };
  console.log("Delete Mutation",quoteId)
  try {
    //const response = await mutation({ quoteId }).unwrap();
    await mutation({ quoteId }).unwrap();
    refetch();
    // if (response.success) refetch();
    // else notification.error(response.message);
  } catch (error) {
    notification.apiFailure();
  }
}

export async function deleteInquiryMutation(object, mutation, refetch) {
  // console.log(refetch())
   const { inquiryId } = { ...object };
   console.log("Delete Mutation",inquiryId)
   try {
     //const response = await mutation({ quoteId }).unwrap();
     await mutation({ inquiryId }).unwrap();
     refetch();
     // if (response.success) refetch();
     // else notification.error(response.message);
   } catch (error) {
     notification.apiFailure();
   }
 }

// export async function deleteMutation(object, mutation, refetch) {
//   const { quoteId } = { ...object };
//   console.log("Delete Mutation", quoteId);
//   try {
//     const response = await mutation({ quoteId }).unwrap();
//     if (response.success && response.data) {
//       console.log("Deletion successful:", response.message);

//       // Additional check for file deletion
//       if (response.data.filesDeleted) {
//         console.log("All files associated with the quote are deleted.");
//       } else {
//         console.warn("Quote deleted but associated files may still be in process.");
//       }

//       // Trigger refetch after ensuring the quote and files are deleted
//       refetch();
//     } else {
//       console.error("Deletion failed:", response.message);
//       notification.error(response.message);
//     }
//   } catch (error) {
//     console.error("API call failed:", error);
//     notification.apiFailure();
//   }
// }


export function removeEmptyValues(obj) {
  if (Array.isArray(obj)) {
    return obj
      .map((item) => removeEmptyValues(item))
      .filter((item) => Object.keys(item).length > 0);
  } else if (typeof obj === "object" && obj !== null) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const filteredValue = removeEmptyValues(value);
      if (
        filteredValue !== null &&
        filteredValue !== undefined &&
        filteredValue !== ""
      ) {
        acc[key] = filteredValue;
      }
      return acc;
    }, {});
  } else {
    return obj;
  }
}

export function downloadCSV(arrayOfObjects) {
  const replacer = (key, value) => (value === null ? "" : value);
  const header = Object.keys(arrayOfObjects[0]);
  let csv = arrayOfObjects.map((row) =>
    header
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(",")
  );
  csv.unshift(header.join(","));
  let csvString = csv.join("\r\n");

  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

  const link = document.createElement("a");

  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "download.csv");
  link.style.visibility = "hidden";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


