import React from "react";
import AdminStatItem from "./AdminStatItem";
import Grid from "@mui/material/Grid";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote"; // Icon for quotes
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"; // Icon for enquiries
import BusinessIcon from "@mui/icons-material/Business"; // Icon for companies
import PersonIcon from "@mui/icons-material/Person"; // Icon for customers

const statArray = [
  {
    title: "Quotes",
   
    total: "5",
    logo: <FormatQuoteIcon />,
    link:"quotes",
     // More specific icon
  },
  {
    title: "Enquires",
    
    total: "10",
    logo: <QuestionAnswerIcon />,
    link:"Enquiries",
    // More specific icon
  },
  // Uncomment and update these if needed
  {
    title: "companies",
   
    total: "50",
    logo:  <BusinessIcon />, // Relevant for calls
    link:"Companies",
  },
  {
    title: "customers",
   
    total: "120",
    logo: <PersonIcon />, // Relevant for calls
    link:"Customers",
  },

];

const Adminstat = () => {
  return (
    <>
      <Grid
        container
        paddingTop="60px"
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="wrap"
      >
        {statArray?.map((item, index) => (
          <Grid item lg md={6} xs={12} key={index}>
            <AdminStatItem
              title={item?.title}
              percentage={item?.percentage}
              total={item?.total}
              logo={item?.logo}
              link={item?.link}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Adminstat;