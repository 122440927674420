// import React, { useEffect, useState } from "react";
// import {
//   Table,
//   TableBody,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
//   TableCell,
//   Tooltip,
//   TablePagination,
//   Button,
//   Box,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import AddIcon from "@mui/icons-material/Add";
// import useQuotesByCustomerQuery from "../../../hook/routing/useGetQuotesByCustomerQuery";
// import useAuth from "../../../hook/useAuth";
// import {
//   globalStyle,
// } from "../../../constant/globalStyle";
// import { useNavigate } from "react-router-dom";
// import EnquiryViewCustomer from "../enquiry/EnquiryViewCustomer";
// import QuoteViewCustomer from "./QuoteViewCusromer";
// const columns = [
//   { field: "quoteId", headerName: "Quote ID", width: 130 },
//   { field: "services", headerName: "Services", width: 130 },
//   { field: "totalSiteLoad", headerName: "Total Site Load", width: 130 },
//   { field: "status", headerName: "Admin Status", width: 130 },
//   { field: "inquiry", headerName: "Inquiry", width: 130 },
//   { field: "quote", headerName: "Quote", width: 130 },
// ];

// const CustomerQuoteList = () => {
//   const navigate = useNavigate();
//   const { array:quotes, isLoading, refetch } = useQuotesByCustomerQuery();
//   const { isAdmin, isIntegrationEngineer } = useAuth();
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   const openAlertBox = async ({ title, message, confirmButtonText, cancelButtonText, onConfirm }) => {
//     if (window.confirm(message)) {
//       await onConfirm();
//     }
//   };

//   const deleteUser = async (user) => {
//     console.log("Deleting user:", user);
//   };
//   const handleDelete = async (object) => {
//     try {
//       await openAlertBox({
//         title: "Confirmation",
//         message: "Are you sure you want to permanently delete this record?",
//         confirmButtonText: "Delete",
//         cancelButtonText: "Cancel",
//         onConfirm: () => deleteUser(object),
//       });
//     } catch (error) {
//       console.error("Error during deletion:", error);
//     }
//   };
//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleAddQuote = () => {
//     // Add your logic to open the quote creation form or navigate to the creation page
//     console.log("Add Quote button clicked");
//     navigate('/customer/create-quote');
//   };

//   useEffect(() => {
//     console.log("Refetching quotes...");
//     refetch();
//   }, [refetch]);

//   return (
//     <>

//       <Paper elevation={2} sx={{ borderRadius: "15px",my:2, mx: 4 }}>
//       <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
//           <Box component="span" sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
//             Customer Quotes
//           </Box>
//           <Button
//             variant="contained"
//             color="primary"
//             startIcon={<AddIcon />}
//             onClick={handleAddQuote}
//             sx={{ textTransform: "none" }}
//           >
//             Add Quote
//           </Button>
//         </Box>
//         <TableContainer>
//           <Table aria-label="simple table">
//             <TableHead>
//               <TableRow >
//                 {columns.map((column) => (
//                   <TableCell
//                     key={column.field}
//                     align="left"
//                     sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
//                   >
//                     {column.headerName}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>

//             <TableBody>
//               {isLoading ? (
//                 <TableRow>
//                   <TableCell colSpan={columns.length}>Loading...</TableCell>
//                 </TableRow>
//               ) : (
//                 quotes
//                   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                   .map((row) => (
//                     <TableRow key={row.quoteId}>
//                       <TableCell>{row.quoteId}</TableCell>
//                       <TableCell>{row.services}</TableCell>
//                       <TableCell>{row.totalSiteLoad}</TableCell>
//                       <TableCell>{row.status}</TableCell>
//                       <TableCell><EnquiryViewCustomer quoteId={row.quoteId}/></TableCell>
//                       <TableCell><QuoteViewCustomer props={row}/></TableCell>
//                     </TableRow>
//                   ))
//               )}
//             </TableBody>
//           </Table>
//           <TablePagination
//             rowsPerPageOptions={[5, 10, 25]}
//             component="div"
//             count={quotes.length}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </TableContainer>
//       </Paper>
//     </>
//   );
// };

// export default CustomerQuoteList;

import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar, useGridApiContext } from "@mui/x-data-grid";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableCell,
  Tooltip,
  TablePagination,
  Button,
  Box,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import useQuotesByCustomerQuery from "../../../hook/routing/useGetQuotesByCustomerQuery";
import DataGridHeader from "../../../component/data-grid/DataGridHeader";
import CustomNoRowsOverlay from "../../../component/data-grid/CustomNoRowsOverlay";
import useAuth from "../../../hook/useAuth";
import { globalStyle } from "../../../constant/globalStyle";
import { useNavigate } from "react-router-dom";
import EnquiryListCustomer from "../enquiry/EnquiryListCustomer";
import QuoteViewCustomer from "./QuoteViewCusromer";
const CustomerQuoteList = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = React.useState(10);
  //const { array, refetch, isLoading } = useGetAllQuotes();
  const { array, isLoading, refetch } = useQuotesByCustomerQuery();
  useEffect(() => {
    refetch().catch((error) => {
      console.error("Error fetching data:", error);
    });
  }, [refetch]);

  // const handleStatusChange = async (id, field, value) => {
  //   try {
  //     await updateQuoteStatus({ quoteId: id, status: value }).unwrap();
  //     refetch();
  //   } catch (error) {
  //     console.error("Failed to update status", error);
  //   }
  // };

  const handleQuote = () => {
    navigate("/customer/create-quote");
  };
  // const handleEditQuotes = (object) => {
  //   console.log("Editing quote:", object);
  //   // Add your logic to open the edit form or navigate to the edit page
  // };

  // const SelectEditInputCell = (props) => {
  //   const { id, value, field } = props;
  //   //const apiRef = useGridApiContext();

  //   const handleChange = async (event) => {
  //     const newValue = event.target.value;
  //     await apiRef.current.setEditCellValue({ id, field, value: newValue });
  //     apiRef.current.stopCellEditMode({ id, field });
  //     handleStatusChange(id, field, newValue);
  //   };

  //   return (
  //     <Select
  //       value={value}
  //       onChange={handleChange}
  //       size="small"
  //       sx={{ height: 1 }}
  //       native
  //       autoFocus
  //     >
  //       <option value="Pending">Pending</option>
  //       <option value="Approved">Approved</option>
  //       <option value="Declined">Declined</option>
  //     </Select>
  //   );
  // };

  // const renderSelectEditInputCell = (params) => {
  //   return <SelectEditInputCell {...params} />;
  // };

  return (
    <Paper elevation={2} sx={{ borderRadius: "15px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{
            backgroundColor: "#1976d2",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
          onClick={handleQuote}
        >
          Add Quote
        </Button>
      </Box>
      <DataGrid
        autoHeight
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        rows={array}
        loading={isLoading}
        getRowId={(row) => row.quoteId}
        slots={{
          toolbar: GridToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        columns={[
          {
            field: "quoteId",
            flex: 1,
            hide: true,
            renderHeader: () => <DataGridHeader header={"Quote Id"} />,
            headerAlign: "center",
            align: "center",
          },
          {
            field: "services",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Services"} />,
            headerAlign: "center",
            align: "center",
          },
          // {
          //   field: "expectedDate",
          //   flex: 1,
          //   renderHeader: () => <DataGridHeader header={"Expected Date"} />,
          //   headerAlign: "center",
          //   align: "center",
          // },
          // {
          //   field: "quoteDeadline",
          //   flex: 1,
          //   renderHeader: () => <DataGridHeader header={"Quote Deadline"} />,
          //   headerAlign: "center",
          //   align: "center",
          // },
          {
            field: "expectedDate",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Expected Date"} />,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              // Check if the expectedDate exists and format it
              const expectedDate = params.row?.expectedDate
                ? new Date(params.row.expectedDate).toISOString().split("T")[0]
                : ""; // Fallback if expectedDate is missing

              return expectedDate;
            },
          },
          {
            field: "quoteDeadline",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Quote Deadline"} />,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              // Check if the quoteDeadline exists and format it
              const quoteDeadline = params.row?.quoteDeadline
                ? new Date(params.row.quoteDeadline).toISOString().split("T")[0]
                : ""; // Fallback if quoteDeadline is missing

              return quoteDeadline;
            },
          },
          {
            field: "totalSiteLoad",
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Total Site Load"} />,
            headerAlign: "center",
            align: "center",
          },

          {
            field: "status",
            flex: 1,
            renderHeader: () => (
              <DataGridHeader header={"Admin Quote Status"} />
            ),
            headerAlign: "center",
            align: "center",
          },

          // {
          //   field: "actions",
          //   sortable: false,
          //   filterable: false,
          //   hideable: false,
          //   // hideFil
          //   flex: 1,
          //   renderHeader: () => <DataGridHeader header={"Actions"} />,
          //   headerAlign: "center",
          //   align: "center",
          //   renderCell: (params) => (
          //     <Tooltip title="Delete" sx={{ p: 1 }}>
          //       <IconButton
          //         color="error"
          //         cursor="pointer"
          //         onClick={() => handleDelete(params.row)}
          //       >
          //         <DeleteOutlineOutlinedIcon />
          //       </IconButton>
          //     </Tooltip>
          //   ),
          // },
          {
            field: "quoteDetail",
            sortable: false,
            filterable: false,
            // hideable: false,
            // hideFil
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Quote"} />,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              return (
                <>
                  {/* <Tooltip title="Delete" sx={{ p: 1 }}>
                <IconButton
                  color="error"
                  cursor="pointer"
                  onClick={() => handleDelete(params.row)}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Tooltip> */}

                  {/* {
                                  <Tooltip title="Edit">
                        <IconButton
                          color="primary"
                           onClick={() => handleEditQuotes(params.row)}
                        >
                          <EditIcon />
                         </IconButton>
                       </Tooltip>
              } */}
                  {<QuoteViewCustomer props={params.row} />}
                  {/* {
                    <EnquiryViewCustomer quoteId={params.row.quoteId} />
                  } */}
                </>
              );
            },
          },
          {
            field: "inquiryDetail",
            sortable: false,
            filterable: false,
            // hideable: false,
            // hideFil
            flex: 1,
            renderHeader: () => <DataGridHeader header={"Inquiry"} />,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              return (
                <>
                  {/* <Tooltip title="Delete" sx={{ p: 1 }}>
                <IconButton
                  color="error"
                  cursor="pointer"
                  onClick={() => handleDelete(params.row)}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Tooltip> */}

                  {/* {
                                  <Tooltip title="Edit">
                        <IconButton
                          color="primary"
                           onClick={() => handleEditQuotes(params.row)}
                        >
                          <EditIcon />
                         </IconButton>
                       </Tooltip>
              } */}
                  {<EnquiryListCustomer quoteId={params.row.quoteId} />}
                </>
              );
            },
          },
        ]}
      />
    </Paper>
  );
};

export default CustomerQuoteList;
