// // // import React from "react";
// // // import { 
// // //     sideBarArraySuperAdmin,
// // //     sideBarArrayAdmin,
// // //     sideBarArrayCustomer,
// // //     sideBarArrayCompany
// // //  } from "./menuArray";
// // //  import useAuth from "../hook/useAuth";

// // // import Box from "@mui/material/Box";
// // // import { useTheme } from "@mui/material/styles";
// // // import List from "@mui/material/List";
// // // import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// // // import IconButton from "@mui/material/IconButton";
// // // import ChevronRight from "@mui/icons-material/ChevronRight";
// // // import KeyboardDoubnButton from "@mui/material/IconButton";
// // // import ChevronRighleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// // // import Logo from "../assets/logo.svg";
// // // import Grid from "@mui/material/Grid";
// // // import { Drawer, DrawerHeader } from "./style";
// // // import SideBarItem from "./SideBarItem";
// // // import KeyboardDoubleArrowLeft from "@mui/icons-material/KeyboardDoubleArrowLeft";
// // // const SideBar = ({ open, toggleSidebar }) => {
// // //   const theme = useTheme();
    
// // //   const { isSuperAdmin, isAdmin, isCustomer, isCompany } = useAuth();

// // //   let sideBarArray = [];
// // //   if (isSuperAdmin) sideBarArray = [...sideBarArraySuperAdmin];
// // //   else if (isAdmin) sideBarArray = [...sideBarArrayAdmin];
// // //   else if (isCustomer) sideBarArray = [...sideBarArrayCustomer];
// // //   else if (isCompany) sideBarArray = [...sideBarArrayCompany];
// // //   return (
// // //     <>
// // //       <Drawer variant="permanent" sx={{ color: "#B5B5DA" }} open={open}>
// // //         <DrawerHeader
// // //           sx={{
// // //             background: "#1B2646",
// // //             color: "#B5B5DA",
// // //             fontFamily: "Raleway",
// // //             fontStyle: "normal",

// // //             fontWeight: 500,
// // //             fontSize: "13px",
// // //             lineHeight: "12px ",
// // //           }}
// // //         >
// // //           {open && (
// // //             <Grid container alignItems="center" marginTop={2}>
// // //               <Grid item xs>
// // //                 <Box
// // //                   sx={{
// // //                     fontWeight: 500,
// // //                     fontSize: "0.25rem",
// // //                     lineHeight: 0.6,
// // //                     flexGrow: 1,
// // //                     display: {
// // //                       xs: "block",
// // //                       md: "none",
// // //                       sm: "none",
// // //                     },
// // //                   }}
// // //                 >
// // //                   <img src={Logo} alt="Logo" />
// // //                 </Box>
// // //               </Grid>
// // //               <Grid item>
// // //                 <IconButton onClick={toggleSidebar}>
// // //                   {theme.direction === "rtl" && <ChevronRightIcon/>}
// // //                   {theme.direction !== "rtl" && (
// // //                     <ChevronRighleArrowLeftIcon style={{ color: "#B5B5DA" }} />
// // //                   )}
// // //                 </IconButton>
// // //               </Grid>
// // //             </Grid>
// // //           )}
// // //         </DrawerHeader>
// // //         <Grid></Grid>
// // //         <List
// // //           sx={{
// // //             background: "#1B2646",
// // //             color: "#B5B5DA",

// // //             fontFamily: "Raleway",
// // //             fontStyle: "normal",
// // //             fontWeight: 500,
// // //             fontSize: "10px",
// // //             lineHeight: "12px ",
// // //             height: "100%",
            
// // //           }}
// // //         >
// // //           {sideBarArray?.map((item, index) => (
// // //             <SideBarItem
// // //               sx={{
// // //                 "&:hover": {
// // //                   background: "#121829",
// // //                 },
// // //               }}
// // //               key={index}
// // //               open={open}
// // //               item={item}
// // //               children={item.children}
// // //             />
// // //           ))}
// // //         </List>
// // //       </Drawer>
// // //     </>
// // //   );
// // // };

// // // export default SideBar;


// // import React from "react";
// // import Box from "@mui/material/Box";
// // import { useTheme } from "@mui/material/styles";
// // import List from "@mui/material/List";
// // import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// // import IconButton from "@mui/material/IconButton";
// // import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// // import Grid from "@mui/material/Grid";
// // import { Drawer, DrawerHeader } from "./style";
// // import SideBarItem from "./SideBarItem";
// // import useAuth from "../hook/useAuth";
// // import Logo from "../assets/logo.svg";
// // import { 
// //     sideBarArraySuperAdmin,
// //     sideBarArrayAdmin,
// //     sideBarArrayCustomer,
// //     sideBarArrayCompany
// //  } from "./menuArray";

// // const SideBar = ({ open, toggleSidebar }) => {
// //   const theme = useTheme();
// //   const { isSuperAdmin, isAdmin, isCustomer, isCompany } = useAuth();

// //   let sideBarArray = [];
// //   if (isSuperAdmin) sideBarArray = [...sideBarArraySuperAdmin];
// //   else if (isAdmin) sideBarArray = [...sideBarArrayAdmin];
// //   else if (isCustomer) sideBarArray = [...sideBarArrayCustomer];
// //   else if (isCompany) sideBarArray = [...sideBarArrayCompany];

// //   return (
// //     <Drawer variant="permanent" open={open}>
// //       <DrawerHeader>
// //         <Grid container justifyContent="center" alignItems="center">
// //           <Grid item>
// //             <img src={Logo} alt="logo" width={70} />
// //           </Grid>
// //           <Grid item>
// //             <IconButton onClick={toggleSidebar}>
// //               {theme.direction === "rtl" ? <ChevronRightIcon /> : <KeyboardDoubleArrowLeftIcon />}
// //             </IconButton>
// //           </Grid>
// //         </Grid>
// //       </DrawerHeader>
// //       <List>
// //         {sideBarArray.map((item, index) => (
// //           <SideBarItem key={index} item={item} open={open} />
// //         ))}
// //       </List>
// //     </Drawer>
// //   );
// // };

// // export default SideBar;




// // import Box from "@mui/material/Box";
// // import { useTheme } from "@mui/material/styles";
// // import List from "@mui/material/List";
// // import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// // // import KeyboardDoubleButton from "@mui/material/IconButton";
// // // import ChevronRightIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// // import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// // import ChevronRight from "@mui/icons-material/ChevronRight";
// // import IconButton from "@mui/material/IconButton";
// // import Logo from "../assets/logo.svg";
// // import Grid from "@mui/material/Grid";
// // import { Drawer, DrawerHeader } from "./style";
// // import SideBarItem from "./SideBarItem";
// // import useAuth from "../hook/useAuth";
// // // import {
// // //   sideBarArrayEndUser,
// // //   sideBarArrayChannelPartner,
// // //   sideBarArraySuperAdmin,
// // //   sideBarArrayAdmin,
// // // } from "./menuArray";
// // import { 
// //     sideBarArraySuperAdmin,
// //     sideBarArrayAdmin,
// //     sideBarArrayCustomer,
// //     sideBarArrayCompany
// //  } from "./menuArray";
// // const SideBar = ({ open, toggleSidebar }) => {
// //   const theme = useTheme();
// //     const { isSuperAdmin, isAdmin, isCustomer, isCompany } = useAuth();
// //   let sideBarArray = [];
// //   if (isSuperAdmin) sideBarArray = [...sideBarArraySuperAdmin];
// //   else if (isAdmin) sideBarArray = [...sideBarArrayAdmin];
// //   else if (isCustomer) sideBarArray = [...sideBarArrayCustomer];
// //   else if (isCompany) sideBarArray = [...sideBarArrayCompany];
// //   return (
// //     <>
// //       <Drawer variant="permanent" sx={{ color: "#B5B5DA" }} open={open}>
// //         <DrawerHeader
// //           sx={{
// //             background: "#1B2646",
// //             color: "#B5B5DA",
// //             fontFamily: "Raleway",
// //             fontStyle: "normal",

// //             fontWeight: 500,
// //             fontSize: "13px",
// //             lineHeight: "12px ",
// //           }}
// //         >
// //           {open && (
// //             <Grid container alignItems="center" marginTop={2}>
// //               <Grid item xs>
// //                 <Box
// //                   sx={{
// //                     fontWeight: 500,
// //                     fontSize: "0.25rem",
// //                     lineHeight: 0.6,
// //                     flexGrow: 1,

// //                     display: {
// //                       xs: "block",

// //                       md: "none",
// //                       sm: "none",
// //                     },
// //                   }}
// //                 >
// //                   <img src={Logo} alt="Logo" />
// //                 </Box>
// //               </Grid>

// //               <Grid item>
// //                 <IconButton onClick={toggleSidebar}>
// //                   {theme.direction === "rtl" && <ChevronRightIcon />}

// //                   {theme.direction !== "rtl" && (
// //                     <KeyboardDoubleArrowLeftIcon style={{ color: "#B5B5DA" }} />
// //                   )}
// //                 </IconButton>
// //               </Grid>
// //             </Grid>
// //           )}
// //         </DrawerHeader>
// //         <Grid></Grid>
// //         <List
// //           sx={{
// //             background: "#1B2646",
// //             color: "#B5B5DA",

// //             fontFamily: "Raleway",
// //             fontStyle: "normal",
// //             fontWeight: 500,
// //             fontSize: "10px",
// //             lineHeight: "12px ",
// //             height: "100%",
            
// //           }}
// //         >
// //           {sideBarArray?.map((item, index) => (
// //             <SideBarItem
// //               sx={{
// //                 "&:hover": {
// //                   background: "#121829",
// //                 },
// //               }}
// //               key={index}
// //               open={open}
// //               item={item}
// //               children={item.children}
// //             />
// //           ))}
// //         </List>
// //       </Drawer>
// //     </>
// //   );
// // };

// // export default SideBar;




// import Box from "@mui/material/Box";
// import { useTheme } from "@mui/material/styles";
// import List from "@mui/material/List";
// import IconButton from "@mui/material/IconButton";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

// import Logo from "../assets/logo.svg";
// import Grid from "@mui/material/Grid";
// import { Drawer, DrawerHeader } from "./style";
// import SideBarItem from "./SideBarItem";
// import useAuth from "../hook/useAuth";
// import {
//   sideBarArrayCustomer,
//   sideBarArrayCompany,
//   sideBarArraySuperAdmin,
//   sideBarArrayAdmin,
// } from "./menuArray";
// const SideBar = ({ open, toggleSidebar }) => {
//   const theme = useTheme();
//       const { isSuperAdmin, isAdmin, isCustomer, isCompany } = useAuth();
//   let sideBarArray = [];
//   if (isSuperAdmin) sideBarArray = [...sideBarArraySuperAdmin];
//   else if (isAdmin) sideBarArray = [...sideBarArrayAdmin];
//   else if (isCustomer) sideBarArray = [...sideBarArrayCustomer];
//   else if (isCompany) sideBarArray = [...sideBarArrayCompany];
//   return (
//     <>
//       <Drawer variant="permanent" sx={{ color: "#B5B5DA" }} open={open}>
//         <DrawerHeader
//           sx={{
//             background: "#1B2646",
//             color: "#B5B5DA",
//             fontFamily: "Raleway",
//             fontStyle: "normal",

//             fontWeight: 500,
//             fontSize: "13px",
//             lineHeight: "12px ",
//           }}
//         >
//           {open && (
//             <Grid container alignItems="center" marginTop={2}>
//               <Grid item xs>
//                 <Box
//                   sx={{
//                     fontWeight: 500,
//                     fontSize: "0.25rem",
//                     lineHeight: 0.6,
//                     flexGrow: 1,

//                     display: {
//                       xs: "block",

//                       md: "none",
//                       sm: "none",
//                     },
//                   }}
//                 >
//                   <img src={Logo} alt="Logo" />
//                 </Box>
//               </Grid>

//               <Grid item>
//                 <IconButton onClick={toggleSidebar}>
//                   {theme.direction === "rtl" && <ChevronRightIcon />}

//                   {theme.direction !== "rtl" && (
//                     <KeyboardDoubleArrowLeftIcon style={{ color: "#B5B5DA" }} />
//                   )}
//                 </IconButton>
//               </Grid>
//             </Grid>
//           )}
//         </DrawerHeader>
//         <Grid></Grid>
//         <List
//           sx={{
//             background: "#1B2646",
//             color: "#B5B5DA",

//             fontFamily: "Raleway",
//             fontStyle: "normal",
//             fontWeight: 500,
//             fontSize: "10px",
//             lineHeight: "12px ",
//             height: "100%",
            
//           }}
//         >
//           {sideBarArray?.map((item, index) => (
//             <SideBarItem
//               sx={{
//                 "&:hover": {
//                   background: "#121829",
//                 },
//               }}
//               key={index}
//               open={open}
//               item={item}
//               children={item.children}
//             />
//           ))}
//         </List>
//       </Drawer>
//     </>
//   );
// };

// export default SideBar;


import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// import Logo from "../assets/logo.svg";
import Logo from "../assets/header/logo.png";
import Grid from "@mui/material/Grid";
import { Drawer, DrawerHeader } from "./style";
import SideBarItem from "./SideBarItem";
import useAuth from "../hook/useAuth";
import {
  sideBarArrayCustomer,
  sideBarArrayCompany,
  sideBarArraySuperAdmin,
  sideBarArrayAdmin,
} from "./menuArray";
const logoStyle = {
  width: "100px",
  height: "80px",
};

const SideBar = ({ open, toggleSidebar }) => {
  const theme = useTheme();
  const { isIntegrationEngineer, isAdmin, isCustomer, isCompany } = useAuth();
  let sideBarArray = [];
  if (isIntegrationEngineer) sideBarArray = [...sideBarArraySuperAdmin];
  else if (isAdmin) sideBarArray = [...sideBarArrayAdmin];
  else if (isCustomer) sideBarArray = [...sideBarArrayCustomer];
  else if (isCompany) sideBarArray = [...sideBarArrayCompany];
  
  return (
    <>
      <Drawer variant="permanent" sx={{ color: "#B5B5DA" }} open={open}>
        <DrawerHeader
          sx={{
            background: "#1B2646",
            color: "#B5B5DA",
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "13px",
            lineHeight: "12px ",
          }}
        >
          {open && (
            <Grid container alignItems="center" marginTop={2}>
              <Grid item xs>
                <Box
                  sx={{
                    fontWeight: 500,
                    fontSize: "0.25rem",
                    lineHeight: 0.6,
                    flexGrow: 1,
                    display: {
                      xs: "block",
                      md: "none",
                      sm: "none",
                    },
                  }}
                >
                  <img src={Logo} style={logoStyle} alt="Logo" />
                </Box>
              </Grid>

              <Grid item>
                <IconButton onClick={toggleSidebar}>
                  {theme.direction === "rtl" ? <ChevronRightIcon /> : <KeyboardDoubleArrowLeftIcon style={{ color: "#B5B5DA" }} />}
                </IconButton>
              </Grid>
            </Grid>
          )}
        </DrawerHeader>
        <Grid></Grid>
        <List
          sx={{
            background: "#1B2646",
            color: "#B5B5DA",
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "10px",
            lineHeight: "12px ",
            height: "100%",
          }}
        >
          {sideBarArray?.map((item, index) => (
            <SideBarItem
              sx={{
                "&:hover": {
                  background: "#121829",
                },
              }}
              key={index}
              open={open}
              item={item}
            />
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default SideBar;
